import Form from 'react-bootstrap/Form';
import { useState,useEffect } from 'react';

export default function RollList(props)
{
    const [selectedOption, setSelectedOption] = useState(props.getdefaultroll);
    const [searchTerm, setSearchTerm] = useState('');
    const options=props.getdblist
    
    // const options = [
    //   { value: 'roll', label: 'roll' },
    //   { value: 'EXL', label: 'EXL' },
    //   { value: 'Cognizant', label: 'Cognizant' },
    //   { value: 'Capgemini', label: 'Capgemini' },
    //   { value: 'Tech Mahindra', label: 'Tech Mahindra' },
    //   { value: 'Oracle', label: 'Oracle' },
    //   { value: 'Master Card', label: 'Master Card' },
    //   { value: 'Other', label: 'Other' },
    // ];
    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const handleBlur = () => {
        if (searchTerm.trim() !== '') { // Check if searchTerm is not empty
          if (!options.find((option) => option.value === searchTerm)) {
            setSelectedOption(searchTerm);
          } else {
            const selected = options.find((option) => option.value === searchTerm);
            setSelectedOption(selected.value);
          }
          setSearchTerm('');
        }
      };
    
      const handleRemoveOption = () => {
        setSelectedOption('');
      };


      useEffect(() => {
        if (props.getdefaultroll && selectedOption !== props.getdefaultroll) {
          setSelectedOption(props.getdefaultroll);
        }
      }, [props.getdefaultroll]);


    return(
        <>
        <Form.Group>
         <div className="d-flex align-items-center">
           <input
             className="form-control w-100"
             type="text"
             list="roll"
             value={selectedOption || searchTerm}
             onChange={handleOnChange}
             onBlur={handleBlur}
             placeholder="Enter Roll"
             autoComplete="off"
             onFocus={(e) => e.target.select()}
           />
           {selectedOption && (
             <button
               type="button"
               onClick={handleRemoveOption}
               className="remove-option btn btn-link"
             >
               &#x2716;
             </button>
           )}
         </div>
         <datalist id="roll">
           {options.map((option) => (
             <option value={option.value} key={option.value}>
               {option.label}
             </option>
           ))}
         </datalist>
       </Form.Group>
       {props.selroll(selectedOption)}
               </>
    )
}