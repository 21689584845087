import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { IoHomeOutline } from "react-icons/io5";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaHome } from "react-icons/fa";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import './Header.css'; // Import a CSS file for custom styling

export default function MainNavigation() {

  const navigate = useNavigate();
  const [islogin, setLogin] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      setLogin(true);
      let userdata = localStorage.getItem('user-info');
      let parseVal = JSON.parse(userdata);
      setUserName(parseVal.u_name);
    }
  }, []);

  function logout() {
    localStorage.clear();
    navigate("/");
    document.location.reload();
  }

  return (
    <>
      <header className="header">
        {/* <Container fluid className="my-1"> */}
          <Card className="my-0">
            <Navbar expand="lg" className="navbar">
              <Navbar.Brand href="/" className='navbar-brand'>
                <span className="ms-2">Find Jobs Here</span> {/* Company name */}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end ">
                <Nav className="justify-content-end my-1 navbar-nav">
                  {islogin ? (
                    <>
                      <Navbar.Brand href="/" className='navbar-brand'>
                        <FaHome />
                      </Navbar.Brand>
                      <Nav className="navbar-nav">
                        <NavLink to="/dash" className="nav-link">Dashboard</NavLink>
                        <NavLink to="/profile" className="nav-link">Profile</NavLink>
                        {/* <NavLink to="/plan" className="nav-link">Services</NavLink> */}
                        <NavDropdown title={userName} id="basic-nav-dropdown" className="nav-item dropdown">
                          {/* <NavDropdown.Item href="/feedback">
                            Feedback
                          </NavDropdown.Item> */}
                          <NavDropdown.Divider />
                          <NavDropdown.Item onClick={logout}>
                            Logout
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
                    </>
                  ) : (
                    <>
                      <Nav.Link href="/login" className="nav-link">Login</Nav.Link>
                      <Nav.Link href="/signup" className="nav-link">SignUp</Nav.Link>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Card>
        {/* </Container> */}
      </header>
    </>
  );
}