import { Row, Col, Card, Button, Tabs } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import CloseButton from 'react-bootstrap/CloseButton';
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import { MdCancelPresentation } from "react-icons/md";
import { useEffect,useState } from 'react';
import axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import { IoPersonAddOutline } from "react-icons/io5";
import { IoPersonAdd } from "react-icons/io5";
import Tab from 'react-bootstrap/Tab';
import ContactList from './ContactList';
import Badge from 'react-bootstrap/Badge';

export default function CandidateList()
{
  return null;
  const [allResumeData,setAllResumeData]=useState([])
  const [getDraftJobCount,setDraftJobCount]=useState('')
  const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);
const [getapplicantname,setapplicantname]=useState('')
const [gettitle,settitle]=useState('')
const [getskill,setskill]=useState('')
const [getcertificate,setcertificate]=useState('')
const [getattachement,setattachement]=useState('')
const [getemailid,setemailid]=useState('')
const [getexpyear,setexpyear]=useState('')
const [getexpmonth,setexpmonth]=useState('')
const [getuserid,setuserid]=useState('')
const [getstatus, setstatus]=useState(false)
const [getloginuserid,setloginuserid]=useState('')
const [getprofileimage,setprofileimage]=useState('')
const [getresumedesc,setresumedesc]=useState('')
const [getcompany,setcompany]=useState('')
const [getusername,setusername]=useState('')
const [searchKey,setSearchKey]=useState('')
const [counter,setCounter]=useState('')
const [activeKey,setActiveKey]=useState('candidate')



useEffect(()=>{
  if(localStorage.getItem('user-info')){
    let userdata=localStorage.getItem('user-info')
    let parseVal=JSON.parse(userdata)
    setloginuserid(parseVal.user_id)
    setprofileimage(parseVal.u_image)
    
  }},[])

useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
     let userIdAuto= parseVal.user_id

     let data={userIdAuto,searchKey}
let datas=JSON.stringify(data)

      fetch(`${window.siteUrl}/candidatelist/${datas}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
    console.log("candidatelist1",result)
     result.json().then((resp)=>{
      setAllResumeData(resp) 
          console.log("candidatelist",resp)
     }) 
   }
   )
  }
  },[getstatus,searchKey,counter]) 


  let [getvalue,setvalue]=useState()
function setdeldata(id)
{
handleShow ()
  setvalue(id)
 
}

async function addcontactlist()
{
  const data={gettitle,getskill,getcertificate,getattachement,getemailid,getexpyear
  ,getexpmonth,getuserid,getloginuserid,getprofileimage,getresumedesc,getcompany,getusername}
  console.log("alldata",data)
try{
let pResult= fetch(window.siteUrl+"/addcontactlist",
{
 method: 'POST',
 headers: {
   'Accept': 'application/json',
   'Content-Type': 'application/json',
   'authorization': 'bearar'+' '+localStorage.getItem('auth')
 },
 body: JSON.stringify(data)
}).then((pResult) => {
 //console.log("user list", pResult)
 pResult.json().then((resp) => {
   console.log("contactlist", resp)  
   if(resp.result.affectedRows>=1)
   {
    setstatus(true)
    handleClose()
    
   }
   setTimeout(() => {
    setstatus(false)
  }, 4000)
}
 )
}
)
}catch
{

}
}
  
const handleCounter = (count) => {
  setCounter(count);
};

    return(

        <>
        {/* <h2 className="headingbackground p-3" style={{ borderRadius: '4px' }}>Candidate List</h2> */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.css" integrity="sha256-NAxhqDvtY0l4xn+YVa6WjAcmd94NNfttjNsDmNatFVc=" crossorigin="anonymous" />
        <h2 className="dashboard-heading">Candidate List</h2>
        {/* {
      activeKey === 'candidate'?
        <h2 className="headingbackground p-3" style={{ borderRadius: '4px' }}>
          Candidate List
        </h2>:
        <h2 className="headingbackground p-3" style={{ borderRadius: '4px' }}>
        Contact List
      </h2>

      } */}
      
        <Tabs
      defaultActiveKey="candidate"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
      onSelect={(key) => {
        // if (key === 'contact') {
        //   window.history.pushState({}, '', '/profile/contact');
        // }
        if (key === 'candidate') {
          window.history.pushState({}, '', '/candidatelist');
          setActiveKey(key)
        }
        if (key === 'contact') {
          window.history.pushState({}, '', '/candidatelist/contactlist');
          setActiveKey(key)
        }
      }}
    >
      
     
     
      

      <Tab eventKey="candidate" title="Candidate">
      <form className="d-flex justify-content-start my-2 ml-auto" style={{ width: "50%" }}>
          <input
            className="form-control"
            type="search"
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder="Search People/company/skill"
            aria-label="Search"
          />
        </form>
        {allResumeData &&
      allResumeData.length > 0 &&
      allResumeData.map((item, i)=>
        <div class="candidate-list-box card mb-2">
                              <div class="p-2 card-body">
                                  <div class="align-items-center row">
                                      <div class="col-lg-2">
                                          <div class="candidate-list-images">
                                              <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" class="avatar-md img-thumbnail rounded-circle" /></a>
                                          </div>
                                         
                                      </div>
                                      
                                      <div class="col-lg-4">
                                          <div class="candidate-list-content mt-3 mt-lg-0">
                                              <h5 class="fs-19 mb-0">
                                                  <a class="primary-link" href="#">{item.r_title}</a>
                                              </h5>
                                              <p class="text-muted mb-0">{item.r_title}</p>
                                              <ul class="list-inline mb-0 text-muted">
                                                  <li class="list-inline-item"><i class="bi bi-building"></i>{item.u_company_name} {item.exp_year}.{item.exp_month} year </li>
                                                  {/* <li class="list-inline-item"> {item.exp_year-item.exp_month} year</li> */}
                                              </ul>
                                          </div>
                                      </div>
                                      <div class="col-sm-4 py-1">
              <span class="badge bg-secondary text-wrap text-break ">{item.r_skill}</span>
              <span class="badge bg-secondary text-wrap text-break">{item.r_certificate}</span>
            </div>

            <div class="col-sm-2 text-lg-end p-2">
              {/* <a href="#" class="btn btn-primary stretched-link m-1">Resume</a> */}


              {/* {item.r_attachment && (
    <a href={item.r_attachment} download>
      <i className="bi bi-download" /> 
    </a>
  )} */}
          <Button variant="outline-success" href={item.r_attachment} download target="_blank" rel="noopener noreferrer">
  <i className="bi bi-download text-danger" /> Resume
</Button>

              <Button variant="outline-primary"className="btn my-1" onClick={()=>setdeldata(item.resume_id_a,setuserid(item.u_user_id),settitle(item.r_title),setskill(item.r_skill),setcertificate(item.r_certificate),setattachement(item.r_attachment),setemailid(item.r_email_id),setresumedesc(item.resume_desc),setcompany(item.u_company_name),setusername(item.u_user_name),setexpyear(item.r_exp_year),setexpmonth(item.r_exp_month))}> <IoPersonAdd /> Contact</Button>
            </div>
            {/* onClick={()=>setdeldata(item.id,setdeleteshopname(item.shop_name),setdeleteshopurl(item.shop_url))} */}
                                  </div>
                                  {/* <div class="favorite-icon d-flex flex-column align-items-center"> */}
                                  {/* <text className='fs-12 text-muted fw-normal italic'>25-10-2025</text> */}
  {/* <div class="d-flex">
    <FcViewDetails className='fs-2'/>
    <MdCancelPresentation className='fs-2 text-danger'/>
  </div>
</div> */}
                              </div>
                          </div>
      )}



</Tab>


<Tab eventKey="contact" title={(
  <span>
    <span style={{ marginRight: 5 }}>Contact</span>
    {counter > 0 && (
      <Badge bg="secondary">{counter}</Badge>
    )}
  </span>
)} >
<ContactList  status={getstatus} getcounter={handleCounter}/>
      </Tab>

</Tabs>
<Modal show={showdel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to add this contact  <b>{gettitle}</b>
            
        </Modal.Body>
        <Modal.Footer>
         <Button variant="primary" onClick={addcontactlist}>
            Add
          </Button> 
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
                          {/* <div class="candidate-list-box card mt-1">
                              <div class="p-2 card-body">
                                  <div class="align-items-center row">
                                      <div class="col-auto">
                                          <div class="candidate-list-images">
                                              <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" class="avatar-md img-thumbnail rounded-circle" /></a>
                                          </div>
                                      </div>
                                      <div class="col-lg-5">
                                          <div class="candidate-list-content mt-3 mt-lg-0">
                                              <h5 class="fs-19 mb-0">
                                                  <a class="primary-link" href="#">Rebecca Swartz </a>
                                              </h5>
                                              <p class="text-muted mb-2">Software tester with manager post</p>
                                              <ul class="list-inline mb-0 text-muted">
                                                  <li class="list-inline-item"><i class="bi bi-building"></i>Cognizant technology solution pvt ltd </li>
                                                  <li class="list-inline-item"> 5-7 year</li>
                                              </ul>
                                          </div>
                                      </div>
                                      <div class="col-lg-4">
                                          <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1"><span class="badge bg-soft-secondary fs-14 mt-1 text-wrap">
  Design dfadsk dfasdf dfasd fadsf adsfadsf dfads dfa dfasd fadfa dfasd fadfa sd dfadsf dfads 
</span></div>
                                      </div>
                                  </div>
                                  <div class="favorite-icon d-flex flex-column align-items-center">
                                 
  <div class="d-flex">
    <FcViewDetails className='fs-2'/>
    <MdCancelPresentation className='fs-2 text-danger'/>
  </div>
</div>
                              </div>
                          </div> */}

 {/* <text className='fs-12 text-muted fw-normal italic'>25-10-2025</text> */}

         {/* <Row className='border p-2 my-2'>
            <Col xs={6}sm ={2}>
           
  <figure>
    <Card.Img variant="top" src="holder.js/200px300?text=Image cap" />
    <figcaption className="text-center">image</figcaption>
  </figure>

            </Col>
            <Col xs={6}sm={2} > */}
            {/* <Card.Body className="py-0"> */}
          {/* <Card.Title className='small' >Candidate Name From Company</Card.Title>
          <Card.Text className="appliedOnDate"><strong className='appliedontext'>Applied on-</strong>25/02/2025</Card.Text>
          </Col>
          <Col xs={12}sm={5} >
          <Card.Text className="text-muted mb-1">Skill Year of Exp dfas dfadsf asdfad fadsf adsf adsfa dfads fadsfadsfads dfadsfadfads fadsfadsfads </Card.Text>
        
        </Col>
            <Col xs={6} sm={2} className="d-flex flex-sm-column align-items-end" >
              
             
              <FcViewDetails className='fs-2'/>
                </Col>
                <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-start ">
                <AiOutlineDelete className='fs-2 text-danger'/>
</Col>
                
          </Row> */}

{/* 
          <Row className='border border p-2 my-2'>
            <Col xs={6}sm ={2}>
           
  <figure>
    <Card.Img variant="top" src="holder.js/200px300?text=Image cap" />
    <figcaption className="text-center">image</figcaption>
  </figure>

            </Col>
            <Col xs={6}sm={2} >
          
          <Card.Title className='small' >Candidate Name From Company</Card.Title>
          <Card.Text className="appliedOnDate"><strong className='appliedontext'>Applied on-</strong>25/02/2025</Card.Text>
          </Col>
          <Col xs={12}sm={5} >
          <Card.Text className="text-muted mb-1">Skill Year of Exp dfas dfadsf asdfad fadsf adsf adsfa dfads fadsfadsfads dfadsfadfads fadsfadsfads </Card.Text>
     
        </Col>
            <Col xs={6} sm={2} className="d-flex flex-sm-column align-items-end" >
           
             
              <FcViewDetails className='fs-2'/>
                </Col>
                <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-start ">
                <AiOutlineDelete className='fs-2 text-danger'/>
</Col>
                
          </Row> */}


        
</>

)
}
