import { Row, Col, Card, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { SlUserFollow } from "react-icons/sl";

import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';
import { NavLink,useLocation  } from 'react-router-dom';
import HeadMenu from './HeadMenu';
import { useEffect } from 'react';
import { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import './SideMenu.css'; 
export default function SideMenu(props)
{

  const [profileImage, setProfileImage]=useState('')
  const [userName, setUserName]=useState('')
  const location = useLocation();
  let userData
useEffect(()=>
{
  if(localStorage.getItem('user-info'))
	{
	  userData=localStorage.getItem('user-info')
		const details= JSON.parse(userData);
    setProfileImage(details.u_image) 
    setUserName(details.u_name)
}
},)



    return(
        <>
        
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"/>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"/>
        <div className='my-2'>
    <div class="row">
        <div class="col-md-12 static">
            <div class="profile-card text-center">
                <img src={profileImage?profileImage:"https://bootdey.com/img/Content/avatar/avatar1.png"} alt="user" class="profile-photo"/>
            	<h5 className="word-break" style={{ width: '100%', overflowWrap: 'break-word' }}><a href="#" class="text-white">{userName}</a></h5>
            	{/* <a href="#" class="text-white"><i class="fa fa-user"></i> 1,299 followers</a> */}
            </div>
            <ul className="nav-news-feed text-start">
            
  <NavLink to="/dash" >
    <li style={location.pathname === '/dash' ? { backgroundColor: '#99CCFF',borderRadius: '10px',padding: '10px' } : undefined}>
      <i className="fa fa-list-alt icon1" style={{ fontSize: 24, color: 'blue' }} />
      <div>Dashboard</div>
    </li>
  </NavLink>
  {/* className={(active) => (active ? "active" : undefined)} */}
  <NavLink to="/posturjob" >
  <li style={location.pathname === '/posturjob' ? { backgroundColor: '#99CCFF' ,borderRadius: '10px',padding: '10px' } : undefined}>
    <i class="fa fa-upload" style={{ fontSize: 24, color: 'blue' }}></i><div>Post Your Job</div></li>
  </NavLink>

  <NavLink to="/postedjoblist">
    <li style={location.pathname === '/postedjoblist' ? { backgroundColor: '#99CCFF' ,borderRadius: '10px',padding: '10px' } : undefined}>
      <i className="bi bi-list-task" style={{ fontSize: 24, color: 'blue' }} />
      <div>Posted Job </div>
      {/* <Badge bg="secondary"> {props.joblistposted}</Badge> */}
    </li>
  </NavLink>


     <NavLink to="/appliedjob">
     <li style={location.pathname === '/appliedjob' ? { backgroundColor: '#99CCFF' ,borderRadius: '10px',padding: '10px' } : undefined}>
      <i class="fa fa-briefcase" style={{ fontSize: 24, color: 'blue' }}></i><div>Applied Job</div></li>
  </NavLink>       
              
  <NavLink to="/searchjob" >
  <li style={location.pathname === '/searchjob' ? { backgroundColor: '#99CCFF',borderRadius: '10px',padding: '10px'  } : undefined}>
    <i class="fa fa-search" style={{ fontSize: 24, color: 'blue' }}></i><div>search Job</div></li>
  </NavLink>          

  <NavLink to="/resume">
      <li style={location.pathname === '/resume' ? { backgroundColor: '#99CCFF',borderRadius: '10px',padding: '10px'  } : undefined}>
        <i className="fa fa-file-text" style={{ fontSize: 24, color: 'blue' }}></i>
        <div>Resume</div>
      </li>
    </NavLink>
{/* Candidate is inactive as of now 
   <NavLink to="/candidatelist">
  <li style={location.pathname === '/candidatelist' ? { backgroundColor: '#99CCFF',borderRadius: '10px',padding: '10px'  } : undefined}>
    <i class="fa fa-users icon2"  style={{ fontSize: 24, color: 'blue' }}></i><div>Candidates</div></li>
  </NavLink>            */}

  {/* <NavLink to="/contactlist" className={(active) => (active ? "active" : undefined)}>
  <li><i class="fa fa-users icon2"  style={{ fontSize: 24, color: 'blue' }}></i><div>Contact</div></li>
  </NavLink> 

  <NavLink to="/followlist" className={(active) => (active ? "active" : undefined)}>
  <li><i class="bi bi-person-plus-fill" style={{ fontSize: 24, color: 'blue' }}></i><div>Follow</div></li>
  </NavLink> */}

  <NavLink to="/profile">
  <li style={location.pathname === '/profile' ? { backgroundColor: '#99CCFF',borderRadius: '10px',padding: '10px'  } : undefined}>
    <i class="bi bi-person-plus-fill" style={{ fontSize: 24, color: 'blue' }}></i><div>Profile</div></li>
  </NavLink>
  <NavLink to="/chat">
  <li style={location.pathname === '/chat' ? { backgroundColor: '#99CCFF',borderRadius: '10px',padding: '10px'  } : undefined}>
    <i class="bi bi-chat-fill" style={{ fontSize: 24, color: 'blue' }}></i><div>Chat</div></li>
  </NavLink>      
              
            </ul>
            {/* <div id="chat-block">
              <div class="title">Chat online</div>
              <ul class="online-users list-inline">
                <li><a href="#" title="Linda Lohan"><img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
                <li><a href="#" title="Sophia Lee"><img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
                <li><a href="#" title="John Doe"><img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
                <li><a href="#" title="Alexis Clark"><img src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
                <li><a href="#" title="James Carter"><img src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
                <li><a href="#" title="Robert Cook"><img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
                <li><a href="#" title="Richard Bell"><img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
                <li><a href="#" title="Anna Young"><img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
                <li><a href="#" title="Julia Cox"><img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="user" class="img-responsive profile-photo"/><span class="online-dot"></span></a></li>
              </ul>
            </div> */}
        </div>
	</div>

</div>
        </>
    )
}