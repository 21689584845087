import './JobChat.css';
import HeadMenu from "./HeadMenu";
import { useState,useEffect,useRef } from 'react';
import JobChatMessage from './JobChatMessage';
import io from 'socket.io-client';
import { Button } from 'react-bootstrap';
export default function JobChat()
{

  const [activeTab, setActiveTab] = useState('inbox');
  const [activeMessage, setActiveMessage] = useState(null);
  const [userChatList, setUserChatList] = useState([]);
  const [UserListBD, setUserListBD] = useState([]);
  const [usersList,setUsersList]=useState(() => {
    const storedList = localStorage.getItem('usersList');
    return storedList ? JSON.parse(storedList) : [];
  });
  
  
  const [socket, setSocket] = useState(null);
const [message, setMessage] = useState('');
const [conversations, setConversations] = useState([]);
const [conversationDetails, setConversationDetails] = useState({});
const [senderId, setSenderId] = useState(null);
const [recipientId, setRecipientId] = useState('');
const [recipientName, setRecipientName] = useState('');
const[newChatStart,isNewChatStart]=useState(false)
const[conversationId,setConversationId]=useState('')
const[countUpdate,setCountUpdate]=useState(false)
const [isButtonClicked, setIsButtonClicked] = useState(false);
const [clickedButtonId, setClickedButtonId] = useState(null);



useEffect(() => {
  localStorage.setItem('usersList', JSON.stringify(usersList));
}, [usersList]);




const handleTabClick = (tab) => {
  setActiveTab(tab);
  setActiveMessage(null);
};

useEffect(() => {
  if (localStorage.getItem('user-info')) {
    let userdata = localStorage.getItem('user-info');
    let parseVal = JSON.parse(userdata);
    setSenderId(parseVal.user_id);
    console.log("loginid",parseVal.user_id)
    
  }
}, []);

// Define the getConversationDetails function


const getConversationDetails = async (recipientId) => {
  if (senderId && recipientId) {
    console.log("iddetails", senderId, recipientId, senderId && recipientId)
    const response = await fetch(`${window.siteUrl}/conversation-details?senderId=${senderId}&recipientId=${recipientId}`);
    const details = await response.json();
    console.log("communicate123",details.conversationHistory,details.conversationHistory.length > 0)
    if(details.conversationHistory && details.conversationHistory.length > 0){
      if(details.conversationHistory[0].conversation_history !== "" && details.conversationHistory[0].conversation_history !== null){
        try {
          console.log("chatdeetails1",details.conversationHistory[0].conversation_history)
          const conversationHistory = JSON.parse(details.conversationHistory[0].conversation_history);
          setConversations(conversationHistory);
          console.log("conversations state updated to:", conversations);
          setConversationId(details.conversationId)
          console.log("conversationID123:", details.conversationId);
        } catch (error) {
          console.error("Error parsing conversation history:", error);
          setConversations([]);
          console.log("conversations state updated to:", conversations);
        }
      } else {
        setConversations([]); // Update this line
        console.log("conversations state updated to:", conversations);
      }
    } else{
      setConversations([]); // Update this line
      console.log("conversations state updated to:", conversations);
    }
  }
};



useEffect(() => {
  const newSocket = io('http://localhost:4500', {
    transports: ['websocket'],
  });

  newSocket.on('connect', () => {
    console.log('Connected to the server!');
    
        newSocket.emit('join', window.loggedIUser);
        console.log('Sent join event with userId:', window.loggedIUser);
       
    
  });

  newSocket.on('disconnect', () => {
    console.log('Disconnected from the server!');
    newSocket.emit('leave', window.loggedIUser);
    setTimeout(() => {
      newSocket.connect();
      newSocket.emit('getUsers', {}); // Request updated user list
    }, 1000); // Add a 1-second delay before reconnecting
  });

  newSocket.on('error', (err) => {
    console.error('Socket.IO Error:', err);
  });

  newSocket.on('newMessage', (messageData) => {
    console.log('Received new message:', messageData);
    setConversations((prevConversations) => [...prevConversations, messageData]);
  });

  newSocket.on('getUserList', (userList) => {
    console.log('Received user list:', userList);
    setUsersList(userList);
  });
 


  setSocket(newSocket);

  return () => {
    newSocket.disconnect();
  };
}, []);

// const handleRecipientClick = (recipientId) => {
//   setRecipientId(recipientId);
//   getConversationDetails();
// };

const handleSendMessage = () => {
  if (!message.trim()) return;

  try {
    const messageData = {
      conversationId: conversationDetails.conversationId,
      senderId: senderId,
      recipientId: recipientId,
      message,
    };

    socket?.emit('newMessage', messageData);
    setMessage('');
    getConversationDetails(recipientId);
  } catch (err) {
    console.error('Error sending message:', err);
  }
};

const handleMessageClick = (messageId,recipientId) => {
  setRecipientId(recipientId);
  setActiveMessage(messageId);
  getConversationDetails(recipientId);
  
  resetunreadcount()
};
const addToChatList=(recipientId)=>
{
  getConversationDetails(recipientId);
  //  setIsButtonClicked(true);
}
  useEffect(()=>
  {

  })
    useEffect(()=>{
      if(localStorage.getItem('user-info')){
        let userdata=localStorage.getItem('user-info')
        let parseVal=JSON.parse(userdata)
        let userIdAuto= parseVal.user_id
        fetch(`${window.siteUrl}/getuserchatlist/${userIdAuto}`,
      //fetch(`//allstoresprice.com:3443/getuserdetails/${usernumber}`,
      {
        headers: {
        authorization:'bearar'+' '+localStorage.getItem('auth')
      }
    }).then((result)=>
     {
      console.log("user details1",result)
       result.json().then((resp)=>{
         console.log("userchatlist",resp)
        //  setcount(getplist.length) 
            //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
            setUserChatList(resp.userChatList)
            setUserListBD(resp.conversationPartner)
            
            isNewChatStart(false)
            setCountUpdate(false)
            // if(resp[0].City!=null){
            // setcity(resp[0].City)
            // }
            //  setshowdetails(resp[0].showDetails)
            // setMobile(resp[0].user_number)
            // setareapincode(resp[0].user_areaCode)
            // setprofileimg(resp[0].profile_img)
            //  localStorage.setItem('shop-name',JSON.stringify(resp))  
       }) 
     }
     )
    }
    },[newChatStart,countUpdate]) 
    
    
      const chatBodyRef = useRef(null);

      useEffect(() => {
        if (chatBodyRef.current) {
          chatBodyRef.current.scrollTo(0, chatBodyRef.current.scrollHeight);
        }
      }, [conversations]);
   

      function resetunreadcount()
      {
          const data={conversationId,senderId}
    try{
        let pResult= fetch(window.siteUrl+"/resetunreadcount",
        {
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'authorization': 'bearar'+' '+localStorage.getItem('auth')
         },
         body: JSON.stringify(data)
       }).then((pResult) => {
         //console.log("user list", pResult)
         pResult.json().then((resp) => {
           console.log("unreadcountupdated", resp)  
           setCountUpdate(true)
    }
         )
       }
       )
    }catch
    {
    
    }
        }

        function getRandomColor() {
          const letters = '0123456789ABCDEF';
          let color = '#';
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }

    return(
<>

<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
<div class="container">
{/* <HeadMenu/> */}
    <div class="panel messages-panel my-2">
       
    <div className="contacts-list">
      <div className="inbox-categories" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          className={activeTab === 'inbox' ? 'active' : ''}
          onClick={() => handleTabClick('inbox')}
          style={{ width: '50%' }}
        >
          Chat
        </div>
        <div
          className={activeTab === 'sent' ? 'active' : ''}
          onClick={() => handleTabClick('sent')}
          style={{ width: '50%' }}
        >
          Users
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 'inbox' && (
          <div id="inbox" className="contacts-outter-wrapper tab-pane active">
            <form className="panel-search-form info form-group has-feedback no-margin-bottom">
              <div className="input-group">
                <input type="text" className="form-control" name="search" placeholder="Search" />
                <span className="input-group-addon" style={{ backgroundColor: 'transparent', border: 'none' }}>
                  {/* <i className="fa fa-search"></i> */}
                </span>
              </div>
            </form>
            <div className="contacts-outter">
              <ul className="list-unstyled contacts">
              {UserListBD &&
      UserListBD.length > 0 &&
      UserListBD.map((item, i)=>
      <>
      {console.log("userid",usersList,(item.user_id) )}
                <li  style={{ display: 'flex', alignItems: 'center' }}
                //activeMessage === 'inbox-message-1' ? 'active' : ''
                className={clickedButtonId === item.user_id ? 'active' : ''}
                onClick={() => handleMessageClick('inbox-message-1', item.user_id,setRecipientName(item.u_name),setClickedButtonId(item.user_id))}
              >
                  <div className="message-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <div className={`green-background message-count`} style={{ backgroundColor: usersList.includes(item.user_id) ? 'green' : '' }}>
    {item.unread_count}
  </div>
  <div className="img-circle medium-image m-2" style={{
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  fontSize: '24px', 
  fontWeight: 'bold', 
  width: '40px', 
  height: '40px', 
  borderRadius: '50%', 
  backgroundColor: '#f0f0f0', 
  color: getRandomColor() // Add this line
}}>
  {item.u_image !== null ? (
    <img 
      alt="" 
      src={item.u_image} 
      style={{ 
        width: '100%', 
        height: '100%', 
        borderRadius: '50%', 
        objectFit: 'cover' 
      }} 
    />
  ) : (
    item.u_name && item.u_name[0].toUpperCase()
  )}
</div>
</div>
                  <div className="vcentered info-combo">
                    <h3 className="no-margin-bottom name"> {item.u_name} </h3>
                    <h5 className="no-margin-bottom name">at {item.u_company_name} </h5>
                  </div>
                  <div className="contacts-add">
                    {/* <span className="message-time"> 2:32 <sup>AM</sup></span> */}
                    {/* <i className="fa fa-trash-o"></i> */}
                  </div>
                </li>
                </>
      )}
              </ul>
            </div>
          </div>
        )}
        {activeTab === 'sent' && (
          <div id="sent" className="contacts-outter-wrapper tab-pane active">
            <form className="panel-search-form success form-group has-feedback no-margin-bottom">
              <input type="text" className="form-control" name="search" placeholder="Search" />
              {/* <span className="fa fa-search form-control-feedback"></span> */}
            </form>
            <div className="contacts-outter">
              <ul className="list-unstyled contacts">
              {userChatList && userChatList.length > 0 && userChatList
    .filter((item) => !UserListBD.find((userItem) => userItem.user_id === item.user_id))
    .map((item, i) =>
      <>
                <li
                className={activeMessage === 'inbox-message-1' ? 'active' : ''}
                
              >
                  {/* <div className="message-count"> 1 </div> */}
                  <div className={`green-background message-count`} style={{ backgroundColor: usersList.includes(item.user_id) ? 'green' : '' }}>
    
  </div>
                  <div className="img-circle medium-image m-2" style={{
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  fontSize: '24px', 
  fontWeight: 'bold', 
  width: '40px', 
  height: '40px', 
  borderRadius: '50%', 
  backgroundColor: '#f0f0f0', 
  color: getRandomColor() // Add this line
}}>
  {item.u_image !== null ? (
    <img 
      alt="" 
      src={item.u_image} 
      style={{ 
        width: '100%', 
        height: '100%', 
        borderRadius: '50%', 
        objectFit: 'cover' 
      }} 
    />
  ) : (
    item.u_name && item.u_name[0].toUpperCase()
  )}
</div>
                  <div className="vcentered info-combo">
                    <h3 className="no-margin-bottom name"> {item.u_name} </h3>
                    <h5 className="no-margin-bottom name">at {item.u_company_name} </h5>
                  </div>
                  <div className="contacts-add" >
                  <Button
              className="message-time text-center"
              onClick={() => {
                addToChatList(item.user_id, isNewChatStart(true));
                setClickedButtonId(item.user_id);
              }}
              style={{
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                backgroundColor: clickedButtonId === item.user_id ? 'grey' : '',
                cursor: clickedButtonId === item.user_id ? 'not-allowed' : 'pointer',
                opacity: clickedButtonId === item.user_id ? 0.5 : 1,
              }}
              disabled={clickedButtonId === item.user_id}
            >
              Start Chat
            </Button>
                    {/* <i className="fa fa-trash-o"></i> */}
                  </div>
                </li>
                </>
      )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  
    {activeMessage === 'inbox-message-1'&& (
            <div class="tab-pane message-body active" id="inbox-message-1">
            
                <div class="message-chat">
                <div className="chat-body" ref={chatBodyRef}>
  {console.log("Conversations array:", conversations)}
  {conversations.map((conversation, index) => {
    console.log("Conversation object:", conversation)
    return (
      <div key={index} className={`message ${conversation.senderId === senderId ? 'my-message' : 'info'}`}>
        {/* <img alt="" className="img-circle medium-image" src={conversation.senderId === senderId ? conversation.senderImage : conversation.recipientImage} /> */}
        <div className="message-body">
          <div className="message-info">
            {/* <h4>{conversation.senderId === senderId ? 'You' : conversation.recipientName}</h4> */}
            <h4>{conversation.senderId === senderId ? 'You' : recipientName}</h4>
            <h5>
              <i className="fa fa-clock-o"></i> {new Date(conversation.date).toLocaleTimeString()}
            </h5>
          </div>
          <hr />
          <div className="message-text">
            {conversation.message}
          </div>
        </div>
        <br />
      </div>
    )
  })}
  {console.log("Conversations rendered")}
</div>

                    {/* <div class="chat-footer">
                        <textarea class="send-message-text"></textarea>
                        <label class="upload-file">
                            <input type="file" required=""/>
                            <i class="fa fa-paperclip"></i>
                        </label>
                        <button type="button" class="send-message-button btn-info"> <i class="fa fa-send"></i> </button>
                    </div> */}

<div>
      <div className="chat-footer">
        <textarea
          className="send-message-text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {/* <label className="upload-file">
          <input type="file" required="" />
          <i className="fa fa-paperclip" />
        </label> */}
        <button
          type="button"
          className="send-message-button btn-info"
          onClick={handleSendMessage}
        >
          <i className="fa fa-send" />
        </button>
      </div>
      <ul>
        {/* {conversations.map((conversation, index) => (
          <li key={index}>
            {conversation.senderId}: {conversation.message}
          </li>
        ))} */}
      </ul>
    </div>
                </div>
            </div>    
    )}
    
</div>
</div>
</>
    )
}