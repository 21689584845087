import Container from 'react-bootstrap/Container';
import { useEffect,useState } from 'react';
import UserResumeDetails from './UserResumeDetails';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Card, Button } from 'react-bootstrap';
import ResumeDetails from './ResumeDetails';

export default function ContactList(props)
{
  return null;
  
  const [allcontact,setallcontact]=useState([])
  const [username,setusername]=useState('')
  const [status,setStatus]= useState(false)
  // let searchKey=props.getSearchKey 
  let getStatusCandidate=props.status
  const [searchKey,setSearchKey]=useState('')

  const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);

  useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id

      let data={userIdAuto,searchKey}
      let datas=JSON.stringify(data)
      console.log("contactloop",datas)

      fetch(`${window.siteUrl}/contactlist/${datas}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
    console.log("contactlist1",result)
     result.json().then((resp)=>{
      setallcontact(resp) 
          console.log("contactlist",resp)
          setStatus(false)
     }) 
   }
   )
  }
  },[status,searchKey,getStatusCandidate])

  function deleteapplication(applicationid)
  {
    let data = applicationid
  fetch(`${window.siteUrl}/deletecontact/${data}`,
  {
  method:'DELETE',
  
    headers: {
    authorization:'bearar'+' '+localStorage.getItem('auth')
  }
  
  }).then((result)=>
   {
  //   setapistatus(result.status)
  setStatus(true)
  result.json().then((resp)=>
  {
    
   console.log("deleteapplication",resp)
   handleClose()
   setTimeout(() => {
    
    setStatus(false)
   },4000)
   })
   })
  }
  
    let [getvalue,setvalue]=useState()
  
    function setdeldata(id)
  {
    console.log("delete")
  handleShow ()
    setvalue(id)
   
  }

  props.getcounter?.(allcontact.length);
  
  // const ContactList = (props) => {
   
  //   if (props.getcounter) {
  //     props.getcounter(allcontact.length);
  //   }

  // };
    return(
        <>
         <Container fluid='md' className='my-0 '  >
         {/* <h2 className="headingbackground p-3" style={{ borderRadius: '4px' }}>My Contact List</h2> */}
         {/* <div class="card mb-3">
      <div class="card-body p-2">
        <div class="d-flex flex-column flex-lg-row">
        <div class="col-sm-2">
          <h6 class="h6">Photo</h6>
            </div>
          <div class="row flex-fill">
            <div class="col-sm-5">
            <h6 class="h6">Name/Email</h6>
            </div>
            <div class="col-sm-4  ">
            <h6 class="h6">Group</h6>
            </div>
            <div class="col-sm-3 text-lg-end">
            <h6 class="h6">Action</h6>
            </div>
          </div>
        </div>
      </div>
      
    </div> */}

<form className="d-flex justify-content-start my-2 ml-auto" style={{ width: "50%" }}>
          <input
            className="form-control"
            type="search"
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder="Search People/company/skill"
            aria-label="Search"
          />
        </form>

{allcontact &&
      allcontact.length > 0 &&
      allcontact.map((item, i)=>
      <div class="card mb-2">
      <div class="card-body">
        <div class="d-flex flex-column flex-lg-row">
        <div class="col-sm-2">
          <div class="candidate-list-images">
          <a href="#"><img src={item.u_image?item.u_image:"https://bootdey.com/img/Content/avatar/avatar3.png" }alt="" class="profile-photo-jd" /></a>
               </div>
               <span class="text-wrap">{item.user_name} </span>               
                                      </div>
          <div class="row flex-fill">
            <div class="col-sm-5">
              <h6 class="h6">{item.jobtitle}</h6>
              <ul class="list-inline mb-0 text-muted">
            <li class="list-inline-item"><i class="bi bi-building"></i>{item.email_id}</li>
                                                  {/* <li class="list-inline-item"> 5-7 year</li> */}
             </ul>

            </div>
            <div class="col-sm-4 py-1 ">
              <span class="badge bg-secondary text-wrap">Group </span>
              

            </div>
            <div class="col-sm-3 text-lg-end p-1">
            <ResumeDetails  uemail={item.email_id}rname={item.user_name}rtitle={item.jobtitle} rcertificate={item.certificate} rexp_year={item.exp_year}rexp_month={item.exp_month} rskill={item.skill}ucompany={item.company} rdesc={item.resume_des} rattachment={item.resume_attachment}/>
            <Button variant="outline-danger" className="m-1" onClick={()=>setdeldata(item.contact_id,setusername(item.user_name))}>Delete</Button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
      )}
    </Container>
    <Modal show={showdel} onHide={handleClose}>
<Modal.Header closeButton>
  <Modal.Title>Delete Application</Modal.Title>
</Modal.Header>
<Modal.Body>Do you want to this  <b>{username}</b>
    
</Modal.Body>
<Modal.Footer>
 <Button variant="primary" onClick={()=>deleteapplication(getvalue)}>
    Delete
  </Button> 
  <Button variant="secondary" onClick={handleClose}>
    No
  </Button>
</Modal.Footer>
</Modal> 
{/* {props.getcounter(allcontact.length)} */}
        </>
    )
}