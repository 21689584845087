import Form from 'react-bootstrap/Form';
import { useState,useEffect } from 'react';

export default function IndustryList(props)
{
    const [selectedOption, setSelectedOption] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    
    // const options=props.getdblist

    const options = [
      { value: 'BPO Industry', label: 'BPO Industry' },
      { value: 'IT Industry', label: 'IT Industry' },
      { value: 'Call Center', label: 'Call Center' },
      { value: 'Manufacturing', label: 'Manufacturing' },
      { value: 'Support', label: 'Support' },
      
    ];
    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const handleBlur = () => {
        if (searchTerm.trim() !== '') { // Check if searchTerm is not empty
          if (!options.find((option) => option.value === searchTerm)) {
            setSelectedOption(searchTerm);
          } else {
            const selected = options.find((option) => option.value === searchTerm);
            setSelectedOption(selected.value);
          }
          setSearchTerm('');
        }
      };
    
      const handleRemoveOption = () => {
        setSelectedOption('');
      };

      useEffect(() => {
        console.log("clear",props.clearstatus)
        if (props.clearstatus) {
          setSelectedOption('');
        }
      }, [props.clearstatus]);

      useEffect(() => {
        if (props.getdefaultindustry && selectedOption !== props.getdefaultindustry) {
          setSelectedOption(props.getdefaultindustry);
        }
      }, [props.getdefaultindustry]);


    return(
        <>
        <Form.Group>
         <div className="d-flex align-items-center">
           <input
             className="form-control w-100"
             type="text"
             list="industry"
             value={selectedOption || searchTerm}
             onChange={handleOnChange}
             onBlur={handleBlur}
             placeholder="Enter Industry (optional)"
             autoComplete="off"
             onFocus={(e) => e.target.select()}
           />
           {selectedOption && (
             <button
               type="button"
               onClick={handleRemoveOption}
               className="remove-option btn btn-link"
             >
               &#x2716;
             </button>
           )}
         </div>
         <datalist id="industry">
           {options.map((option) => (
             <option value={option.value} key={option.value}>
               {option.label}
             </option>
           ))}
         </datalist>
       </Form.Group>
       {props.selindustry(selectedOption)}
               </>
    )
}