
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { NavLink,Link,useNavigate } from "react-router-dom";
import MobileOtp from './MobileOtp';
import isEmail from 'validator/es/lib/isEmail';
export default function SignUp()
{
  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

  const navigate=useNavigate();
    const [getMobile,setMobile]=useState('')
    const [getPin,setPin]=useState('') 
    const [getuserName,setuserName]=useState('') 
    const [getuserEmail,setuserEmail]=useState('') 
    const [getAreaPinCode,setAreaPinCode]=useState('') 
    const [getOtp,setOtp]=useState("f") 
    
    const [getMessage, setMessage]= useState("")
    let data={getMobile,getPin,getuserName,getAreaPinCode,getuserEmail}
    const [getresponse,setresponse]=useState([])
    const [getisloginissue, setisloginissue]=useState(false);
  const [getlength, setlength]=useState();
  const [getduplicaterecerror, setduplicaterecerror]=useState(false);
  const [getenable, setdisable]=useState(true)
  const [isotprequired, setrequiredotp]=useState(true)
  
  
    let getOtpstatus="false";
    function cancel()
    {
      navigate('/')
      
    }
    function saveUserDetails()
    {
        try{
           //let pResult= fetch("//allstoresprice.com:3443/userdetails",
            
           let pResult= fetch(window.siteUrl+"/signprofile",
            
             {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            }).then((pResult) => {
            //  console.log("user list", pResult)
              setresponse(pResult.status)
              pResult.json().then((resp) => {
             //    console.log("Post lefpro", resp)
             console.log("resp.code",resp)
                if(resp.affectedRows>0)
            {         
            // setlength(resp.result.affectedRows) 
            //  localStorage.setItem('user-info',JSON.stringify(resp.usedata))
            //  localStorage.setItem('auth',JSON.stringify(resp.auth))
            // console.log("length is"+resp.result.length)
            
            if( resp.affectedRows>=1)
            {
      //  
      // {<MobileOtp email={getuserEmail}/>}
      // navigate('/otp')
      setrequiredotp(false)
       }
            
            } else if(resp.code=="ER_DUP_ENTRY"|| resp.affectedRows==0)
            {
              setduplicaterecerror(true)
            }else{
              // setduplicaterecerror(true)
              setisloginissue(true)
            } 
              })
              
            })
          }catch(error)
          {
            console.log("update error",error)
          }
          
        }

//         useEffect(()=>
//         {
//           if(getresponse=='200')
//           {
// navigate('/otp')
// }else{
//   console.log("some issue")
// }

//         },[getresponse])

    const signup=()=>
    {
       // console.log({getMobile,getPin})
        
        // setOtp("t")
        saveUserDetails(); 
    }
    
    useEffect(()=>{
      if (getduplicaterecerror==true )
      {
        setTimeout(() => {
  
          setduplicaterecerror(false)
        },2000)
      }else 
      {
        setTimeout(() => {
  
          setisloginissue(false)
        },2000)
      }
    },[getduplicaterecerror])
   
    useEffect(()=>
       {
        if( getPin.length==4 &&isEmail(getuserEmail))
        {
          setdisable(false)
        }else
        {
          setdisable(true)
        }
       }) 
    
    return(
      
      
<>

{/* <AddC/> */}

{isotprequired?
<>
<form className='container formC col-sm-4 col-lg-4 col-md-6 col-11 p-3 my-3' style={{ backgroundColor: '#20c997' }}>
<div className="form-group d-flex justify-content-center ">
<div className="col-sm-10 col-lg-10 col-md-10 col-10  align-self-center textformat">
<div>
{getduplicaterecerror?
<label for="floatingInput" className='text-danger fw-bold'>Record already exist</label>
:
null
}
{getisloginissue?
<label for="floatingInput" className='text-danger fw-bold'>Technical Problem, Try later</label>
:
null
}

</div>
<label for="floatingInput" className='loginLb'>Sign up</label>

{/* <input maxLength={25} onChange={(e)=>setuserName(e.target.value)}type="text" placeholder="Enter Your Name" 
className="form-control m-3 text-center fs-5 userName" id="userName"></input>

<input onInput={(e) => e.target.value = e.target.value.slice(0, 10)} onChange={(e)=>setMobile(e.target.value)}type="number" placeholder="Enter Mobile number" 
className="form-control m-3 text-center fs-5 mobileNumber" id="typePhone" name="phone" ></input>
{getMobile.length>=4 && getMobile.length<10?
  <label for="floatingInput" className='text-danger'>Enter 10 digit mobile number</label>
  :
  null
}
<input maxLength={6} onChange={(e)=>setAreaPinCode(e.target.value)}type="text" placeholder="Enter Area Code" 
className="form-control m-3 text-center fs-5 AreaPin" id="AreaPin" ></input>
{getAreaPinCode.length>=3 && getAreaPinCode.length<6?
  <label for="floatingInput" className='text-danger'>Enter 6 digit Area code</label>
  :
  null
} */}

{/* <hr></hr> */}
<input onChange={(e)=>setuserEmail(e.target.value)}type="email" placeholder="Enter Your Email-ID" 
className="form-control m-3 text-center fs-5 userEmailid" id="typeEmail" name="email" ></input>
{isEmail(getuserEmail)?
  null
  :
  <label for="floatingInput" className='text-danger'>Enter Correct Email Id</label>
}

<input maxLength={4} onChange={(e)=>setPin(e.target.value)}type="text" placeholder ="Set 4 digit Pin" 
className="form-control m-3 text-center fs-5 pinNumber "id="pin">  
</input>
{getPin.length>=3 && getPin.length<4?
  <label for="floatingInput" className='text-danger'>Enter 4 digit login pin</label>
  :
  null
}
<label for="floatingInput" className='note'>Remember above pin for login</label>
<label for="floatingInput" className='note' style={{fontSize: '10px'}}>Sign up will considar acceptance of <NavLink to = "/privacypolicy" target="_blank" >Privacy Policy</NavLink></label>

<div>
{/* <PropsFunctionCall data={"ok"}/> */}
<Button onClick={signup}variant="primary"disabled={getenable}>Continue</Button>{' '} 
<Button onClick={cancel}variant="danger">Cancel</Button>{' '}  
</div>
 {/* <NavLink to = "/otp"><Button onClick={signup}variant="secondary">Continue</Button>{' '} </NavLink>   */}
</div>
</div>

{/*  */}
</form>
</>
:
<MobileOtp email={getuserEmail}/>
}
</>


    )
}