import { useEffect, useState,useRef  } from "react"
import axios from 'axios';

export default function PromoteJob(props)
{
    const [getstatus,setstatus]=useState(false)
    
    const [promotedCount, setPromotedCount] = useState(props.jobs.filter(job => job.promoted === "yes").length);
    
    useEffect(() => {
      if(getstatus==true)
      {
        document.location.reload();
      }
    }, [props.jobs,getstatus]);
    
    function promoteJob()
    {
         let count= promotedCount
    let jobid=props.jobid     
  
      let data={jobid}
      console.log("plandata",data)
  //console.log("reactui",formData)


  
  axios.post(window.siteUrl+'/promoteyourjob',data,
  // axios.post('//allstoresprice.com:3443/updateshopstatusondeleteofaccount',data,
   {
     headers: {
     authorization:'bearar'+' '+localStorage.getItem('auth')
   }
  })
  .then(res=>
    {
      if (res.status=='200')
      {
      setstatus(true)
      setPromotedCount(count+1)
      console.log("plan",res.status)
      }
      
    }, 
  res1=>console.log("shop",res1))
  .catch(err=>console.log(err))
  
  // setTimeout(() => {
  
  //   setstatus(false)
  //  },4000)
  }
    return(
       
       
       <>
       
       {console.log("promotedCount", promotedCount)}
     
        
      {/* {props.isjobpromoted==='yes' || getstatus==true ? 
  <span style={{ fontSize: 'small', color: 'green', fontWeight: 'bold', fontStyle: 'italic' }}> promoted </span> : 
  (promotedCount >= (props.plan === 'basic' ? 1 : props.plan === 'standard' ? 2 : 3)) ? 
    <span style={{ fontSize: 'small', color: 'gray', fontStyle: 'italic', pointerEvents: 'none' }}> promote (limit reached) </span> : 
    (promotedCount < (props.plan === 'basic' ? 1 : props.plan === 'standard' ? 2 : 3)) ? 
      <span style={{ fontSize: 'small', color: 'blue', fontStyle: 'italic', cursor: 'pointer' }} onClick={promoteJob}> promote </span> : 
      <span style={{ fontSize: 'small', color: 'gray', fontStyle: 'italic', pointerEvents: 'none' }}> promote </span>
} */}

{props.isjobpromoted==='yes' || getstatus==true ? (
<span style={{ fontSize: 'small', color: 'green', fontWeight: 'bold', fontStyle: 'italic' }}> promoted </span>
) : (
(props.plan === 'basic' || props.plan === 'standard' || props.plan === 'premium') ? (
promotedCount >= (props.plan === 'basic' ? 1 : props.plan === 'standard' ? 2 : 3) ? (
<span style={{ fontSize: 'small', color: 'gray', fontStyle: 'italic', pointerEvents: 'none' }}> promote (limit reached) </span>
) : (
<span style={{ fontSize: 'small', color: 'blue', fontStyle: 'italic', cursor: 'pointer' }} onClick={promoteJob}> promote </span>
)
) : null
)}
        </>
        
    )
}