import { useState } from "react"
import { useEffect } from "react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import axios from 'axios';

export default function PromotedJobs(props)
{
  return null;
  const [startDate,setStartDate]=useState('')
  const [endDate,setEndDate]=useState('')
  const [planPrice,setPlanPrice]=useState('')
  const [planDays,setPlanDays]=useState('')
  const [planName,setPlanName]=useState('')
  const [getstatus,setstatus]=useState(false)
  const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);

const [userId,setUserId]=useState('')
  const [jobId,setJobId]=useState('')
  const [jobTitle,setJobTitle]=useState('')

  useEffect(()=>
  {

    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      setStartDate(parseVal.planstartdate)
      setEndDate(parseVal.planexpdate)
      setPlanPrice(parseVal.plan_price)
      setPlanDays(parseVal.plan_days)
      setPlanName(parseVal.planselected)
    }
  },[])

function deletePrompt(userId,jobId,jobTitle)
{
  setUserId(userId)
  setJobId(jobId)
  setJobTitle(jobTitle)
  handleShow()
}

function deletePromotedJob()
{
  let data={userId,jobId,jobTitle,startDate,endDate,planName,planPrice,planDays}
  
console.log("reactui",data)

axios.post(window.siteUrl+'/deletepromotedjob',data,
// axios.post('//allstoresprice.com:3443/updateshopstatusondeleteofaccount',data,
{
 headers: {
 authorization:'bearar'+' '+localStorage.getItem('auth')
}
})
.then(res=>
{
 if(res.status==200)
 {
  setstatus(true)
}
  console.log("promote",res.status)
  handleClose()
}, 
res1=>console.log("shop",res1))
.catch(err=>console.log(err))

 setTimeout(() => {

   setstatus(false)

 }, 4000)

}
  
    return(
        <>
        <div className="card-header my-3 d-flex justify-content-between align-items-center">Promoted jobs</div>
        <div style={{ overflowX: 'auto' }}>
        <table className="table text-start">
        <thead>
          <tr>
          <th>Job Id</th>
            <th>Job Title</th>
            {/* <th>Job Description</th> */}
            <th>Job Skills</th>
            <th>Company</th>
            <th>Posted Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
  {Array.isArray(props.promotedlist) ? (
    props.promotedlist.map((job, index) => (
      <tr key={index}>
        <td>{job.job_id_m}</td>
        <td>{job.job_title}</td>
        {/* <td>{job.j_desc}</td> */}
        <td>{job.j_skill}</td>
        <td>{job.u_company_name}</td>
        <td>{new Date(job.j_curr_date).toLocaleDateString('en-GB')}</td>
        <td>
        <button className="btn btn-danger" onClick={()=>deletePrompt(job.user_id,job.job_id_a,job.job_title)}>
          Delete
        </button>
      </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={5}>No promoted jobs found</td>
    </tr>
  )}
</tbody>
      </table>   
        </div>
        <Modal show={showdel} onHide={handleClose}>
<Modal.Header closeButton>
  <Modal.Title>Delete Resume</Modal.Title>
</Modal.Header>
<Modal.Body>Do you want to remove this promotion <b>{props.selectedplan}</b>
   
</Modal.Body>
<Modal.Footer>
 <Button variant="primary" onClick={deletePromotedJob}>
    Remove
  </Button> 
  <Button variant="secondary" onClick={handleClose}>
    Close
  </Button>
</Modal.Footer>
</Modal> 
{props.getupdatedstatus(getstatus)}  
        </>
        
    )
}