
import { Row, Col, Card, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import JobApplication from './JobApplication';
import Accordion from 'react-bootstrap/Accordion';
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

export default function PostedSavedJobList(props)
{
  const [status,setStatus]= useState('')
    const [allJobData,setAllJobData]=useState([])
    const [getDraftJobCount,setDraftJobCount]=useState('')
    const [getImage,setImage]=useState('')
    const [getUserId,setUserId]=useState('')
    const [getCompany,setCompany]=useState('')
    const [searchKey,setSearchKey]=useState('all')
    
    
    const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);


const [getjobname,setjobname]=useState('')
   
const [getid, setid]=useState('')
    useEffect(()=>
    {
  
      if(localStorage.getItem('user-info')){
        let userdata=localStorage.getItem('user-info')
        let parseVal=JSON.parse(userdata)
        setid(parseVal.user_id)
      }
    })

    
    function updatedraftjobstatus()
  {
      const data={getid}
try{
    let pResult= fetch(window.siteUrl+"/updatedraftjobstatus",
    {
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'authorization': 'bearar'+' '+localStorage.getItem('auth')
     },
     body: JSON.stringify(data)
   }).then((pResult) => {
     //console.log("user list", pResult)
     pResult.json().then((resp) => {
       console.log("updateposted job status", resp)  
       if(resp.result.affectedRows>=1)
       {
         setStatus(true)
       }
       setTimeout(() => {
        setStatus(false)
      }, 4000)
}
     )
   }
   )
}catch
{

}
    }

  useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id
      let data={userIdAuto,searchKey}
      const datas=JSON.stringify(data)
     
      fetch(`${window.siteUrl}/getjobdata/${datas}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
     result.json().then((resp)=>{
      setAllJobData(resp) 
          console.log("jobdata",resp)
          const draftCount = resp.filter((job) => job.j_status === "draft").length;
          setDraftJobCount(draftCount)
     }) 
   }
   )
  }
  },[props.sendstatus,status])

  let [getvalue,setvalue]=useState()

  function setdeldata(id)
{
  console.log("delete")
handleShow ()
  setvalue(id)
 
}

function deleteapplication(applicationid)
{
  let data = applicationid
fetch(`${window.siteUrl}/deletejobs/${data}`,
{
method:'DELETE',

  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}

}).then((result)=>
 {
//   setapistatus(result.status)
setStatus(true)
       
  
result.json().then((resp)=>
{
  
 console.log("deleteapplication",resp)
 handleClose()
 setTimeout(() => {
  
  setStatus(false)
 },1000)
 })
 })
}

    return(
      <>
        <Container fluid='md' className='my-0'  >
          
          <div className="resumeheader">Submit Your Jobs</div>
          {
            status?
            <span className="text-success">
Record updated successfully
            </span>:
            null
          }
          {allJobData &&
      allJobData.length > 0 &&
      allJobData.map((item, i)=>
      item.j_status=="draft"?
          <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex flex-column flex-lg-row">
        <div class="col-sm-2">
          {/* avatar-lg img-thumbnail rounded-circle */}
                                          <div class="candidate-list-images">
                                              <a href="#"><img src={item.user_image?item.user_image:"https://bootdey.com/img/Content/avatar/avatar3.png" }alt="" class="profile-photo-jd" /></a>
                                          </div>
                                          <span class="text-wrap">{item.user_name} </span> 
                                      </div>
          <div class="row flex-fill">
            <div class="col-sm-5">
              <h5 class="h5">{item.job_title}</h5>
              <ul class="list-inline mb-0 text-muted">
                                                  <li class="list-inline-item"><i class="bi bi-building"></i>{item.u_company_name} {item.j_exp_from_year}-{item.j_exp_to_year} year</li>
                                                  {/* <li class="list-inline-item"> 5-7 year</li> */}
                                              </ul>
              <span class="badge bg-secondary text-wrap"> <i class="bi bi-geo-alt"></i>{item.j_city} </span> <span class="badge bg-success">{item.j_salary_from}k - {item.j_salary_to}k</span> 
              {/* <span class="">25-10-2025</span> */}
            </div>
            <div class="col-sm-4 py-2 ">
            <span class="badge bg-secondary text-wrap text-break ">{item.j_skill} </span>
            <span class="badge bg-secondary text-wrap text-break">{item.j_certificate}</span>

            </div>
            <div class="col-sm-3 text-lg-end p-1">
              {/* <a href="#" class="btn btn-primary  m-1">Edit</a> */}
              <Button variant="outline-danger" className="m-1" onClick={()=>setdeldata(item.job_id_a,setjobname(item.job_title))}>Delete</Button>
            </div>
          </div>
        </div>
      </div>
      </div>
      :null
      )}

{/* <Card style={{ width: '100%' }} className='my-1'>
  <Card.Body>
    <Row>
      <Col xs={3}sm={2}>
      <figure>
    <Card.Img variant="top" src="holder.js/50px100?text=Image cap" />
    <figcaption className="text-center">image</figcaption>
  </figure>
      </Col>
      <Col xs={9}sm={3}  className="text-center">
      <Card.Text className="fw-bold companyname">Company Name</Card.Text>
        <Card.Text className='postedondate'><strong className='postedontext'>Posted on -</strong>25/02/2025</Card.Text>
        </Col>
        <Col xs={12}sm={5}  className="text-center requirement">
        <Card.Text className="text-muted mb-1">Job Title [Insert education requirements]</Card.Text>
        <Card.Text className="mb-1">Pune, Mumbai, Noida, Year of Exp</Card.Text>
        <Card.Text className="mb-0">Required Skill dfadsf adfa dfa dsfadsfadfad fg dfadsf adfads  fdasf asd</Card.Text>
        </Col>
        <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-end " >

              <FcViewDetails className='fs-2'/>
                </Col>
                <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-start ">
                <AiOutlineDelete className='fs-2 text-danger'/>
</Col>
    </Row>
    
  </Card.Body>
</Card> */}




<Button variant="success" className="me-3 my-2"onClick={updatedraftjobstatus} disabled={getDraftJobCount<1}>
      Submit
    </Button>
    {props.draftJobCount(getDraftJobCount)}
        </Container>
    
    <Modal show={showdel} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Delete Application</Modal.Title>
    </Modal.Header>
    <Modal.Body>Do you want to delete this  <b>{getjobname}</b> drafted job?
       
    </Modal.Body>
    <Modal.Footer>
     <Button variant="primary" onClick={()=>deleteapplication(getvalue)}>
        Delete
      </Button> 
      <Button variant="secondary" onClick={handleClose}>
        No
      </Button>
    </Modal.Footer>
    </Modal> 
   </>  
    
    )
    
}
