import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ToastNot from './ToastNot';
import Container from 'react-bootstrap/Container';

import './Dashboard.css';
import JobChatMessage from './JobChatMessage';
import PlanStatusDashboard from './PlanStatusDashbaord';
import PromotedJobs from './PromotedJobs';
import PromotedJobDisplay from './PromotedJobDisplay';

export default function DashboardContent()
{
    const navigate = useNavigate();
    const [totalJobs, setTotalJobs] = useState(0);
    const [totalJobsTarget, setTotalJobsTarget] = useState('');
  
    const [companies, setCompanies] = useState(0);
    const [companiesTarget, setCompaniesTarget] = useState(1853);
  
    const [jobApplied, setJobApplied] = useState(0);
    const [jobAppliedTarget, setJobAppliedTarget] = useState('');

    const [jobPosted, setJobPosted] = useState(0);
    const [jobPostedTarget, setJobPostedTarget] = useState('');

    const [myContact, setMyContact] = useState(0);
    const [myContactTarget, setMyContactTarget] = useState('');

    const [applicationReceived, setApplicationReceived] = useState(0);
    const [applicationReceivedTarget, setApplicationReceivedTarget] = useState('');

    const [followedJob, setfollowedJob] = useState(0);
    const [followedJobTarget, setFollowedJobTarget] = useState('');
    const [getPromotedJobs, setPromotedJobs] = useState('');

    const[getstatus,setstatus]=useState(false)

    useEffect(()=>{
        
        if(localStorage.getItem('user-info')){
          let userdata=localStorage.getItem('user-info')
          let parseVal=JSON.parse(userdata)
          let userIdAuto= parseVal.user_id
        
    const data={userIdAuto}
    const datas=JSON.stringify(data)
          fetch(`${window.siteUrl}/getalljobdatadashboard/${datas}`,
        {
          headers: {
          // authorization:'bearar'+' '+localStorage.getItem('auth')
        }
      }).then((result)=>
       {
        console.log("joballdatacount1",result)
         result.json().then((resp)=>{
           
            setTotalJobsTarget(resp.counts.total_jobs_others)
            setJobAppliedTarget(resp.counts.total_applied_mine)
            setJobPostedTarget(resp.counts.total_jobs_mine)
            setMyContactTarget(resp.counts.total_contacts_mine )
            setApplicationReceivedTarget(resp.counts.total_applied_posted)
            setFollowedJobTarget(resp.counts.followed_job_count)
            setPromotedJobs(resp.promotedJobs)
              console.log("joballdatacount",resp)
              
         }) 
       }
       )
    }
      },[getstatus])

    useEffect(() => {
        const intervalId = setInterval(() => {
          if (totalJobs < totalJobsTarget) {
            setTotalJobs(totalJobs + 1);
          }
          if (companies < companiesTarget) {
            setCompanies(companies + 1);
          }
          if (jobApplied < jobAppliedTarget) {
            setJobApplied(jobApplied + 1);
          }
          if (myContact < myContactTarget) {
            setMyContact(myContact + 1);
          }
          if (applicationReceived < applicationReceivedTarget) {
            setApplicationReceived(applicationReceived + 1);
          }
          if (jobPosted < jobPostedTarget) {
            setJobPosted(jobPosted + 1);
          }
          if (followedJob < followedJobTarget) {
            setfollowedJob(followedJob + 1);
          }
        }, 1);
        return () => clearInterval(intervalId);
      }, [totalJobs, companies, jobApplied,jobPosted,followedJob]);

    return(
      <div className="container-fluid">
           {/* <Container fluid className="my-1"> */}
      <h2 className="dashboard-heading">Job Dashboard </h2>

      <div className="row">
        <div className="col-lg-3 col-sm-6 card-box bg-blue " onClick={() => navigate('/searchjob')} style={{ cursor: 'pointer'}}>
          <div className="inner" style={{
    transform: 'translateY(5px)' }}>
            <h3>{totalJobs}</h3>
            <p className="text-white">Total Jobs</p>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 card-box bg-orange" onClick={() => navigate('/appliedjob')} style={{ cursor: 'pointer' }}>
          <div className="inner"style={{
    transform: 'translateY(5px)' }}>
            <h3>{jobApplied}</h3>
            <p className="text-white">Your Applied Jobs</p>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 card-box bg-red" onClick={() => navigate('/postedjoblist')} style={{ cursor: 'pointer' }}>
          <div className="inner"style={{
    transform: 'translateY(5px)' }}>
            <h3>{jobPosted}</h3>
            <p className="text-white">Your Posted Jobs</p>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 card-box bg-purple" onClick={() => navigate('/postedjoblist')} style={{ cursor: 'pointer' }}>
          <div className="inner"style={{
    transform: 'translateY(5px)' }}>
            <h3>{applicationReceived}</h3>
            <p className="text-white">Applications Received</p>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 card-box bg-teal" onClick={() => navigate('/searchjob')} style={{ cursor: 'pointer' }}>
          <div className="inner"style={{
    transform: 'translateY(5px)' }}>
            <h3>{followedJob}</h3>
            <p className="text-white">Followed Jobs</p>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 card-box bg-green" onClick={() => navigate('/candidatelist')} style={{ cursor: 'pointer' }}>
          <div className="inner"style={{
    transform: 'translateY(5px)' }}>
            <h3>{myContact}</h3>
            <p className="text-white">Your Contacts</p>
          </div>
        </div>
        <PlanStatusDashboard />
        <PromotedJobs promotedlist={getPromotedJobs} getupdatedstatus={setstatus}/>
      </div>
       {/* </Container> */}
       
    </div>
    
  );
};

   