
import { Row, Col, Card, Button } from 'react-bootstrap';
import { FaRegShareSquare } from "react-icons/fa";
import Container from 'react-bootstrap/Container';

import ListGroup from 'react-bootstrap/ListGroup';
import JobApplication from './JobApplication';
import Accordion from 'react-bootstrap/Accordion';
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import Badge from 'react-bootstrap/Badge';
import JobDetails from './JobDetails';
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useCallback } from 'react';
import { NavLink,useNavigate,useParams ,Link} from "react-router-dom";
import PromoteJob from './PromoteJob';
import { useLocation } from 'react-router-dom';
export default function PostedJobList(props)
{
const allJobData=props.jobList
  console.log("jobList",props.jobList)

const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);
const [counter, setCounter]=useState('')


const [getjobname,setjobname]=useState('')
const[status, setStatus]=useState(false)
  const [getid,setid]=useState('')  
  const [geplan,setplan]=useState('')
  const [isPlanActive,setPlanActive]=useState('')
  const [searchKey,setSearchKey]=useState(10)
  const[isJobPosted, setPostedJobCount]=useState(false)

  // const location = useLocation();
  // const path = location.pathname;
  // const name = path.split('/').pop();

 

  useEffect(()=>
  {

    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      setid(parseVal.user_id)
      setplan(parseVal.planselected)
      setPlanActive(parseVal.isplanactive)
    }
  },[])

  function deleteapplication(applicationid)
{
  let data = applicationid
fetch(`${window.siteUrl}/deletejobs/${data}`,
{
method:'DELETE',

  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}

}).then((result)=>
 {
//   setapistatus(result.status)
setStatus(true)
       
  
result.json().then((resp)=>
{
  
 console.log("deleteapplication",resp)
 handleClose()
 setTimeout(() => {
  
  setStatus(false)
 },4000)
 })
 })
}

  let [getvalue,setvalue]=useState()

  function setdeldata(id)
{
  console.log("delete")
handleShow ()
  setvalue(id)
 
}

const getcountCallback = useCallback((count, jobId) => {
  if (counter[jobId] !== count) {
    setCounter((prevCounter) => ({ ...prevCounter, [jobId]: count }));
  }
}, [setCounter, counter,status]);

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

    return(

      <>
       
          {/* <h2 className="headingbackground p-3 my-2" style={{ borderRadius: '4px' }}>Posted Jobs</h2> */}
          <h2 className="dashboard-heading">Posted Jobs</h2>
          {allJobData.length > 0?
          
          <form className="d-flex justify-content-start my-2 ml-auto" style={{ width: "50%" }}>
  <select 
    className="form-control" 
    value={searchKey} 
    onChange={(e) => {
      const value = e.target.value;
      setSearchKey(value === 'all' ? value : parseInt(value));
    }}
  >
    <option value="10">Last 10 Records</option>
    <option value="20">Last 20 Records</option>
    <option value="30">Last 30 Records</option>
    <option value="all">All Records</option>
  </select>
</form>
:
<span>No Job Posted</span>
          }
        {props.searchkeydata(searchKey)}

          {allJobData &&
      allJobData.length > 0 &&
      allJobData.map((item, i)=>
      item.j_status=="posted" && item.user_id==getid?
     
          <div class="card mb-3">
      
      <div class="card-body">
        <div class="d-flex flex-column flex-lg-row">
        <div class="col-sm-2">
          {/* avatar-lg img-thumbnail rounded-circle */}
                                          <div class="candidate-list-images d-flex justify-content-center">
                                              {/* <a href="#"><img src={item.user_image?item.user_image:"https://bootdey.com/img/Content/avatar/avatar3.png" }alt="" class="profile-photo-jd" /></a> */}
                                              <div className="img-circle medium-image m-2" style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '24px',
  fontWeight: 'bold',
  width: '60px',
  height: '60px',
  borderRadius: '50px',
  backgroundColor: '#f0f0f0',
  color: getRandomColor(),
  transform: 'scale(1.2)',
}}>
  {item.user_image !== null ? (
    <img 
      alt="" 
      src={item.user_image} 
      style={{ 
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        objectFit: 'fill',
        transform: 'scale(1.2)'
      }} 
    />
  ) : (
    item.user_name && item.user_name[0].toUpperCase()
  )}
</div>

                                          </div>
                                          {/* <span class="text-wrap">{item.user_name} </span>  */}
                                          <div className="text-center ml-2 font-weight-bold "style={{ fontSize: "10px",transform: 'translateY(10px)'  }}>{item.j_curr_date.split('T')[0].split('-').reverse().join('/')}</div>
                                          
                                      </div>
          <div class="row flex-fill">
          
            <div class="col-sm-5">
               <Link to ={`/${item.user_id}/${item.job_id_a}`} target="_blank" 
          state={{ itemData: item }}>
              <h5 class="h5">{item.job_title}</h5>
                </Link> 
              <ul class="list-inline mb-0 text-muted">
                                                  <li class="list-inline-item"><i class="bi bi-building"></i>{item.u_company_name} {item.j_exp_from_year}-{item.j_exp_to_year} year</li>
                                                  {/* <li class="list-inline-item"> 5-7 year</li> */}
                                              </ul>
              <span class="badge bg-secondary text-wrap"> <i class="bi bi-geo-alt"></i>{item.j_city} </span> <span class="badge bg-success">{item.j_salary_from}k - {item.j_salary_to}k</span> 
              {/* <span class="">25-10-2025</span> */}
            </div>
          
            <div class="col-sm-4 py-2 ">
            <span class="badge bg-secondary text-wrap text-break ">{item.j_skill} </span>
            <span class="badge bg-secondary text-wrap text-break">{item.j_certificate}</span>

            </div>
            <div class="col-sm-3 text-lg-end p-1">
              <div style={{
    transform: 'translateY(-25px)' }}>
            {/* <FaRegShareSquare style={{
    transform: 'translateX(-30px)' }} /> */}
    
{geplan && isPlanActive=='yes'?
            <PromoteJob jobid={item.job_id_a} isjobpromoted={item.promoted} plan={geplan} jobs={allJobData}  />
:null
}
            </div>
            <JobDetails jresponsibility={item.j_responsibility} jqualification={item.j_qualification}jskill={item.j_skill} jtitle={item.job_title} jusername={item.user_name} jcity={item.j_city} jcompanyname={item.u_company_name} jfromexp={item.j_exp_from_year} jtoexp={item.j_exp_to_year} jdesc={item.j_desc} jdate={item.j_curr_date} jcertificate={item.j_certificate} jeducation={item.j_education} jsalaryfrom={item.j_salary_from} jsalaryto={item.j_salary_to} jstatus={item.j_status} jida={item.job_id_a}jidm={item.job_id_m}/>
               <Button variant="outline-danger" className="m-1" onClick={()=>setdeldata(item.job_id_a,setjobname(item.job_title))}>Delete
               {props.updatejobcount(status)}
               </Button>
               
            </div>
            
          </div>
        </div>
      </div>
      <Accordion className='my-1'>
      <Accordion.Item eventKey="0">
        <Accordion.Header >
          {/* <span className="me-2"><i class="fa fa-list-alt"></i></span>
          View list of applications <Badge bg="secondary" style={{ marginLeft: 5 }}>{counter}</Badge>
         */}
          <span className="me-2"><i class="fa fa-list-alt"></i></span>
          <span className='text-success'>View list of applications</span>
  <Badge bg="danger" style={{ marginLeft: 5 }}>
    {counter[item.job_id_a] || 0}
  </Badge>
        </Accordion.Header>
        <Accordion.Body>
          
        <JobApplication
  getjobid={item.job_id_a}
  getcount={(count) => getcountCallback(count, item.job_id_a)}
/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
:null
)}





    {/* <div className="my-1">
          <Card style={{ width: '100%' }} className='my-1'>
  <Card.Body>
    <Row>
      <Col xs={3}sm={2}>
      <figure>
    <Card.Img variant="top" src="holder.js/10px30?text=Image cap" />
    <figcaption className="text-center">image</figcaption>
  </figure>
      </Col>
      <Col xs={9}sm={3}  className="text-start">
      <Card.Text className="fw-bold companyname  mb-1">Company Name</Card.Text>
      <Card.Text className='postedondate text-muted '><strong className='postedontext'>Posted on -</strong>25/02/2025</Card.Text>
        </Col>
        <Col xs={12}sm={6}  className="text-start requirement ">
        <Card.Text className="text-muted mb-1">Job Title dfads dfadfasdfadsfas  Pune, Mumbai, Noida, delhi </Card.Text>
        <Card.Text className="mb-1">Pune, Mumbai, Noida </Card.Text>
        <Card.Text className="mb-0">  Required Skill dfadsf adfa dfa dsfadsfadfad fg dfadsf 5-7 yr dfasdf dfads fdfads fadf a dfasdf df asd </Card.Text>
        </Col>
        <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-end " >
              <Card.Body className=" justify-content-start align-items-center">
             
              <FcViewDetails className='fs-3'/>
              <AiOutlineDelete className='fs-3 text-danger'/>
                </Col>
                <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-start ">
                <AiOutlineDelete className='fs-2 text-danger'/>
</Col>
    </Row>
    <Accordion className='my-1'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span className="me-2"><i class="fa fa-list-alt"></i></span>
          View list of applications
        </Accordion.Header>
        <Accordion.Body>
          <JobApplication />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </Card.Body>
</Card>

</div> */}

       

<Modal show={showdel} onHide={handleClose}>
<Modal.Header closeButton>
  <Modal.Title>Delete Application</Modal.Title>
</Modal.Header>
<Modal.Body>Do you want to delete this  <b>{getjobname}</b> job?
    This will delete received application as well
</Modal.Body>
<Modal.Footer>
 <Button variant="primary" onClick={()=>deleteapplication(getvalue)}>
    Delete
  </Button> 
  <Button variant="secondary" onClick={handleClose}>
    No
  </Button>
</Modal.Footer>
</Modal> 

</>

    )
}