import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState,useEffect } from 'react';
import Card from 'react-bootstrap/Card';

export default function JobDetails(props)

{
   const [show, setShow] = useState(false);
   const handleShow = () => setShow(true);
   const handleClose = () => setShow(false);

   console.log("jobdetails",props)

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} className='fs-7 m-1 '>View</Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='fs-6'> Job Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Card className="text-center">
      {/* <Card.Header>Company Name</Card.Header> */}
      <Card.Body >
        <Card.Title>{props.jtitle}</Card.Title>
        <Card.Text> <b>Company Name -</b>
          {props.jcompanyname}
        </Card.Text>
        <Card.Text> <b>Experience Required- </b>
          {props.jfromexp}-{props.jtoexp} Year
        </Card.Text>
        <Card.Text> <b>Location -</b>
          {props.jcity}
        </Card.Text>
        <Card.Text> <b>Skill Required -</b>
          {props.jskill}
        </Card.Text>
        <Card.Text> <b>Certificates -</b>
        {props.jcertificate}
        </Card.Text > 
        <b>Job Description - </b>
        <div style={{ height: '200px', overflowY: 'auto' }}>
        <div style={{ fontSize: "15px", color: '#697077', fontWeight: 'bold' }}>Roll -</div>
        <Card.Text style={{ whiteSpace: 'pre-wrap' }} >
        {props.jdesc}
        
        </Card.Text>
        <div style={{ fontSize: "15px", color: '#697077', fontWeight: 'bold' }}>Responsibility -</div>
       
        <Card.Text style={{ whiteSpace: 'pre-wrap' }}>
        {props.jresponsibility}
        
        </Card.Text>
        <div style={{ fontSize: "15px", color: '#697077', fontWeight: 'bold' }}>Qualification -</div>
      
        <Card.Text style={{ whiteSpace: 'pre-wrap' }}>
        {props.jqualification}
        
        </Card.Text>
        </div>
        
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      <Card.Footer className="text-muted">Posted by- {props.jusername} -{props.jdate}</Card.Footer>
    </Card>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}