import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';

export default function SearchCityPage(props)
{

    const [getcategory,setcategory]=useState('Select Type of Shop') 
  const [getothercategory,setothercategory]=useState('')

  const [category, setCategory] = useState('');
  const options = [
    { value: 'Pune', label: 'Pune' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Banglur', label: 'Banglur' },
    { value: 'chennai', label: 'chennai' },
    { value: 'Napur', label: 'Napur' },
    { value: 'Noida', label: 'Noida' },
    { value: 'Hydrabad', label: 'Hydrabad' },
    { value: 'Delhi', label: 'Delhi' },
  ];
  
return(
<>
<Container fluid >
<Form.Group >
  
<input className="form-control mb-2 w-100" type="search"
        list="shop-types"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        placeholder="City"
      />
      <datalist id="shop-types">
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </datalist>
      
    </Form.Group>
    </Container>
    {props.selcity(category)}
  </>
 
)


}