import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
export default function ToolTip({ item, children }) {
    return (
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={<Tooltip id="tooltip-bottom">{item}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    );
  }

  