import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useState,useEffect,useRef } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import TagsInput from 'react-tagsinput';
import CertificateList from "./Elements/CertificateList"
import SkillList from "./Elements/SkillList";
import EducationList from "./Elements/EducationList";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {  Card } from 'react-bootstrap';
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import PostedSavedJobList from "./PostedSavedJobList";
import MultiSelectList from "./MultiSelectList";
import InputGroup from 'react-bootstrap/InputGroup';
import CityList from "./Elements/CityList";
import Badge from 'react-bootstrap/Badge';
import IndustryList from "./Elements/IndustryList";


export default function PostYourJob(props)
{
  
    const [getjobdes, setjobdes]=useState('')
    const [getResponsibility, setResponsibility]=useState('')
    const [getQualification, setQualification]=useState('')
    const [city, setCity]=useState([])
    const [education, setEducation]=useState([])
    const[skill, setskill]=useState([])
    const[certificate, setcertificate]=useState([])
    const [jobTitle, setJobTitle]=useState('')
    const [jobId, setJobId]=useState('')
    const [salaryFrom, setSalaryFrom]=useState('')
    const [salaryTo, setSalaryTo]=useState('')
    const [expFromYear, setExpFromYear]=useState('')
    const [expToYear, setExpToYear]=useState('')
    const [user_Id_Auto,setUser_Id_Auto]=useState('')
    const [status,setStatus]= useState('')
    const [getCompany,setCompany]= useState('')
    const [getProfileImg,setProfileImg]= useState('')
    const [getUserName,setUserName]= useState('')
    const [getDraftJobCount,setDraftJobCount]=useState('')
    const [jobIdTouched, setJobIdTouched] = useState(false);
    const [jobTitleTouched, setJobTitleTouched] = useState(false);
    const [getCityList,setCityList]= useState([])
    const [getSkillList,setSkillList]= useState([])
    const [getEducationList,setEducationList]= useState([])
    const [getCertificateList,setCertificateList]= useState([])
    const [showmessage, setshowmessage] = useState(false);
    const [getenable, setdisable]=useState(true)
    const [getUserEmailId, setUserEmailId]=useState('')
    const[industry, setIndustry]=useState('')
    const[getNoPosition, setNoPosition]=useState(null)
    
    useEffect(()=>
    {
      setjobdes('')
      setResponsibility('')
      setQualification('')
      setJobTitle('')
      setJobId('')
      setSalaryFrom('')
      setSalaryTo('')
      setExpFromYear('')
      setExpToYear('')
      setNoPosition(null)
      

      setTimeout(() => {
        setStatus(false)
      }, 4000)
      
    },[status])
    
    useEffect(()=>
    {
      if(education.length>0 && jobId.length>0 && jobTitle.length>0 && city.length>0&&skill.length>0)
      {
        setdisable(false)
        
      }else{
        setdisable(true)
      }
    })

    useEffect(()=>
    {
      if(localStorage.getItem('user-info')){
        let userdata=localStorage.getItem('user-info')
        let parseVal=JSON.parse(userdata)
        setUser_Id_Auto(parseVal.user_id)
        setCompany(parseVal.u_company_name)
        setProfileImg(parseVal.u_image)
        setUserName(parseVal.u_name)
        setUserEmailId(parseVal.u_email_id)
      }
    },[])

    useEffect(()=>{
      if(localStorage.getItem('user-info')){
             
        
        fetch(`${window.siteUrl}/getlist`,
      {
        headers: {
        authorization:'bearar'+' '+localStorage.getItem('auth')
      }
    }).then((result)=>
     {
       result.json().then((resp)=>{
        setCityList(resp.cityList) 
        setSkillList((resp.skillList))
        setEducationList((resp.educationList))
        setCertificateList((resp.certificateList))
            console.log("citylist123",resp)
            setStatus(false)
       }) 
     }
     )
    }
    },[])

    async function postyourjob()
    {
      const data={getjobdes,getResponsibility,getQualification,city,education,skill,certificate,jobTitle,jobId,salaryFrom,salaryTo,expFromYear,expToYear,user_Id_Auto,getCompany,getProfileImg,getUserName,getUserEmailId,industry,getNoPosition}
      console.log("data",data)
try{
    let pResult= fetch(window.siteUrl+"/postjob",
    {
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'authorization': 'bearar'+' '+localStorage.getItem('auth')
     },
     body: JSON.stringify(data)
   }).then((pResult) => {
     console.log("insertjob1", pResult)
     pResult.json().then((resp) => {
       console.log("insertjob", resp)  
       if(resp.result.affectedRows>=1)
       {
         setStatus(true)
         setshowmessage(true)
         setjobdes('')
      setJobTitle('')
      setJobId('')
      setSalaryFrom('')
      setSalaryTo('')
      setExpFromYear('')
      setExpToYear('')
      setNoPosition(null)
      
       }
       setTimeout(() => {
        setStatus(false)
        setshowmessage(false)
      }, 1000)
}
     )
   }
   )
}catch
{

}
    }
    
     

    return(
<>
{/* <h2 className="headingbackground p-3 my-2" style={{ borderRadius: '4px' }}>Post Your Job</h2> */}
<h2 className="dashboard-heading">Post Your Job</h2>

<div className="card mb-4">
  {getCompany?
  <div className="card-body">
  <Tabs
      defaultActiveKey="home"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Create job">
      {
            showmessage?
            <span className="text-success">
Record updated successfully
            </span>:
            null
          }
      <div className="resumeheader">Create Your Jobs</div>
      
  <Form >
  
    <Row className="align-items-center">
    <Col xs={12} md={3}> 
  <Form.Group controlId="jobId">
    <Form.Label>Job ID <span style={{ color: 'red' }}>*</span></Form.Label>
    <Form.Control 
      type="search" 
      placeholder="Job ID" 
      aria-label="Search" 
      required
    isValid={jobId !== "" && !jobIdTouched}
    isInvalid={jobId === "" && jobIdTouched}
    className={jobIdTouched && jobId === "" ? "is-invalid" : ""}
    onBlur={() => setJobIdTouched(true)}
      onChange={(e)=>setJobId(e.target.value)}
      value={jobId}
      
    />
    {jobIdTouched && jobId === "" && (
    <Form.Control.Feedback type="invalid">
      Please enter a Job ID.
    </Form.Control.Feedback>
    )}
  </Form.Group>
</Col>
      <Col xs={12} md={3}>
        <Form.Group controlId="jobTitle">
          <Form.Label>Job Title <span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control type="search" placeholder="Job Title" aria-label="Search"
          required
          isValid={jobTitle !== "" && !jobTitleTouched}
          isInvalid={jobTitle === "" && jobTitleTouched}
          className={jobTitleTouched && jobTitle === "" ? "is-invalid" : ""}
          onBlur={() => setJobTitleTouched(true)}
          value={jobTitle}
           onChange={(e)=>setJobTitle(e.target.value)}/>

{jobTitleTouched && jobTitle === "" && (
    <Form.Control.Feedback type="invalid">
      Please enter a Job Title.
    </Form.Control.Feedback>
)}
        </Form.Group>
      </Col>
      <Col xs={12} md={3}>
        <Form.Group controlId="city">
          <Form.Label>City <span style={{ color: 'red' }}>*</span></Form.Label>
          <CityList clearstatus={status} selcity={setCity} getdefaultcity={city} getdblist={getCityList}/>
          
        </Form.Group>
      </Col>
      <Col xs={12} md={3}>
        <Form.Group controlId="skill">
          <Form.Label>Skill <span style={{ color: 'red' }}>*</span></Form.Label>
          <SkillList clearstatus={status} selskill={setskill} getdefaultskill={skill} getdblist={getSkillList}/>
        </Form.Group>
      </Col>
    </Row>
    <Row className="align-items-center">
      <Col xs={12} md={3}>
        <Form.Group controlId="education">
          <Form.Label>Education <span style={{ color: 'red' }}>*</span></Form.Label>
          <EducationList clearstatus={status}  seleducation={setEducation} getdefaulteducation={education} getdblist={getEducationList}/>
        </Form.Group>
      </Col>

      
      <Col xs={12} md={3}> 
      <Form.Label>Experience <span style={{ color: 'red' }}>*</span></Form.Label>
      <InputGroup>
      <InputGroup.Text id="basic-addon2">Year</InputGroup.Text>
      <Form.Select value={expFromYear}aria-label="Default select example" onChange={(e)=>setExpFromYear(e.target.value)}>
      <option>From</option>
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
      
    </Form.Select>
    <Form.Select value={expToYear}aria-label="Default select example" onChange={(e)=>setExpToYear(e.target.value)}>
      <option>To</option>
      <option value={+expFromYear+1}>{+expFromYear+1}</option>
      <option value={+expFromYear+2}>{+expFromYear+2}</option>
      <option value={+expFromYear+3}>{+expFromYear+3}</option>
      <option value={+expFromYear+4}>{+expFromYear+4}</option>
      <option value={+expFromYear+5}>{+expFromYear+5}</option>
      <option value={+expFromYear+6}>{+expFromYear+6}</option>
      <option value={+expFromYear+7}>{+expFromYear+7}</option>
      <option value={+expFromYear+8}>{+expFromYear+8}</option>
      <option value={+expFromYear+9}>{+expFromYear+9}</option>
      <option value={+expFromYear+10}>{+expFromYear+10}</option>
      <option value={+expFromYear+11}>{+expFromYear+11}</option>
      <option value={+expFromYear+12}>{+expFromYear+12}</option>
      <option value={+expFromYear+13}>{+expFromYear+13}</option>
      <option value={+expFromYear+14}>{+expFromYear+14}</option>
      <option value={+expFromYear+15}>{+expFromYear+15}</option>
      <option value={+expFromYear+16}>{+expFromYear+16}</option>
      <option value={+expFromYear+17}>{+expFromYear+17}</option>
      <option value={+expFromYear+18}>{+expFromYear+18}</option>
      <option value={+expFromYear+19}>{+expFromYear+19}</option>
      <option value={+expFromYear+20}>{+expFromYear+20}</option>
    </Form.Select>
      </InputGroup>
      
  {/* <Form.Group controlId="experience">
    <Form.Label>Experience</Form.Label>
    <Row>
      <Col xs={6}> 
        <Form.Control 
          type="number" 
          placeholder="From" 
          aria-label="From" 
          min="0" 
        />
      </Col>
      <Col xs={6}> 
        <Form.Control 
          type="number" 
          placeholder="To" 
          aria-label="To" 
          min="0" 
        /> */}
      {/* </Col>
    </Row>
  </Form.Group> */}
</Col>



      <Col xs={12} md={3}>
        <Form.Group controlId="certificate">
          <Form.Label>Certificate</Form.Label>
          <CertificateList clearstatus={status}selcertificate={setcertificate} getdefaultcertificate={certificate} getdblist={getCertificateList}/>
        </Form.Group>
      </Col>

      <Col xs={12} md={3}>
      <Form.Label>Salary Monthly</Form.Label>
      <InputGroup>
      <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
      <Form.Select  value={salaryFrom}type="number" aria-label="Default select example" onChange={(e)=>setSalaryFrom(e.target.value)}>
      <option>From</option>
      <option value="10k">10k</option>
      <option value="20">20k</option>
      <option value="30">30k</option>
      <option value="40">40k</option>
      <option value="50">50k</option>
      <option value="60">60k</option>
      <option value="70">70k</option>
      <option value="80">80k</option>
      <option value="90">90k</option>
      <option value="100">100k</option>
      <option value="110">110k</option>
      <option value="120">120k</option>
      <option value="130">130k</option>
      <option value="140">140k</option>
      <option value="150">150k</option>
      <option value="160">160k</option>
      <option value="170">170k</option>
      <option value="180">180k</option>
      <option value="190">190k</option>
      <option value="200">200k</option>
      <option value="210">210k</option>
      <option value="220">220k</option>
      <option value="230">230k</option>
      <option value="240">240k</option>
      <option value="250">250k</option>
    </Form.Select>
    <Form.Select value={salaryTo}type="number" aria-label="Default select example" onChange={(e)=>setSalaryTo(e.target.value)}>
      <option>To</option>
      {console.log("salaryFrom",+salaryFrom+10)}
      <option value={+salaryFrom+10}>{+salaryFrom+10}k</option>
      <option value={+salaryFrom+20}>{+salaryFrom+20}k</option>
      <option value={+salaryFrom+30}>{+salaryFrom+30}k</option>
      <option value={+salaryFrom+40}>{+salaryFrom+40}k</option>
      <option value={+salaryFrom+50}>{+salaryFrom+50}k</option>
      <option value={+salaryFrom+60}>{+salaryFrom+60}k</option>
      <option value={+salaryFrom+70}>{+salaryFrom+70}k</option>
      <option value={+salaryFrom+80}>{+salaryFrom+80}k</option>
      <option value={+salaryFrom+90}>{+salaryFrom+90}k</option>
      
    </Form.Select>
      </InputGroup>
</Col>

    </Row>
    <Row>
    <Col xs={12} md={3}>
    <Form.Group controlId="certificate">
          <Form.Label>Industry Type</Form.Label>
          <IndustryList clearstatus={status} selindustry={setIndustry} getdefaultindustry={industry} />
        </Form.Group>
    
      </Col>
      <Col xs={12} md={3}>
      <Form.Group controlId="position">
      <Form.Label>Number of Positions</Form.Label>
<Form.Control 
  type="number" 
  placeholder="Positions (Optional)" 
  aria-label="Number of Positions" 
  onChange={(e)=>{
    if(e.target.value > 9999){
      setNoPosition(9999);
    } else {
      setNoPosition(e.target.value);
    }
  }} 
  value={getNoPosition} 
/>
        </Form.Group>
    
      </Col>
      
    </Row>
    <Row className="my-2">
      <Col xs={12}>
        <FloatingLabel controlId="floatingTextarea2" className="my-1">  
          <Form.Control
            id="addressid"
            controlId="addressidc"
            as="textarea"
            className="border border-dark"
            placeholder="Leave a comment here"
            value={getjobdes}
            onChange={(e) => setjobdes(e.target.value)}
            style={{ height: "100px" }}
          />
          {getjobdes == "" ? (
            <label htmlFor="floatingInput" className="text-danger p-2 ml-2">
              Roll/ Job description
            </label>
          ) : (
            <label htmlFor="floatingInputCustom" className="p-2 ml-2">
              Roll / Job description
            </label>
          )}
        </FloatingLabel>
      </Col>

      <Col xs={12}>
        <FloatingLabel controlId="floatingTextarea2" className="my-1">
          <Form.Control
            id="jobresp"
            controlId="jobresp"
            as="textarea"
            className="border border-dark"
            placeholder="Leave a comment here"
            value={getResponsibility}
            onChange={(e) => setResponsibility(e.target.value)}
            style={{ height: "100px" }}
          />
          {getResponsibility == "" ? (
            <label htmlFor="floatingInput" className="text-danger p-2 ml-2">
              Responsibility
            </label>
          ) : (
            <label htmlFor="floatingInputCustom" className="p-2 ml-2">
              Responsibility
            </label>
          )}
        </FloatingLabel>
      </Col>

      <Col xs={12}>
        <FloatingLabel controlId="floatingTextarea2" className="my-1">
          <Form.Control
            id="jobquli"
            controlId="jobquli"
            as="textarea"
            className="border border-dark"
            placeholder="Leave a comment here"
            value={getQualification}
            onChange={(e) => setQualification(e.target.value)}
            style={{ height: "100px" }}
          />
          {getQualification == "" ? (
            <label htmlFor="floatingInput" className="text-danger p-2 ml-2">
              Qualification
            </label>
          ) : (
            <label htmlFor="floatingInputCustom" className="p-2 ml-2">
              Qualification
            </label>
          )}
        </FloatingLabel>
      </Col>
    </Row>
    <Button variant="success" className="me-3 my-2" disabled={getenable}onClick={postyourjob}>
      Post Your Job
    </Button>
    <Button variant="primary"onClick={()=>setStatus(true)}>Clear</Button>
    
  
  </Form>
  
  </Tab>
      <Tab eventKey="profile" title={
  <span>
    Submited jobs  
    <Badge bg="secondary"> {getDraftJobCount}</Badge>
  </span>
}>
      
     <PostedSavedJobList draftJobCount={setDraftJobCount} sendstatus={status}/>
      </Tab>
      </Tabs>
  </div>
  :
  <span className="text-danger fs-5"> Update your profile details to access this page</span>
}
  </div>
  </>

    )
}