import { Row, Col, Container, Card } from 'react-bootstrap';
export default function()
{

    return(
        <>
{/* <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}> */}
  {/* Your content here */}
        <footer style={{ marginTop: 'auto', textAlign: 'center', borderTop: '1px solid #ddd' }}>
  <Container fluid style={{ backgroundColor: '#f0f0f0', padding: '2px 0' }}>
  <Card style={{ padding: '40px', maxWidth: '100%', margin: '0 auto', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
      <Row>
        <Col md={4}>
          <h4 style={{ color: '#333' }}>Job Categories</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li><a href="/jobs/fresher" style={{ color: '#333', textDecoration: 'none' }}>Jobs for Fresher</a></li>
            <li><a href="/jobs/bpo" style={{ color: '#333', textDecoration: 'none' }}>Jobs In BPO</a></li>
            <li><a href="/jobs/call-center" style={{ color: '#333', textDecoration: 'none' }}>Jobs In Call Center</a></li>
            <li><a href="/jobs/software-testing" style={{ color: '#333', textDecoration: 'none' }}>Jobs Software Testing</a></li>
          </ul>
        </Col>
        <Col md={4}>
          <h4 style={{ color: '#333' }}>Job Locations</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li><a href="/jobs/bangalore" style={{ color: '#333', textDecoration: 'none' }}>Jobs Bangalore</a></li>
            <li><a href="/jobs/pune" style={{ color: '#333', textDecoration: 'none' }}>Jobs Pune</a></li>
            <li><a href="/jobs/mumbai" style={{ color: '#333', textDecoration: 'none' }}>Jobs Mumbai</a></li>
            <li><a href="/jobs/chennai" style={{ color: '#333', textDecoration: 'none' }}>Jobs Chennai</a></li>
            <li><a href="/jobs/noida" style={{ color: '#333', textDecoration: 'none' }}>Jobs Noida</a></li>
          </ul>
        </Col>
        <Col md={4}>
          <h4 style={{ color: '#333' }}>Details</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li><a href="/about" style={{ color: '#333', textDecoration: 'none' }}>About Us</a></li>
            <li><a href="/contact" style={{ color: '#333', textDecoration: 'none' }}>Contact Us</a></li>
            <li><a href="/privacy" style={{ color: '#333', textDecoration: 'none' }}>Privacy Policy</a></li>
            <li><a href="/dmca" style={{ color: '#333', textDecoration: 'none' }}>DMCA</a></li>
          </ul>
        </Col>
      </Row>
    </Card>
  </Container>
</footer>
</>
    )
}