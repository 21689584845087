import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from 'react-bootstrap';

export default function ResumeAddUserUnAuth(props)
{
    const[getuserEmail, setEmailId]=useState('')
    const [getresumeenable, setresumedisable]=useState(true)
    const [getduplicaterecerror, setduplicaterecerror]=useState(false);

  const [isotprequired, setrequiredotp]=useState(true)
  const [getisloginissue, setisloginissue]=useState(false);
  const [getresponse,setresponse]=useState([])
  const [enableResumeDetails,setEnableResumeDetails]=useState(false)
  const [userdetails,setuserdetails]=useState([])



    const [getuserName,setUserName]= useState('')

    let data={getuserName,getuserEmail}

    // useEffect(()=>
    // {
    //     setEnableResumeDetails(props.disablepage)  
    //     console.log("pagedisable",props.disablepage) 
    // })
    useEffect(()=>
      {
        
        if( getuserEmail.length>0 && getuserName.length>0)
        {
          setresumedisable(false)
          
        }else{
          setresumedisable(true)
        }
      })

      function saveUserDetails()
    {
        try{
           //let pResult= fetch("//allstoresprice.com:3443/userdetails",
            
           let pResult= fetch(window.siteUrl+"/adduserapplyjob",
            
             {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            }).then((pResult) => {
            //  console.log("user list", pResult)
              setresponse(pResult.status)
              pResult.json().then((resp) => {
             //    console.log("Post lefpro", resp)
             console.log("resp.code",resp)
                // if(resp.affectedRows>0||resp.code=="ER_DUP_ENTRY"|| resp.affectedRows==0)
                if(resp.result1.length==1)

            {         
         
                setEnableResumeDetails(true)
                setuserdetails(resp.result1[0])
                console.log("alldatau",resp.result1[0])
            
            } 
              })
              
            })
          }catch(error)
          {
            console.log("update error",error)
          }
          
        }


    return(

<form>
        <div className="row">
                 <div className="col-md-6 mb-2">
                             <label class="small mb-1" for="inputEmailId"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Email_ID</label>
                                 {/* <label class="small mb-1" for="inputFirstName">First name</label> */}
                                 <input class="form-control" id="email" type="text"  placeholder="Email" onChange={(e)=>setEmailId(e.target.value)} />
                             </div>
                             <div className="col-lg-6 mb-2">
   <label class="small mb-1" for="inputusername"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>User Name</label>
     <input className="form-control" id="inputusername"  type="text" placeholder="Enter User Name" onChange={(e)=>setUserName(e.target.value)}  />
   </div>
   {enableResumeDetails? enableResumeDetails: <Button variant="success" disabled={getresumeenable}className="me-3 my-2" onClick={saveUserDetails}>
       Submit
     </Button>
}
     {props.resumePage(enableResumeDetails)}
     {props.userpagedetails(userdetails)}
</div>

</form>

    )
}