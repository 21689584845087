import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState,useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useLocation,useParams,useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import SelectResumeApply from './SelectResumeApply';
import HeadMenu from "./HeadMenu";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import JobSameUser from './JobSameUser';
import PromotedJobDisplay from './PromotedJobDisplay';
import JobAffiliate from './JobAffiliate';

export default function JobDetailsSinglePage()

{
   const [show, setShow] = useState(false);
   const [getBackUrl, setBackUrl] = useState(false);
   

   const [allJobData,setAllJobData]=useState([])
   const [sameUserJobData,setSameUserJobData]=useState([])
   const handleShow = () => setShow(true);
   const handleClose = () => setShow(false);
   const location = useLocation();
   const [itemData, setItemData] = useState(location.state?.itemData);
     const params = useParams();
     const {jobId } = params;
     const {name } = params;
     console.log("jobid123",itemData)
     

     const navigate = useNavigate();

     useEffect(() => {
      console.log("urldetails",getBackUrl)
       if (getBackUrl) {
         navigate(-1);
         setBackUrl(false)
       }
     }, [getBackUrl]);


   useEffect(()=>{
    const data={name,jobId}
    const datas=JSON.stringify(data)
      fetch(`${window.siteUrl}/getalljobdetails/${datas}`,
    {
      headers: {
      // authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
    console.log("jobDetails1",result)
     result.json().then((resp)=>{
      setAllJobData(resp.result1) 
      console.log("jobDetails2",resp)
      setSameUserJobData(resp.result2)  
      if(resp.result1.length<1)
      {
        setBackUrl(true)
      }
      console.log("jobDetails",resp)
     }) 
   }
   )
  
  },[jobId])

  return (
    <>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"/>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" />
    <Container fluid="md" className="my-2" >
    <HeadMenu/>
    <JobAffiliate/>
    <hr></hr>
   {/* <PromotedJobDisplay/> */}
      {allJobData &&
      allJobData.length > 0 &&
      allJobData.map((item, i)=>
      
      <Row className="g-0 p-1">
    <Col md={9}>
      <Card className="text-start m-0 border-0">
        <Card.Header className="bg-transparent border-0">
        <div className='text-end' style={{
    transform: 'translateY(5px)' }}>
        <SelectResumeApply 
          jskill={item.j_skill}
          jtitle={item.job_title}
          jusername={item.user_name}
          juserida={item.user_id}
          juseridm={item.user_id_self}
          juserimageprofile={item.user_image}
          jcity={item.j_city}
          jcompanyname={item.u_company_name}
          jfromexp={item.j_exp_from_year}
          jtoexp={item.j_exp_to_year}
          jdesc={item.j_desc}
          jdate={item.j_curr_date}
          jcertificate={item.j_certificate}
          jeducation={item.j_education}
          jsalaryfrom={item.j_salary_from}
          jsalaryto={item.j_salary_to}
          jstatus={item.j_status}
          jida={item.job_id_a}
          jidm={item.job_id_m}
        />
        </div>
        </Card.Header>
        
        <Card.Body style={{
    transform: 'translateY(-20px)' }}>
          <div style={{ fontSize: "30px",color:'#697077',fontWeight: 'bold'  }}>{item.job_title}</div>
          <div style={{ fontSize: "20px", color: '#697077', fontWeight: 'bold' }}><i className="bi bi-building" style={{ fontSize: "12px" }}></i> {item.u_company_name}</div>
          {item.j_skill.split(',').map((skill, index) => (
  <span key={index} className={`badge bg-secondary text-wrap text-break d-inline-block m-1`}>
    {skill.trim()}
  </span>
))}

{item.j_certificate.split(',').map((certificate, index) => (
  <span key={index} className={`badge bg-secondary opacity-75 text-wrap text-break d-inline-block m-1`}>
    {certificate.trim()}
  </span>
))}

          
          <div className="m-1"style={{ fontSize: "14px",color:'#697077',fontWeight: 'bold'  }}><i className="fa fa-briefcase"style={{ fontSize: "12px"}}></i> {item.j_exp_from_year}- {item.j_exp_to_year} Years</div>
          
          <i className="bi bi-geo-alt-fill"style={{ fontSize: "20px" }}></i>  {item.j_city.split(',').map((city, index) => (
  <span key={index} className="badge bg-success text-wrap text-break d-inline-block"style={{ marginRight: '2px' }}>
    {city.trim()}
  </span>
))}
        
          
<hr></hr>


   <div className="d-flex justify-content-between my-2">      
   <div><span style={{fontSize:"12px",fontStyle: "italic"}} className='fw-bold'>Job Id- </span>{item.job_id_m} </div>  <div className='float-end '><span className='fw-bold' style={{fontSize:"12px",fontStyle: "italic"}}>Posted On-</span>{item.j_curr_date.split('T')[0].split('-').reverse().join('/')}</div>     
   </div> 
   {item.j_industry?
   <div className="d-flex justify-content-between my-2">      
   <div><span style={{fontSize:"12px",fontStyle: "italic"}} className='fw-bold'>Industry- </span>{item.j_industry} </div>     
   </div> 
   :
   null}
   {item.j_possition_num?
   <div className="d-flex justify-content-between my-2">      
   <div><span style={{fontSize:"12px",fontStyle: "italic"}} className='fw-bold'>No. Position- </span>{item.j_possition_num} </div>  
   </div> 
   :
   null
   }
<div style={{ fontSize: "15px", color: '#697077', fontWeight: 'bold' }}>Job Description :- </div>
          <Card.Text style={{ whiteSpace: 'pre-wrap',fontSize: "14px" }}>
            {item.j_desc}
          </Card.Text>
          
  <div style={{ fontSize: "15px", color: '#697077', fontWeight: 'bold' }}>Responsibility :- </div>
          <Card.Text style={{ whiteSpace: 'pre-wrap',fontSize: "14px" }}>
            {item.j_responsibility}
          </Card.Text>

          <div style={{ fontSize: "15px", color: '#697077', fontWeight: 'bold' }}>Qualification :- </div>
          <Card.Text style={{ whiteSpace: 'pre-wrap',fontSize: "14px" }}>
            {item.j_qualification}
          </Card.Text>
        </Card.Body>
        {/* <Card.Footer className="text-muted border-0">
          Posted by- {item.user_name} -{item.j_curr_date}
        </Card.Footer> */}
        <div>
        <SelectResumeApply 
          jskill={item.j_skill}
          jtitle={item.job_title}
          jusername={item.user_name}
          juserida={item.user_id}
          juseridm={item.user_id_self}
          juserimageprofile={item.user_image}
          jcity={item.j_city}
          jcompanyname={item.u_company_name}
          jfromexp={item.j_exp_from_year}
          jtoexp={item.j_exp_to_year}
          jdesc={item.j_desc}
          jdate={item.j_curr_date}
          jcertificate={item.j_certificate}
          jeducation={item.j_education}
          jsalaryfrom={item.j_salary_from}
          jsalaryto={item.j_salary_to}
          jstatus={item.j_status}
          jida={item.job_id_a}
          jidm={item.job_id_m}
        />
        </div>
      </Card>
      <hr></hr>
      <JobAffiliate/>
      {/* <PromotedJobDisplay/> */}
    </Col>
    <Col md={3}>
           
        
          <JobSameUser userjoblist={sameUserJobData}/>
        
      
    </Col>
    
  </Row>
      )
}

</Container>
    </>
  );
}