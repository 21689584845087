import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function EducationList(props)
{

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
// let value=props.getdefaulteducation.length>1

useEffect(() => {
  if (props.clearstatus) {
    setSelectedOptions([]);
  }
}, [props.clearstatus]);

const options=props.getdblist

useEffect(() => {
  if (props.getdefaulteducation !== null && props.getdefaulteducation !== undefined) {
    if (typeof props.getdefaulteducation === "string") {
      const educationArray = props.getdefaulteducation.split(",");
      setSelectedOptions(educationArray);
    } else {
      // Handle non-string cases
    }
  } else {
    setSelectedOptions([]); // Reset to empty array
  }
}, [props.getdefaulteducation]);

    // const options = [
    //   { value: 'Grocery', label: 'Grocery' },
    //   { value: 'Food', label: 'Food' },
    //   { value: 'Stationary', label: 'Stationary' },
    //   { value: 'Profession', label: 'Profession' },
    //   { value: 'HomeMade Product', label: 'HomeMade Product' },
    //   { value: 'Travelling', label: 'Travelling' },
    //   { value: 'Interior Design', label: 'Interior Design' },
    //   { value: 'Other', label: 'Other' },
    // ];
    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const handleBlur = () => {
        if (searchTerm && !selectedOptions.includes(searchTerm)) {
          setSelectedOptions([...selectedOptions, searchTerm]);
          setSearchTerm('');
        }
      };
    
      const handleRemoveOption = (index) => {
        const updatedOptions = selectedOptions.filter((option, i) => i !== index);
        setSelectedOptions(updatedOptions);
      };

      useEffect(() => {
        if (props.getdblist) {
          const filteredOptions = props.getdblist.filter(option => !selectedOptions.includes(option.value));
          setFilteredOptions(filteredOptions);
        }
      }, [props.getdblist, selectedOptions]);
      
    return(
<>
        <Form.Group>
      <input
        className="form-control mb-2 w-100"
        type="text"
        list="education"
        value={searchTerm}
        onChange={handleOnChange}
        onBlur={handleBlur}
        placeholder="Enter Education"
        autoComplete="off"
        onFocus={(e) => e.target.select()}
      />
      <datalist id="education">
        {filteredOptions.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </datalist>
      <div className="selected-options">
        {console.log("education3",selectedOptions)}
        {selectedOptions.map((option, index) => (
          <span key={index}>
            {option || selectedOptions}
            <button
              type="button"
              onClick={() => handleRemoveOption(index)}
              className="remove-option"
            >
              &#x2716;
            </button>
          </span>
        ))}
      </div>
    </Form.Group>
    {props.seleducation(selectedOptions)}
    </>
    )
}