import Form from 'react-bootstrap/Form';
import { useState,useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { NavLink, useNavigate, useParams } from "react-router-dom";
export default function Job_ExitAccount()
{

    const [getshowdetails, setshowdetails] = useState();
    const [getUserId, setUserID]=useState('')
    const [status,setStatus]=useState(false)
    const navigate = useNavigate();

    const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => 
{
    if(localStorage.getItem('user-info')){
        let userdata=localStorage.getItem('user-info')
        let parseVal=JSON.parse(userdata)
        setUserID(parseVal.user_id)
    }
    setShowdel(true);
}

function logout() {
    localStorage.clear();
    navigate("/");
    document.location.reload();
  }

function deleteapplication()
{
  let data = getUserId
fetch(`${window.siteUrl}/existaccount/${data}`,
{
method:'DELETE',

  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}

}).then((result)=>
 {
//   setapistatus(result.status)
console.log("deleteaccount",result)
setStatus(true)
       
  
result.json().then((resp)=>
{
  
 console.log("deleteaccount1",resp)

 handleClose()
 logout()
 setTimeout(() => {
  
  setStatus(false)
 },1000)
 })
 })
}

    return(
        <>
        <div className="card-header">Close Your Account</div>
        <Form className='ml-4 p-2'>
  <Form.Check
    type="checkbox"
    id="custom-checkbox"
    label={
      <div style={{ textAlign: 'left' }}>
        Do you want to close account
      </div>
    }
    checked={getshowdetails}
    onChange={(e) => setshowdetails(e.target.checked)}
  />
  <Button variant="danger" disabled={!getshowdetails} onClick={handleShow}> Exit Account</Button>

</Form>

<Modal show={showdel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Close Your Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>Closing account will delete all data from database?
            
        </Modal.Body>
        <Modal.Footer>
         <Button variant="danger" onClick={deleteapplication}>
            Delete
          </Button> 
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}