import { Row, Col, Card, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import CloseButton from 'react-bootstrap/CloseButton';
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import { MdCancelPresentation } from "react-icons/md";
import { useEffect,useState } from 'react';
import axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import ResumeDetails from './ResumeDetails';


export default function JobApplication(props)
{
  const [allJobData,setAllJobData]=useState([])
  const [getDraftJobCount,setDraftJobCount]=useState('')
  const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);
const [getapplicantname,setapplicantname]=useState('')
const [getstatus, setstatus]=useState()


  useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id
      
     
      fetch(`${window.siteUrl}/getjobapplied/${props.getjobid}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
     result.json().then((resp)=>{
      setAllJobData(resp) 
          console.log("jobdata",resp)
          const draftCount = resp.filter((job) => job.j_status === "draft").length;
          setDraftJobCount(draftCount)
          setstatus(false)
     }) 
   }
   )
  }
  },[getstatus])


  let [getvalue,setvalue]=useState()
function setdeldata(id)
{
handleShow ()
  setvalue(id)
 
}

function removeapplicationreq(applicationid)
  {
        
    // const  formData= new FormData();
    // formData.append('getMobile',getMobile)
    console.log("applicationid",applicationid)
    let data={applicationid}
    //console.log("profiledata",data)
//console.log("reactui",formData)

axios.post(window.siteUrl+'/removeapplicationreq',data,
// axios.post('//allstoresprice.com:3443/updateshopstatusondeleteofaccount',data,
 {
   headers: {
   authorization:'bearar'+' '+localStorage.getItem('auth')
 }
})
.then(res=>
  {
    setstatus(res.status)
  }, 
res1=>console.log("shop",res1))
.catch(err=>console.log(err))
handleClose()
//  setTimeout(() => {
  
//    setshow(false)
  
//  }, 4000)
}

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
  
    return(

        <>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.css" integrity="sha256-NAxhqDvtY0l4xn+YVa6WjAcmd94NNfttjNsDmNatFVc=" crossorigin="anonymous" />
        {allJobData.length<1?"No Applications Received":null}
        {allJobData &&
      allJobData.length > 0 &&
      allJobData.map((item, i)=>
        <div className="candidate-list-box card mb-2" >
          
                              <div class="p-2 card-body" style={{
          backgroundColor: '#adb5bd30'}}>
                                  <div class="align-items-center row" >
                                      <div class="col-lg-2">
                                          <div class="candidate-list-images">
                                              {/* <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" class="avatar-md img-thumbnail rounded-circle" /></a> */}
                                              {/* <a href="#"><img src={item.u_user_image?item.u_user_image:"https://bootdey.com/img/Content/avatar/avatar3.png" }alt="" class="profile-photo-jd" /></a> */}

                                              <div className="img-circle medium-image m-2" style={{
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  fontSize: '24px', 
  fontWeight: 'bold', 
  width: '70px', 
  height: '70px', 
  borderRadius: '50%', 
  backgroundColor: '#f0f0f0', 
  color: getRandomColor() // Add this line
}}>
  {item.u_user_image !== null ? (
    <img 
      alt="" 
      src={item.u_user_image} 
      style={{ 
        width: '100%', 
        height: '100%', 
        borderRadius: '50%', 
        objectFit: 'cover' 
      }} 
    />
  ) : (
    item.u_username && item.u_username[0].toUpperCase()
  )}
</div>
                                          </div>
                                          <div className="text-center"style={{ fontSize: "10px" }}>{item.applied_date.split('T')[0].split('-').reverse().join('/')}</div>
                                      </div>
                                      
                                      <div class="col-lg-4">
                                          <div class="candidate-list-content mt-3 mt-lg-0">
                                              <h5 class="fs-19 mb-0">
                                                  <a class="primary-link" href="#">{item.u_username}</a>
                                              </h5>
                                              <p class="text-muted mb-0">{item.u_user_roll}</p>
                                              <ul class="list-inline mb-0 text-muted">
                                                  <li class="list-inline-item"><i class="bi bi-building"></i>{item.u_company_name} {item.r_exp_year}.{item.r_exp_month} year </li>
                                                  {/* <li class="list-inline-item"> 5-7 year</li> */}
                                              </ul>
                                          </div>
                                      </div>
                                      <div class="col-sm-4 py-1">
              <span class="badge bg-secondary text-wrap text-break ">{item.r_skill}</span>
              <span class="badge bg-secondary text-wrap text-break">{item.r_certificate}</span>
            </div>

            <div class="col-sm-2 text-lg-end p-2">
              {/* <a href="#" class="btn btn-primary stretched-link m-1">Resume</a> */}


              {/* {item.r_attachment && (
    <a href={item.r_attachment} download>
      <i className="bi bi-download" /> 
    </a>
  )} */}
          {/* <Button variant="outline-primary" href={item.r_attachment} download target="_blank" rel="noopener noreferrer">
  <i className="bi bi-download text-danger" /> Resume
</Button> */}
<ResumeDetails  rname={item.u_username}rtitle={item.r_title} rcertificate={item.r_certificate} rexp_year={item.r_exp_year}rexp_month={item.r_exp_month} rskill={item.r_skill}ucompany={item.u_company_name} rdesc={item.resume_desc} rattachment={item.r_attachment}/>

              <Button className="btn btn-danger m-1" onClick={()=>setdeldata(item.applied_id,setapplicantname(item.u_username))}>Remove</Button>
            </div>
            {/* onClick={()=>setdeldata(item.id,setdeleteshopname(item.shop_name),setdeleteshopurl(item.shop_url))} */}
                                  </div>
                                  {/* <div class="favorite-icon d-flex flex-column align-items-center"> */}
                                  {/* <text className='fs-12 text-muted fw-normal italic'>25-10-2025</text> */}
  {/* <div class="d-flex">
    <FcViewDetails className='fs-2'/>
    <MdCancelPresentation className='fs-2 text-danger'/>
  </div>
</div> */}

                              </div>
                          </div>
      )}

<Modal show={showdel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete this  <b>{getapplicantname}</b> applicant?
            
        </Modal.Body>
        <Modal.Footer>
         <Button variant="primary" onClick={()=>removeapplicationreq(getvalue)}>
            Delete
          </Button> 
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
                          {/* <div class="candidate-list-box card mt-1">
                              <div class="p-2 card-body">
                                  <div class="align-items-center row">
                                      <div class="col-auto">
                                          <div class="candidate-list-images">
                                              <a href="#"><img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" class="avatar-md img-thumbnail rounded-circle" /></a>
                                          </div>
                                      </div>
                                      <div class="col-lg-5">
                                          <div class="candidate-list-content mt-3 mt-lg-0">
                                              <h5 class="fs-19 mb-0">
                                                  <a class="primary-link" href="#">Rebecca Swartz </a>
                                              </h5>
                                              <p class="text-muted mb-2">Software tester with manager post</p>
                                              <ul class="list-inline mb-0 text-muted">
                                                  <li class="list-inline-item"><i class="bi bi-building"></i>Cognizant technology solution pvt ltd </li>
                                                  <li class="list-inline-item"> 5-7 year</li>
                                              </ul>
                                          </div>
                                      </div>
                                      <div class="col-lg-4">
                                          <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1"><span class="badge bg-soft-secondary fs-14 mt-1 text-wrap">
  Design dfadsk dfasdf dfasd fadsf adsfadsf dfads dfa dfasd fadfa dfasd fadfa sd dfadsf dfads 
</span></div>
                                      </div>
                                  </div>
                                  <div class="favorite-icon d-flex flex-column align-items-center">
                                 
  <div class="d-flex">
    <FcViewDetails className='fs-2'/>
    <MdCancelPresentation className='fs-2 text-danger'/>
  </div>
</div>
                              </div>
                          </div> */}

 {/* <text className='fs-12 text-muted fw-normal italic'>25-10-2025</text> */}

         {/* <Row className='border p-2 my-2'>
            <Col xs={6}sm ={2}>
           
  <figure>
    <Card.Img variant="top" src="holder.js/200px300?text=Image cap" />
    <figcaption className="text-center">image</figcaption>
  </figure>

            </Col>
            <Col xs={6}sm={2} > */}
            {/* <Card.Body className="py-0"> */}
          {/* <Card.Title className='small' >Candidate Name From Company</Card.Title>
          <Card.Text className="appliedOnDate"><strong className='appliedontext'>Applied on-</strong>25/02/2025</Card.Text>
          </Col>
          <Col xs={12}sm={5} >
          <Card.Text className="text-muted mb-1">Skill Year of Exp dfas dfadsf asdfad fadsf adsf adsfa dfads fadsfadsfads dfadsfadfads fadsfadsfads </Card.Text>
        
        </Col>
            <Col xs={6} sm={2} className="d-flex flex-sm-column align-items-end" >
              
             
              <FcViewDetails className='fs-2'/>
                </Col>
                <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-start ">
                <AiOutlineDelete className='fs-2 text-danger'/>
</Col>
                
          </Row> */}

{/* 
          <Row className='border border p-2 my-2'>
            <Col xs={6}sm ={2}>
           
  <figure>
    <Card.Img variant="top" src="holder.js/200px300?text=Image cap" />
    <figcaption className="text-center">image</figcaption>
  </figure>

            </Col>
            <Col xs={6}sm={2} >
          
          <Card.Title className='small' >Candidate Name From Company</Card.Title>
          <Card.Text className="appliedOnDate"><strong className='appliedontext'>Applied on-</strong>25/02/2025</Card.Text>
          </Col>
          <Col xs={12}sm={5} >
          <Card.Text className="text-muted mb-1">Skill Year of Exp dfas dfadsf asdfad fadsf adsf adsfa dfads fadsfadsfads dfadsfadfads fadsfadsfads </Card.Text>
     
        </Col>
            <Col xs={6} sm={2} className="d-flex flex-sm-column align-items-end" >
           
             
              <FcViewDetails className='fs-2'/>
                </Col>
                <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-start ">
                <AiOutlineDelete className='fs-2 text-danger'/>
</Col>
                
          </Row> */}


     {props.getcount(allJobData.length)}   
     
</>

)
}
