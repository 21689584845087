import { useState } from "react"
import { useEffect } from "react"
import { Link } from "react-router-dom"

export default function JobListSameUser(props)
{
  const [usersamejob,setusersamejob]=useState([])
useEffect(()=>
{
  setusersamejob(props.samejoblist)
  console.log("jobsame",props.samejoblist)
},[props])
    return(
      <>
      
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"/>
        <div className="col-md-12 col-lg-12 d-flex align-items-center ">
        <div  style={{ width: "100%", maxWidth: "100%", padding: 0, margin: 0 }}>
           <div className="row my-4 no-gutters">
           <hr></hr>
           {usersamejob &&
      usersamejob.length > 0 &&
      usersamejob.map((item, i)=>
             <div className="col-12 ">
             {/* style={{ width: "98%" }} */}
               <div className="text-start "style={{ marginLeft: "20px", marginRight: "20px" }} >
               <Link to ={`/${item.user_id}/${item.job_id_a}`} target="_blank" 
          state={{ itemData: item }}>
                 <span className="fw-semibold mb-0" style={{ fontSize: "14px" }}> {item.job_title}</span>
                 </Link>
                 <div style={{ fontSize: "14px" }}><i style={{ fontSize: "12px" }}>in </i> {item.u_company_name}</div>
                 <span className="m-1"style={{ fontSize: "14px" }}>Exp. {item.j_exp_from_year}- {item.j_exp_to_year} Year</span>
                 <span class="badge bg-secondary text-wrap text-break ">{item.j_skill} </span>
                 <div> <span class="badge bg-success text-wrap text-break"> <i className="bi bi-geo-alt-fill"style={{ fontSize: "12px" }}></i>{item.j_city} </span> </div>
                 

               </div>
               <hr />
             </div>
      )}
             
           </div>
    
     </div>
     </div>
     </>
    )
}