import { Col, Row } from "react-bootstrap";
import SideMenu from "./SideMenu";
import PostYourJob from "./PostYourJob";
import Container from 'react-bootstrap/Container';
import { Offcanvas } from 'react-bootstrap';
import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { NavLink,useNavigate,useParams ,Link} from "react-router-dom";
import PostedJobList from "./PostedJobList";
import AppliedJob from "./AppliedJob";
import SearchPage from "./SearchPage";
import ResumeList from "./ResumeList";
import FollowList from "./FollowList";
import DashboardContent from "./DashboardContent";
import ContactList from "./ContactList";
import Profile from "./Profile";
import HeadMenu from "./HeadMenu";
import CandidateList from "./CandidateList";
import { useLocation } from 'react-router-dom';
import JobChat from "./JobChat";
import PromotedJobDisplay from "./PromotedJobDisplay";
import FooterData from "./FooterData";
import JobAffiliate from "./JobAffiliate";

export default function DashboardPage()
{
  
 
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  //   const params= useParams()
  // const {name}=params
  // console.log("name1",name)

  const location = useLocation();
const path = location.pathname;
const name = path.split('/').pop();

  const [allJobData,setAllJobData]=useState([])
  const [status,setStatus]= useState('')
  const [getPostedJobCount,setPostedJobCount]=useState('')
  const [getupdatecount,setupdatecount]=useState(false)
  const [searchKey,setSearchKey]=useState(10)
  
  console.log("postedjoblist11",getupdatecount)
  useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id
      let data={userIdAuto,searchKey}
      const datas=JSON.stringify(data)
      
      fetch(`${window.siteUrl}/getjobdata/${datas}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
     result.json().then((resp)=>{
      setAllJobData(resp) 
          console.log("postedjoblist",resp)
          const draftCount = resp.filter((job) => job.j_status === "posted").length;
          console.log("postedjoblist1",draftCount)
          setPostedJobCount(draftCount)
          
     }) 
   }
   )
  }
  },[name==='postedjoblist',getupdatecount,searchKey])


  useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id
      fetch(`${window.siteUrl}/getuserdetails/${userIdAuto}`,
    //fetch(`//allstoresprice.com:3443/getuserdetails/${usernumber}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
    console.log("user details1",result)
     result.json().then((resp)=>{
       console.log("user details",resp[0])
      
          localStorage.setItem('user-info',JSON.stringify(resp[0]))  
          
          
     }) 
   }
   )
  }
  },[]) 

return(

    <Container fluid="md" className="my-3">
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"/>
        {/* <a href="https://amzn.to/43q367H">headphone</a> */}

        
        <HeadMenu/>

        
        <PromotedJobDisplay/>
    <Row>
      <Col  sm={3} className="d-md-block d-none">
       
        <SideMenu joblistposted={getPostedJobCount}/>
      </Col>
      <Col xs={12} sm={9}>
      <div className="navbar-toggler d-md-none" onClick={handleShow} style={{ textAlign: 'left' }}>
  <i className="bi bi-list" style={{ fontSize: 24, color: 'blue' }} />
</div>


{name=='postedjoblist'?<PostedJobList jobList={allJobData} updatejobcount={setupdatecount} searchkeydata={setSearchKey}/>
        :name === 'posturjob'?<PostYourJob/>
        :name === 'appliedjob'?<AppliedJob />
        :name === 'dash'?<DashboardContent />
        :name === 'searchjob'?<SearchPage />
        :name === 'contactlist'?<ContactList />
        :name === 'candidatelist'?<CandidateList />
        :name === 'resume'?<ResumeList />
        :name === 'profile'?<Profile />
        :name === 'chat'?<JobChat />
        :name === 'followlist'?<FollowList />
        :name?<SearchPage />
        :<DashboardContent />
}
{/* {name === 'posturjob' ? <PostYourJob /> :
name === 'postedjoblist' ? <PostedJobList /> :
name === 'appliedjob' ? <AppliedJob /> :
name === 'searchjob' ? <SearchPage /> :
name === 'resume' ? <ResumeList /> :
name === 'followlist' ? <FollowList /> :
<DashboardPage />} */}
        
        <Offcanvas show={show} onHide={handleClose}>
  <Offcanvas.Header closeButton>
    <Offcanvas.Title>Menu</Offcanvas.Title>
  </Offcanvas.Header>
  <Offcanvas.Body onClick={(e) => handleClose()}>
    <SideMenu />
  </Offcanvas.Body>
</Offcanvas>
      </Col>
    </Row>
    <PromotedJobDisplay/>
    <FooterData/>
  </Container>
)

}