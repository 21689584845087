import { Button, InputGroup, NavLink, Tabs } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import SingleSelect from "./SingleSelect";
import Container from 'react-bootstrap/Container';
import { useNavigate,useParams ,Link} from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import ContactList from "./ContactList";
import FollowList from "./FollowList";
import { useState,useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Captureimg from "./Elements/Captureimg"
import CompanyList from "./Elements/CompanyList";
import RollList from "./Elements/RollList";
import EducationList from "./Elements/EducationList";
import CityList from "./Elements/CityList";
import axios from 'axios';
import isEmail from 'validator/es/lib/isEmail';
import IndustryList from "./Elements/IndustryList";
import SelectPlan from "./SelectPlan";
import Services from "./Services";
import Resetpassword from "./LoginAuth/Reset_password";
import Job_ExitAccount from "./Job_ExitAccount";


export default function Profile()
{

    const params= useParams()
    const {name}=params
    const [getimage, setimage]=useState('')
    const [dbimage, setdbimage]=useState('')
    const [radioValue, setRadioValue] = useState('product');
    const [isPhoto,setPhoto]=useState(true)

    const [preview, setPreview] = useState(null);
    const [userIdSelf, setUserIdSelf]=useState('')
    const [getuser_id,setuser_id]=useState('')
    const [userName, setUserName]=useState('')
    const [companyName, setCompanyName]=useState('')
    const [roll, setRoll]=useState('')
    const[experience, setExperience]=useState('')
    const[eduction, setEducation]=useState('')
    const[phoneNumber, setphoneNumber]=useState('')
    const[emailId, setEmailId]=useState('')
    const[city, setCity]=useState([])
    const[state, setState]=useState('')
    const[country, setCountry]=useState('')
    const[status, setStatus]=useState(false)
    const[expYear, setExpYear]=useState('')
    const[expMonth, setExpMonth]=useState('')
    const[getAllprofileData,setAllprofileData]=useState('')
    const[industry, setIndustry]=useState('')

    const [getCityList,setCityList]= useState([])
    const [getSkillList,setSkillList]= useState([])
    const [getEducationList,setEducationList]= useState([])
    const [getCertificateList,setCertificateList]= useState([])
    const [getRollList,setRollList]= useState([])
    const [getCompanyList,setCompanyList]= useState([])
    const [enableButton,setEnableButton]= useState(true)
    const [isParent, setIsParent] = useState(true);

console.log("imageprof",getimage)
    // const handleImageChange = (e) => {
    //   const file = e.target.files[0];
    //   setimage(file);
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setPreview(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // };
    let userData
useEffect(()=>
{
  if(localStorage.getItem('user-info'))
	{
	  userData=localStorage.getItem('user-info')
		const details= JSON.parse(userData);
    setuser_id(details.user_id)
    
}
})

    const handleImageCapture = (file) => {
      setimage(file);
    };
    
    const handleRadioChange = (event) => {
      setRadioValue(event.target.value);
    };
    
    
    const [resizedImage, setResizedImage] = useState(null);
    
    const resizeImage = (image) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const width = 300; // desired width
      const height = 250; // desired height
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);
      return canvas.toDataURL();
    };
    // useEffect(() => {
    //   if (getimage) {
    //     const image = new Image();
    //     image.onload = () => {
    //       const resizedImage = resizeImage(image);
    //       setResizedImage(resizedImage);
    //     };
    //     image.src = URL.createObjectURL(getimage);
    //   }
    // }, [getimage]);

   

    function addprofile()
    {
    const  formData= new FormData();
    formData.append('userIdSelf',userIdSelf)
    formData.append('userName',userName)
    formData.append('companyName',companyName)
    formData.append('roll',roll)
    formData.append('expYear',expYear)
    formData.append('expMonth',expMonth)
    formData.append('eduction',eduction)
    formData.append('phoneNumber',phoneNumber)
    formData.append('emailId',emailId)
    formData.append('city',city)
    formData.append('getimage',getimage)
    formData.append('getuser_id',getuser_id)
    formData.append('industry',industry)
    
    for (const [key, value] of formData.entries()) {
      console.log("image123",`${key}: ${value}`);
    }

    axios.post(window.siteUrl+'/addprofileimg',formData,
//axios.post('//allstoresprice.com:3443/addshopimg',formData,
{
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth'),
  foldername:getuser_id
}
}
)
.then(res=>
  {
    console.log("Addprofile2",res)
    // localStorage.setItem("profileimg",res.data.img)
    if(res.data.result.affectedRows>=1)
    {
      setStatus(true)
      console.log("Addprofile1",res)
     
    }
    
    console.log("error",res.data.result.affectedRows)
   
  }, 
res1=>console.log("Addprofile",res1.cityResults))
.catch(err=>console.log(err))

  setTimeout(() => {
      setStatus(false)
    }, 4000)

// setTimeout(() => {
  
//   setshowshopimgmess(false)
  
//   img.current.value='';
  
// }, 4000)
}


useEffect(()=>{
  if(localStorage.getItem('user-info')){
    let userdata=localStorage.getItem('user-info')
    let parseVal=JSON.parse(userdata)
    let userIdAuto= parseVal.user_id
    fetch(`${window.siteUrl}/getuserdetails/${userIdAuto}`,
  //fetch(`//allstoresprice.com:3443/getuserdetails/${usernumber}`,
  {
    headers: {
    authorization:'bearar'+' '+localStorage.getItem('auth')
  }
}).then((result)=>
 {
  console.log("user details1",result)
   result.json().then((resp)=>{
     console.log("user details",resp[0])
    //  setcount(getplist.length) 
        //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
        setAllprofileData(resp[0])
        setUserName(resp[0].u_name)
        setCity(resp[0].u_city)
        setUserIdSelf(resp[0].user_id_self)
        setdbimage(resp[0].u_image)
        console.log("imageurl",resp[0].u_image)
        setphoneNumber(resp[0].u_mobile_num)
        setEmailId(resp[0].u_email_id)
        setCompanyName(resp[0].u_company_name)
        setRoll(resp[0].u_roll)
        setExpYear(resp[0].exp_year)
        setExpMonth(resp[0].exp_month)
        setEducation(resp[0].u_education)
        setIndustry(resp[0].u_industry)

        localStorage.setItem('user-info',JSON.stringify(resp[0]))  
        
        // if(resp[0].City!=null){
        // setcity(resp[0].City)
        // }
        //  setshowdetails(resp[0].showDetails)
        // setMobile(resp[0].user_number)
        // setareapincode(resp[0].user_areaCode)
        // setprofileimg(resp[0].profile_img)
        //  localStorage.setItem('shop-name',JSON.stringify(resp))  
   }) 
 }
 )
}
},[status]) 


useEffect(()=>{
  if(localStorage.getItem('user-info')){
         
    fetch(`${window.siteUrl}/getlist`,
  {
    headers: {
    authorization:'bearar'+' '+localStorage.getItem('auth')
  }
}).then((result)=>
 {
   result.json().then((resp)=>{
    setCityList(resp.cityList) 
    setSkillList((resp.skillList))
    setEducationList((resp.educationList))
    setCertificateList((resp.certificateList))
    setRollList((resp.rollList))
    setCompanyList((resp.companyList))
        console.log("citylist123",resp)
        setStatus(false)
   }) 
 }
 )
}
},[])

const navigate = useNavigate();

const handleTabSelect = (key) => {
  if (key === 'follow') {
    navigate('/followlist');
  }
};
  

useEffect(() => {
  
  if (companyName) {
    console.log("companyName", enableButton)
    setEnableButton(false)
  } else {
    setEnableButton(true)
  }
})

    return(
        <>
     {/* <div class="container-xl px-4 mt-4"> */}
     <Container fluid='md' className='my-3'  >
     <h2 className="dashboard-heading">Profile Page</h2>
     
     <Tabs 
      defaultActiveKey="profile"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
      // onSelect={handleTabSelect}


      onSelect={(key) => {
        // if (key === 'contact') {
        //   window.history.pushState({}, '', '/profile/contact');
        // }
        if (key === 'profile') {
          window.history.pushState({}, '', '/profile');
        }
        if (key === 'follow') {
          window.history.pushState({}, '', '/profile/follow');
        }
      }}
    >
      <Tab eventKey="profile" title="Profile">
      {
            status?
            <span className="text-success">
Record updated successfully
            </span>:
            null
          }
      <hr className="mt-0 mb-4"/>
      
    <div class="row">
        <div class="col-xl-4">
         
            <div className="card mb-4 mb-xl-0">
                <div class="card-header">Profile Picture</div>
                <div class="card-body text-center">
                
                  {/* getimage ? URL.createObjectURL(getimage) : item.shop_img */}
                  {/* img-account-profile rounded-circle mb-2 p-2 */}
                  {console.log("image",getimage)}
                  
                  
                  <Form.Group controlId="formFile" className="mb-2  ">
  {/* <Form.Label>Upload product image</Form.Label> */}
  {/* {getimage ? URL.createObjectURL(getimage) : "http://bootdey.com/img/Content/avatar/avatar1.png"}
   */}
  
  <img 
  src={getimage? URL.createObjectURL(getimage) : dbimage?dbimage:"http://bootdey.com/img/Content/avatar/avatar1.png"}
  alt="Image" 
  className="ms-2 img-account-profile rounded-circle mb-2 p-2 bg-primary" 
  style={{ 
    width: "170px", 
    height: "170px", 
    objectFit: "cover", 
    overflow: "hidden" 
  }} 
/>

<Captureimg 
              onImageCapture={(image, file) => console.log("imagephoto", image, file)}
              onFileSet={(file) => handleImageCapture(file)}
              onError={(error) => console.error("Camera error:", error)}
            />
          


        <div className="d-flex my-1">
        
          <Form.Control type="file"  className='border border-dark' onChange={(e)=>setimage(e.target.files[0])}/>
          
        </div>
                    
        
      </Form.Group>
      {/* <Captureimg 
    onImageCapture={(image, file) => console.log("imagephoto", image, file)} 
    onFileSet={(file) => handleImageCapture(file)} 
  /> */}
  
  {/* <Captureimg 
  onImageCapture={(image, file) => console.log("imagephoto", image, file)}
  onFileSet={(file) => handleImageCapture(file)}
  onError={(error) => console.error("Camera error:", error)}
/> */}

                </div>
                
            </div>
            
        </div>
        <div class="col-xl-8">
        
            <div className="card mb-4">
                <div className="card-header">Account Details</div>
                {
        !companyName?
                <span className="text-danger">Please update profile details</span>:
                null
                  }
                <div className="card-body">
                
                    <form>
                       
                       {/* <label class="small mb-1" for="inputUsername">Username (how your name will appear to other users on the site)</label> */}
                        {/* <div class="mb-3">
                            
                            <input class="form-control" id="inputUsername" type="text" placeholder="Enter your username" />
                        </div> */}
                    

                    {/* <div className="form-group my-2">
	   <Form.Floating className="mb-3">
        <Form.Control maxLength={20}
          id="UserId"
          controlId="UserId"
          type="text"
          className='border border-dark'
          // placeholder="Place"
          value={userIdSelf} onChange={(e)=>setUserIdSelf(e.target.value)}
        />
        {console.log("cityeror",userIdSelf=="")}
        {userIdSelf==""?
  <label for="floatingInput" className='text-danger'>Enter User Id</label>
  :
  <label htmlFor="floatingInputCustom">Enter User Id</label>
}
      </Form.Floating>
	   </div>  


     <div className="form-group my-2">
	   <Form.Floating className="mb-3">
        <Form.Control maxLength={20}
          id="inputFirstName"
          controlId="inputFirstName"
          type="text"
          className='border border-dark'
          // placeholder="Place"
          value={userName} onChange={(e)=>setUserName(e.target.value)}
        />
        {console.log("cityeror",userName=="")}
        {userName==""?
  <label for="floatingInput" className='text-danger'>Enter Full Name</label>
  :
  <label htmlFor="floatingInputCustom">Enter Full Name</label>
}
      </Form.Floating>
	   </div>  */}

            <div className="row">
                        {/* <div className="col-md-6"> */}
                                {/* <label class="small mb-1" for="inputLastName">Last name</label> */}
                                {/* <input class="form-control" id="UserId" type="text" placeholder="UserIdSelf" value={userIdSelf} onChange={(e)=>setUserIdSelf(e.target.value)} />
                            </div> */}

                            <div className="col-md-6 mb-2">
                                <label class="small mb-1" for="inputFirstName">Your Name</label>
                                <input class="form-control" id="inputFirstName" type="text" placeholder="Enter Full Name" value={userName} onChange={(e)=>setUserName(e.target.value)} />
                            </div>
                            <div className="col-md-6 mb-2">
                                <label class="small mb-1" for="inputPhone">Mobile number</label>
                                <input className="form-control" id="inputPhone" type="tel" placeholder="Enter your Mobile number" value={phoneNumber} onChange={(e)=>setphoneNumber(e.target.value)}/>
                            </div>
                        {/* </div> */}
                       
                        {/* <div className="row gx-3 mb-3"> */}
                            
                            <div className="col-md-6 mb-2">
                                <label class="small mb-1" for="inputOrgName"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Company Name</label>
                            
                                  <CompanyList selcompany={setCompanyName} getdefaultcompany={companyName} getdblist={getCompanyList} />
                            </div>
                           
                            <div className="col-md-6 mb-2">
                                <label class="small mb-1" for="inputFirstName">Current Rol</label>
                                
                               <RollList selroll={setRoll} getdefaultroll={roll} getdblist={getRollList}/>
                            </div>
                        {/* </div> */}

                        
                        {/* <div className="row gx-3 mb-3"> */}
                            
                        <div className="col-md-6 mb-2">
                                <label class="small mb-1" for="inputLastName">Experience</label>
                                {/* <input class="form-control" id="inputLastName" type="text" placeholder="Year of Exp" /> */}
                                <InputGroup>
       <InputGroup.Text id="basic-addon2">Exp</InputGroup.Text> 
      <Form.Select aria-label="Default select example" value={expYear} onChange={(e)=>setExpYear(e.target.value)}>
      <option>Year</option>
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
      <option value="16">16</option>
      <option value="17">17</option>
      <option value="18">18</option>
      <option value="19">19</option>
      <option value="20">20</option>
      <option value="21">21</option>
      <option value="22">22</option>
      <option value="23">23</option>
      <option value="24">24</option>
      <option value="25">25</option>
      <option value="26">26</option>
      <option value="27">27</option>
      <option value="28">28</option>
      <option value="29">29</option>
      <option value="30">30</option>
      <option value="31">31</option>
      <option value="32">32</option>
      <option value="33">33</option>
      <option value="34">34</option>
      <option value="35">35</option>
      <option value="36">36</option>
      <option value="37">37</option>
      <option value="38">38</option>
      <option value="39">39</option>
      <option value="40">40</option>
      <option value="41">41</option>
      <option value="42">42</option>
      <option value="43">43</option>
      <option value="44">44</option>
      <option value="45">45</option>
      <option value="46">46</option>
      <option value="47">47</option>
      <option value="48">48</option>
      <option value="49">49</option>
      <option value="50">50</option>

    </Form.Select>
    {/* <InputGroup.Text id="basic-addon2">Year</InputGroup.Text> */}
      <Form.Select aria-label="Default select example" value={expMonth} onChange={(e)=>setExpMonth(e.target.value)}>
      <option>Month</option>
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
    </Form.Select>
    </InputGroup>
                            </div>

                            <div className="col-md-6 mb-2">
                                <label class="small mb-1" for="inputLastName">Email ID</label>
                                <input class="form-control" id="email" type="text" placeholder="Email ID default" value={emailId}/>
                               
                            </div>

                           
                        {/* </div> */}

                        {/* <div className="row gx-3 mb-3"> */}
                        <div className="col-md-6 mb-2">
                                <label class="small mb-1" for="inputLocation">Location</label>
                                {console.log("city234",city)}
                               <CityList selcity={setCity} getdefaultcity={city} getdblist={getCityList}/>
                            </div>

                        {/* <div className="col-md-6">
                                <label class="small mb-1" for="inputFirstName">Enter your State</label> 
                                 <SingleSelect/>
                            </div> */}
                             <div className="col-md-6 mb-2">
                             <label class="small mb-1" for="inputEducation">Education</label> 
                             <EducationList seleducation={setEducation} getdefaulteducation={eduction} getdblist={getEducationList}/>
                             
                             </div>
                        
                        {/* </div> */}

                        {/* <div className="row gx-3 mb-3"> */}
                           
                        
                        <div className="col-md-6 mb-2">
                                <label class="small mb-1" for="inputLastName">Industry</label>

                                <IndustryList selindustry={setIndustry} getdefaultindustry={industry} getdblist={getCompanyList} />

                                {/* <CompanyList selcompany={setCompanyName} getdefaultcompany={companyName}/> */}
                            </div>
                           
                        </div>
                       
                        <Button className="btn btn-primary" type="button" disabled={enableButton}onClick={addprofile}>Save changes</Button>
                    </form>
                    
                </div>
                <hr></hr>
                <Job_ExitAccount/>
                {/* <hr></hr>
                <Services/>
                <hr></hr>
                <Resetpassword isParent={isParent} /> */}
                
            </div>
            
            
        </div>
        
    </div>
    
      </Tab>
      

      

      
      {/* <Tab eventKey="follow" title="Follow">
        <FollowList />
      </Tab> */}
  

</Tabs>
    
{/* </div> */}
</Container>
  </>
    )
}