import { Container } from "react-bootstrap";
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import { Row, Col, Card, Button } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CertificateList from "./Elements/CertificateList";
import SkillList from "./Elements/SkillList";
import { useEffect, useState,useRef } from "react";
import axios from 'axios';
import { FileIcon } from 'react-file-icon';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import HeaderPage from "./HeaderPage";


export default function ResumeList()
{
    const[certificate, setcertificate]=useState([])
    const[skill, setskill]=useState([])
    const[status, setStatus]=useState(false)
    const[user_id_auto, setUser_Id_Auto]=useState('')
    const[resume_title, setResume_Title]=useState('')
    const[email_id, setEmailId]=useState('')
    const[getimage, setimage]=useState('')
    const[exp_year, setexp_year]=useState('')
    const[exp_month, setexp_month]=useState('')
    const[allResumeData, setAllResumeData]=useState([])
    const [getenable, setdisable]=useState(true)
    const [selectedId, setSelectedId] = useState(null);
    const [getresumename,setresumename]=useState('')
    const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);
    
const [getCityList,setCityList]= useState([])
    const [getSkillList,setSkillList]= useState([])
    const [getEducationList,setEducationList]= useState([])
    const [getCertificateList,setCertificateList]= useState([])
    const [getCompanyName,setCompanyName]= useState('')
    const [getUserName,setUserName]= useState('')
    const [getMobNumber,setMobNumber]= useState('')
    const [getResumeDesc,setResumeDesc]= useState('')


    
    const email_idRef=useRef('')
    const resume_titleRef=useRef('')
    const imageref=useRef('')
    const exp_yearRef=useRef('')
    const exp_monthRef=useRef('')
    const resumeDescRef=useRef('')

let name="Resume"
    

    useEffect(()=>
    {
      if(localStorage.getItem('user-info')){
        let userdata=localStorage.getItem('user-info')
        let parseVal=JSON.parse(userdata)
        setUser_Id_Auto(parseVal.user_id)
        setCompanyName(parseVal.u_company_name)
        setUserName(parseVal.u_name)
        setMobNumber(parseVal.u_mobile_num)
      }
    },[])

    let imgsize=0;
    try{
    imgsize=getimage.size
  }catch
  {

  }
    useEffect(()=>
      {
        if(imgsize>0 && resume_title.length>0 && email_id.length>0 && allResumeData.length<3)
        {
          setdisable(false)
          console.log("enableblock1", getimage,resume_title, email_id)
          console.log("enableblock", getimage!=null && resume_title!=null && email_id!=null)
        }else{
          setdisable(true)
        }
      })

    function addresume()
    {
    const  formData= new FormData();
    formData.append('user_id_auto',user_id_auto)
    formData.append('resume_title',resume_title)
    formData.append('email_id',email_id)
    formData.append('getimage',getimage)
    formData.append('skill',skill)
    formData.append('certificate',certificate)
    formData.append('exp_year',exp_year)
    formData.append('exp_month',exp_month)
    formData.append('getCompanyName',getCompanyName)
    formData.append('getUserName',getUserName)
    formData.append('getMobNumber',getMobNumber)
    formData.append('getResumeDesc',getResumeDesc)
    
  
    for (const [key, value] of formData.entries()) {
      console.log("resumedata",`${key}: ${value}`);
    }
    axios.post(window.siteUrl+'/addresume',formData,

{
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth'),
  foldername:user_id_auto
}
}
)
.then(res=>
  {
    console.log("resume",res)
    if(res.data.result.affectedRows>=1)
    {
      setStatus(true)
    
    }
    
    console.log("error",res.data.result.affectedRows)
    
    email_idRef.current.value=''
    resume_titleRef.current.value=''
    imageref.current.value=''
    exp_yearRef.current.value=''
    exp_monthRef.current.value=''
    resumeDescRef.current.value=''
    // skillRef.current.value=''
    // certificateRef.current.value=''
    
   
  }, 
res1=>console.log("addresume",res1))
.catch(err=>console.log(err))

  setTimeout(() => {
      setStatus(false)
    }, 4000)
  }

  useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id
      fetch(`${window.siteUrl}/getresumedata/${userIdAuto}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
     result.json().then((resp)=>{
      setAllResumeData(resp) 
          console.log("resumedata",resp)
          setStatus(false)
          email_idRef.current.value=''
    resume_titleRef.current.value=''
    imageref.current.value=''
    exp_yearRef.current.value=''
    exp_monthRef.current.value=''
    resumeDescRef.current.value=''
    setResumeDesc('')

     }) 
   }
   )
  }
  },[status]) 

  useEffect(()=>{
    if(localStorage.getItem('user-info')){
           
      fetch(`${window.siteUrl}/getlist`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
     result.json().then((resp)=>{
      setCityList(resp.cityList) 
      setSkillList((resp.skillList))
      setEducationList((resp.educationList))
      setCertificateList((resp.certificateList))
          console.log("citylist123",resp)
          setStatus(false)
     }) 
   }
   )
  }
  },[])

  function updateresumestatus(resumeId)
  {
      const data={user_id_auto,resumeId}
      console.log("resumedata1",data)
try{
    let pResult= fetch(window.siteUrl+"/updateresumestatus",
    {
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'authorization': 'bearar'+' '+localStorage.getItem('auth')
     },
     body: JSON.stringify(data)
   }).then((pResult) => {
     //console.log("user list", pResult)
     pResult.json().then((resp) => {
       console.log("updateposted job status", resp)  
       if(resp.result.affectedRows>=1)
       {
         setStatus(true)
       }
       setTimeout(() => {
        setStatus(false)
      }, 4000)
}
     )
   }
   )
}catch
{

}
    }


    let [getvalue,setvalue]=useState()

    function setdeldata(id)
  {
    console.log("delete")
  handleShow ()
    setvalue(id)
   
  }


  function deleteapplication(applicationid)
{
  let data = applicationid
fetch(`${window.siteUrl}/deleteresume/${data}`,
{
method:'DELETE',

  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}

}).then((result)=>
 {
//   setapistatus(result.status)
setStatus(true)
       
  
result.json().then((resp)=>
{
  
 console.log("deleteresume",resp)
 handleClose()
 setTimeout(() => {
  
  setStatus(false)
 },4000)
 })
 })
}

    return(
        <>
       <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"></link>
        <Container fluid='md' className='my-0'  >
        {/* <HeaderPage headingname={name}/> */}
        <h2 className="dashboard-heading">Resume</h2>
        {
            status?
            <span className="text-success">
Record updated successfully
            </span>:
            null
          }
          
        <div className="card mb-4">
                <div className="resumeheader">Create Resume</div>
                <div className="card-body">
                    <form >
                
                    <div className="row">
  <div className="col-lg-6 mb-2">
  <label class="small mb-1" for="inputResumeTitle"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Resume Title</label>
    <input className="form-control" id="inputresumetitle" ref={resume_titleRef} type="text" placeholder="Enter resume title" onChange={(e)=>setResume_Title(e.target.value)}  />
  </div>
  <div className="col-lg-6 mb-2 ">
  <label class="small mb-1" for="inputExp">Experience</label>
    <InputGroup>
      <InputGroup.Text>Experience</InputGroup.Text>
      <Form.Select value={exp_year}aria-label="Years" ref={exp_yearRef}onChange={(e)=>setexp_year(e.target.value)}>
        <option>Year</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
        <option value="24">24</option>
        <option value="25">25</option>
        <option value="26">26</option>
        <option value="27">27</option>
        <option value="28">28</option>
        <option value="29">29</option>
        <option value="30">30</option>
        <option value="31">31</option>
      </Form.Select>
      <Form.Select value={exp_month} aria-label="Months" ref={exp_monthRef} onChange={(e)=>setexp_month(e.target.value)}>
        <option>Month</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
      </Form.Select>
    </InputGroup>
  </div>
{/* </div>
                        <div className="row gx-3 mb-3"> */}
                            
                            <div className="col-md-6 mb-1 ">
                            <label class="small mb-1" for="inputCertificate">Certificate</label>
                                {/* <label class="small mb-1" for="inputFirstName">First name</label> */}
                                <CertificateList clearstatus={status} selcertificate={setcertificate} getdefaultcertificate={certificate} getdblist={getCertificateList}/>
                            </div>
                            
                            <div className="col-md-6 mb-2">
                            <label class="small mb-1" for="inputCertificate"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Upload Resume</label>
 
  <input ref={imageref}
    className="form-control" 
    id="inputUploadResume" 
    type="file" 
    accept=".pdf,.doc,.docx" 
    onChange={(e)=>setimage(e.target.files[0])}
  />
  {/* <Form.Control type="file"  className='border border-dark' onChange={(e)=>setimage(e.target.files[0])}/> */}
</div>
 {/* </div>
                        <div className="row gx-3 mb-3"> */}
                            
                            <div className="col-md-6 mb-2">
                            <label class="small mb-1" for="inputEmailId"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Email_ID</label>
                                {/* <label class="small mb-1" for="inputFirstName">First name</label> */}
                                <input class="form-control" id="email" type="text" ref={email_idRef} placeholder="Email" onChange={(e)=>setEmailId(e.target.value)} />
                            </div>
                            <div className="col-md-6 mb-2">
                                {/* <label class="small mb-1" for="inputFirstName">First name</label> */}
                                <label class="small mb-1" for="inputSkill">Skills</label>
                                <SkillList  clearstatus={status} selskill={setskill} getdefaultskill={skill} getdblist={getSkillList}/>
                               
                            </div>
                         </div>

                        <div className="row gx-3 mb-3"> 
                            <div className="col-md-12">
                            <label class="small mb-1" for="inputdescription">Resume Description</label>
                        <FloatingLabel controlId="floatingTextarea2" className="my-2">
          <Form.Control
            id="addressid"
            controlId="addressidc"
            as="textarea"
            className="border border-dark"
            placeholder="Leave a comment here"
            value={getResumeDesc}
            onChange={(e) => setResumeDesc(e.target.value)}
            style={{ height: "150px" }}
            ref={resumeDescRef}
          />
          {getResumeDesc == "" ? (
            <label htmlFor="floatingInput" className="text-danger p-3">
              Enter Resume Details (1000-{getResumeDesc.length} left)
            </label>
          ) : (
            <label htmlFor="floatingInputCustom" className="p-3">
              Enter Resume Details (1000-{getResumeDesc.length} left char)
            </label>
          )}
        </FloatingLabel>
</div>
</div>
{console.log("enable",getenable)}
                       
                        <Button variant="success" disabled={getenable}className="me-3 my-2" onClick={addresume}>
      Add
    </Button>
    <Button variant="primary" onClick={()=>setStatus(true)} >Clear</Button>
                        </form>
                        </div>
                        </div>

                        <div className="card mb-4">
                          {
allResumeData.length>2?
                        <p className="text-danger">*Only 3 resume can be added*</p>:
                        null
                      }
                <div className="resumeheader">Resume List</div>
<span className="text-center fw-bold" style={{fontSize:'11px'}}>
*Make sure only one resume can be set as Active*
            </span>
     {/* <div class="card mb-3">
      <div class="card-body p-2">
        <div class="d-flex flex-column flex-lg-row">
        <div class="col-sm-2">
          <h6 class="h6">Attachment</h6>
            </div>
          <div class="row flex-fill">
            <div class="col-sm-5">
            <h6 class="h6">Resume Title</h6>
            </div>
            <div class="col-sm-4  ">
            <h6 class="h6">Group</h6>
            </div>
            <div class="col-sm-3 text-lg-end">
            <h6 class="h6">Action</h6>
            </div>
          </div>
        </div>
      </div>
      
    </div> */}
    {allResumeData &&
      allResumeData.length > 0 &&
      allResumeData.map((item, i)=>
      
                 <div class="card mb-1">
                  
      <div class="card-body">
        <div class="d-flex flex-column flex-lg-row">
            
        <div class="col-sm-2">
        
   {/* item.r_attachment.endsWith('.pdf') || item.r_attachment.endsWith('.doc') ||
   item.r_attachment.endsWith('.docx') ?
 <Card.Img variant="top" src={`/${item.r_attachment}`} className="imageh" /> :
   <FileIcon fileName={item.r_attachment} className="imageh" src={`/${item.r_attachment}`}/>
   */}

{
  item.r_attachment.endsWith('.pdf') ? (
    <div>
      <a 
        href={`/${item.r_attachment}`} 
        download 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ cursor: 'pointer' }}
      >
      <i className="bi bi-file-pdf-fill" style={{ fontSize: '75px',color: 'red' }}></i>
      </a>
    </div>
  ) : 
  ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', '.csv', '.odt', '.ods', '.odp'].some(ext => item.r_attachment.endsWith(ext)) ? (
    <div>
      <a 
        href={`/${item.r_attachment}`} 
        download 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ cursor: 'pointer' }}
      >
      <i className="bi bi-file-earmark-word" style={{ fontSize: '75px', color: 'blue' }}></i>
      </a>
    </div>
  ) : (
    <div>
      <a 
        href={`/${item.r_attachment}`} 
        download 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ cursor: 'pointer' }}
      >
        <i className="bi bi-file-text text-success" style={{ fontSize: '75px', color: 'black' }}></i>
        {/* <Card.Img variant="top" src={`/${item.r_attachment}`} className="imageh" />  */}
       
      </a>
    </div>
  )
}




{/* <Card.Img variant="top" src={`/${item.r_attachment}`} className="imageh" />  */}
          {/* <div class="candidate-list-images" src={`/${item.r_attachment}`}> 
          <FileIcon fileName={item.r_attachment} />
               </div>  */}
        </div>
          <div class="row flex-fill">
            <div class="col-sm-5">
                <h6 class="h6  text-wrap text-break">{item.r_title}</h6>
              <ul class="list-inline mb-0 text-muted">
            <li class="list-inline-item text-wrap text-break">{item.r_email_id}</li>
                 <li class="list-inline-item"> {item.exp_year}.{item.exp_month} year</li> 
             </ul>

            </div>
            <div class="col-sm-4 py-1">
              <span class="badge bg-secondary text-wrap text-break ">{item.r_skill}</span>
              <span class="badge bg-secondary text-wrap text-break">{item.r_certificate}</span>
            </div>
            <div className="col-sm-3 text-lg-end p-1 d-flex align-items-center justify-content-center">
           
            <div className="radio-wrapper">
  
            <Form.Check
  key={item.resume_id_a}
  inline
  label="Active"
  name="group1"
  type="radio"
  id={`radio-${item.resume_id_a}`}
  defaultChecked={item.active === "yes"}
  onChange={() => {setSelectedId(item.resume_id_a);updateresumestatus(item.resume_id_a);}}
/>
</div>
<Button variant="outline-danger" className="m-1" onClick={()=>setdeldata(item.resume_id_a,setresumename(item.r_title))}>Delete</Button>
{/* <Form.Check
          key={item.resume_id_a}
          inline
          label="Active"
          name="group1"
          type="radio"
          id={`radio-${item.resume_id_a}`}
          checked={selectedId === item.resume_id_a}
          
          onChange={() => setSelectedId(item.resume_id_a)}
        /> */}

{/* inline 
    label="Active" 
    name="group1" 
    type="radio" 
    id="radio1" */}

        {/* <Button class="btn btn-danger delete-btn stretched-link">Delete</Button> */}
        
            </div>
         
          </div>
        </div>
      </div>
      
      
    </div>
    
    
    )}
                </div>
</Container>

<Modal show={showdel} onHide={handleClose}>
<Modal.Header closeButton>
  <Modal.Title>Delete Resume</Modal.Title>
</Modal.Header>
<Modal.Body>Do you want to delete this  <b>{getresumename}</b> Resume?
    This will delete received application as well
</Modal.Body>
<Modal.Footer>
 <Button variant="primary" onClick={()=>deleteapplication(getvalue)}>
    Delete
  </Button> 
  <Button variant="secondary" onClick={handleClose}>
    No
  </Button>
</Modal.Footer>
</Modal> 
        </>
    )
}