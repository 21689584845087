
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardImg from 'react-bootstrap/CardImg';
import CardBody from 'react-bootstrap/CardBody';
import CardTitle from 'react-bootstrap/CardTitle';
import CardText from 'react-bootstrap/CardText';
import JobSearch from './JobSearch';
import HeadMenu from './HeadMenu';
export default function ()
{
return(
    <>  
  <Card className="my-2">
    <CardImg 
      alt="Card image cap"
      src="job.png"
      // src="https://loremflickr.com/900/180"
      // src="https://picsum.photos/900/180"
      style={{
        height: 300
      }}
      top
      width="100%"
    />
    {/* <div className="position-absolute top-0 start-0 w-100 h-100">
      <HeadMenu className="position-relative z-index-1" /> */}

    <div className="position-absolute top-50 start-50 translate-middle text-black">
      <h1>Find Your Job Here</h1>
      <p>connect with emplyee</p>
      
    </div>
    {/* </div> */}
</Card>
</>
)
}