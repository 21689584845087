import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState,useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { NavLink,useNavigate,useParams ,Link} from "react-router-dom";
import ResumeAddWithoutLogin from './ResumeAddWithoutLogin';
export default function SelectResumeApply(props)

{
   const [show, setShow] = useState(false);
   const[allResumeData, setAllResumeData]=useState([])
   const[status, setStatus]=useState(false)
   const [getJobUserData, setJobUserData]=useState('')
   const [getAllJobData, setAllJobData]=useState('')
   const [isLogin, setIsLogin]=useState(false)
   
   let userData
useEffect(()=>
{
  if(localStorage.getItem('user-info'))
	{
	  setIsLogin(true)
}
},)

useEffect(()=>
{
  setShow(false)
  
},[status])



   const handleShow = () => 
   {
    setShow(true);
    fetchResumeList()
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      console.log("logindata",{parseVal}.parseVal)
      console.log("jobdetails",props)
  console.log("jobdetails1",{parseVal}.parseVal)
  const mergedJson = Object.assign({},{parseVal}.parseVal, props);
  setJobUserData(mergedJson)
      console.log("jobdetailall",mergedJson)
      
      
    }
   }

   async function getReffered()
    { 
try{
  console.log("reffered",getAllJobData)
    let pResult= fetch(window.siteUrl+"/postapplyjob",
    {
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'authorization': 'bearar'+' '+localStorage.getItem('auth')
     },
     body: JSON.stringify(getAllJobData)
   }).then((pResult) => {
     console.log("applylist", pResult)
     pResult.json().then((resp) => {
       console.log("applyjob", resp)  
       if(resp.result.affectedRows>=1)
       {
         setStatus(true)
       }
       setTimeout(() => {
        setStatus(false)
      }, 4000)
}
     )
   }
   )
}catch
{

}
    }
   

   const handleClose = () => setShow(false);

   const [selectedResume, setSelectedResume] = useState(null);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedResumeData = allResumeData.find((item) => item.r_title === selectedValue);
    setSelectedResume(selectedResumeData);

    const mergedJson = Object.assign({},getJobUserData, selectedResumeData);
    setAllJobData(mergedJson)
    console.log("jobdetailall1",mergedJson)
  };


   function fetchResumeList()
   {
    
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id
      fetch(`${window.siteUrl}/getresumedata/${userIdAuto}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
     result.json().then((resp)=>{
      setAllResumeData(resp) 
          console.log("resumedata",resp)
     }) 
   }
   )
  }

  } 

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = selectedResume?.r_attachment;
    link.download = true;
    link.target = '_blank';
    link.click();
  };
  return (
   
    <>
     {
      isLogin?
      <>
      <Button variant="outline-primary" onClick={handleShow} className='fs-7 m-1 '>Apply</Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='fs-6'> Select Resume </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Card className="text-center">
        <Card.Header>
        {/* Select Your Resume */}
        <Form.Select aria-label="Default select example" onChange={handleSelectChange}>
        <option>Select Your Resume</option>
          {allResumeData && allResumeData.length > 0 && allResumeData.map((item, i) => (
            
            <option key={item.r_title} value={item.r_title}>{item.r_title}</option>
            
          ))}
        </Form.Select>
      </Card.Header>
     {selectedResume?
      <Card.Body>
        <Card.Title>{selectedResume?.r_title}</Card.Title>
        <Card.Text>
          <b>Skill Required -</b> {selectedResume?.r_skill}
        </Card.Text>
        <Card.Text> <b>Certificates -</b>
        {selectedResume?.r_certificate}
        </Card.Text> 
        
        <Card.Text> <b>Required Experience -</b>
        {selectedResume?.exp_year}-{selectedResume?.exp_month}
        </Card.Text>

        <Card.Text> <b>Email-id -</b>
        {selectedResume?.r_email_id}
        </Card.Text>
        <Card.Text>
  <b>Resume Attachment -</b>
 
  {selectedResume?.r_attachment && (
    <a href={selectedResume?.r_attachment} download>
      <i className="bi bi-download" /> 
    </a>
  )}
</Card.Text>
<Card.Text style={{ height: '200px', overflowY: 'auto' }}> <b>Resume Description -</b>
        {selectedResume?.resume_desc}
        </Card.Text>
       
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      :null
      
    }
      {/* <Card.Footer className="text-muted">Posted by- </Card.Footer> */}
    </Card>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" disabled={!selectedResume} onClick={getReffered}>Get Refered</Button>
          <Button variant="danger" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
      </>
      :
      <ResumeAddWithoutLogin show={show} hide={handleClose} jobdata={props}/>
    }
    </>
  );
}