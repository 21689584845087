import Banner from "./Banner";
import FooterData from "./FooterData";
import HeadMenu from "./HeadMenu";
import JobList from "./JobList";
import JobSearch from "./JobSearch";
import PromotedJobDisplay from "./PromotedJobDisplay";
import SearchPage from "./SearchPage";
import Container from 'react-bootstrap/Container';
export default function HomePage()
{

    return(
        <Container fluid='md' className='my-2'>
        <HeadMenu/>
        <PromotedJobDisplay/>
        <Banner />
        <JobSearch /> 
        <PromotedJobDisplay/>
        <FooterData/>
        </Container>
    )

}