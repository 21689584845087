
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { NavLink,useNavigate ,useHistory } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CloseButton from 'react-bootstrap/CloseButton';
import MobileOtp from './MobileOtp';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import PromotedJobDisplay from '../PromotedJobDisplay';

export default function Login(val)
{
  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

  const [getMobile,setMobile]=useState()
  const [getPin,setPin]=useState() 
  const [getStatus, setStatus]=useState([]);
  const [getError, setError]=useState("Incorrect Details");
  const [getisloginissue, setisloginissue]=useState(false);
  const [getlength, setlength]=useState('');
  const navigate=useNavigate(0);
  const [getuserEmail,setuserEmail]=useState('') 
  
 useEffect(()=>
 {
  if (getisloginissue==true)
  {
  setisloginissue(false)
}
 },[getMobile,getPin])
 
  
    function cancel()
    {
      navigate('/')
    }

    const handleClick = () => {
      navigate('/dash', { replace: true });
      window.location.reload();
    };
   async function login()
    {
      let loginData= {getuserEmail,getPin}
      
      // console.log("email",getuserEmail)
      try{
         let pResult= fetch(window.siteUrl+"/login",
          //let pResult= fetch("http://85.31.232.240:8080/login",
        //let pResult= fetch("//allstoresprice.com:3443/login",
        
        //let pResult= fetch("//allstoresprice.com:3443/leftPro",
        
         {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(loginData)
        }).then((pResult) => {
          //console.log("user list", pResult)
          pResult.json().then((resp) => {
            console.log("logindetails", resp)
            if(resp.auth && resp.result.length>0)
            {         
            setlength(resp.result.length) 
            localStorage.setItem('user-info',JSON.stringify(resp.result[0]))
            localStorage.setItem('auth',JSON.stringify(resp.auth))
            console.log("setlogin",JSON.stringify(resp.result[0]))
            try{
            localStorage.setItem("profileimg",resp.result[0].profile_img)
            
          }catch
          {

          }
            // localStorage.setItem('shopurl',(resp.result[0].shopName))
              // localStorage.setItem('shop-name',(resp.result[0].shop_name))
            // console.log("length is"+resp.result.length)
            
            
            } 
            else
            {
              setisloginissue(true) 
            }
            try{
            if( resp.result.length>=1)
            {
              handleClick()
       
        
       }
       else{
        setisloginissue(true)
       } 
      }catch{
        
      }
          })
        })
        
      }catch(error)
      {
        console.log("update error",error)
        
        
      }
      
      
    }
    

    function shopdetails(){
      //allstoresprice.com:3443
     // fetch(`//allstoresprice.com:3443/getshoplist/${getMobile}`)
      //fetch(`http://85.31.232.240:8080/getshoplist/${getMobile}`)
       fetch(`${window.siteUrl}/getshoplist/${getMobile}`,{
        headers: {
        authorization:'bearar'+' '+localStorage.getItem('auth')
      }
    })
      
      .then((result)=>
      
     {
       result.json().then((resp)=>{
         //console.log("shop list",resp)
        //  setcount(getplist.length) 
            //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
               localStorage.setItem('shop-name',JSON.stringify(resp))
              
       })
       
     }
     )
    }
   
//     useEffect(()=>
//     {
//        if( getlength>=1)
//       {
// navigate('/')
// }
// else{
//   console.log("status"+getStatus)
// }
//     },[getlength])

// pResult= await result.json();
// localStorage.setItem('user-info',JSON.stringify(pResult))
// console.log("result"+JSON.stringify(result));
//navigate('/otp')
        
    //}
    return(
      <>
{/* <AddC/> */}
{/* <Container fluid='md' style={{backgroundColor: "#20c997"}}>
<Row>
  <Col xs={12} sm={6}> */}
  <PromotedJobDisplay/>
<form className='container formC col-sm-4  col-xs-4 my-3 p-3' style={{ backgroundColor: '#20c997' }}>

<div className="form-group d-flex justify-content-center ">

<div className="col-sm-8 col-xs-4 align-self-center textformat">


<label for="floatingInput" className='loginLb '>Login</label>
<Row className="justify-content-md-center">
      <Col xs={12} sm={8}>
{
getisloginissue==true?
<label for="floatingInput" className='text-danger fw-bold'>{getError}</label>
:null
}
</Col>
</Row>
{/* <input maxLength={10} onChange={(e)=>setMobile(e.target.value)}type="tel" placeholder="Enter Mobile number" 
className="form-control m-3 text-center fs-5 mobileNumber" id="typePhone" name="phone" ></input> */}
<input onChange={(e)=>setuserEmail(e.target.value)}type="email" placeholder="Enter Your Email-ID" 
className="form-control m-3 text-center fs-5 userEmailid" id="typeEmail" name="email" ></input>

<input maxLength={4} onChange={(e)=>setPin(e.target.value)}type="text" placeholder ="set 4 digit Pin" 
className="form-control m-3 text-center fs-5 pinNumber"id="pin">  
</input>
<Row className="justify-content-md-center">
      <Col xs={12} sm={8}>
<Button onClick={login}variant="primary" className='btn-sm'>Login</Button>{' '} 
<Button onClick={cancel}variant="danger" className='btn-sm'>Cancel</Button>{' '} 
</Col>
</Row>
<NavLink to = "/setpassword" className="login1" >Reset password</NavLink>

</div>
</div>
{/*  */}
</form>
{/* </Col>
<Col xs={12} sm={6} >
<div className='text-start my-4'>
<div className='fs-2'>Steps To Register Your Self:</div>

<ListGroup as="ol" numbered >
      <ListGroup.Item as="li" style={{backgroundColor: "#20c997"}}>Login/SignUp</ListGroup.Item>
      <ListGroup.Item as="li" style={{backgroundColor: "#20c997"}} >Create shop as per your choice</ListGroup.Item>
      <ListGroup.Item as="li" style={{backgroundColor: "#20c997"}} >Add product what you want to sell in the market.</ListGroup.Item>
      <ListGroup.Item as="li"style={{backgroundColor: "#20c997"}} >Your product will be live and displayed under shop name created. You can use the url created with your shop name for sharing others.</ListGroup.Item>
      <ListGroup.Item as="li" style={{backgroundColor: "#20c997"}} >Best of luck for your support for connecting with us through digit platform.</ListGroup.Item>
      
    </ListGroup>

  </div> 
</Col>
</Row> 
</Container> */}
</>  )
}