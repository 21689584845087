import React, { useState, useEffect } from 'react';
import Webcam from 'react-webcam';

const Captureimg = ({ onImageCapture, onFileSet }) => {
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [showCamera, setShowCamera] = useState(true);
  const webcamRef = React.useRef(null);

  useEffect(() => {
    if (cameraActive) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          webcamRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Camera error:", error);
        });
    }
  }, [cameraActive]);

  const startCamera = () => {
    setCameraActive(true);
    setShowCamera(true);
  };

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot({ facingMode: { exact: 'user' }, });
      console.log('Image src:', imageSrc);
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const originalWidth = img.naturalWidth;
        const originalHeight = img.naturalHeight;
        console.log('Original width:', originalWidth);
        console.log('Original height:', originalHeight);
        const canvas = document.createElement('canvas');
        canvas.width = originalWidth;
        canvas.height = originalHeight;
        const ctx = canvas.getContext('2d') || canvas.getContext('webkit-2d');
        ctx.drawImage(img, 0, 0, originalWidth, originalHeight);
        const resizedImage = canvas.toDataURL();
        setImage(resizedImage);
        canvas.toBlob((blob) => {
          const file = new File([blob], 'captured-image.jpg', { type: 'image/jpeg' });
          setFile(file);
          onImageCapture(resizedImage, file);
          onFileSet(file);
          setShowCamera(false);
        }, 'image/jpeg', 1);
      };
    }
  };

  const retakePhoto = () => {
    setShowCamera(true);
    setImage(null);
  };

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  return (
    <div className="camera-container">
      {!cameraActive && (
        <button className='text-danger' onClick={startCamera}>Start Camera</button>
      )}
      {showCamera && cameraActive && (
        <Webcam ref={webcamRef} facingMode="user" width="268" height="180" />
//         <Webcam
//   ref={webcamRef}
//   facingMode={isMobile ? { exact: 'environment' } : 'user'}
//   width="268"
//   height="180"
// />
      )}
      {showCamera && cameraActive && (
        <button className='text-danger' onClick={captureImage}>Capture Photo</button>
      )}
      {!showCamera && image && (
        <div>
          <img src={image} alt="Captured Image" style={{ width: '80%', height: '24vh', objectFit: 'contain' }} />
          <button onClick={retakePhoto} className='my-1 text-danger'>Take Photo Again</button>
        </div>
      )}
    </div>
  );
};

export default Captureimg;