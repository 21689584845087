import { Container } from "react-bootstrap";
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import { Row, Col, Card, Button } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CertificateList from "./Elements/CertificateList";
import SkillList from "./Elements/SkillList";
import { useEffect, useState,useRef } from "react";
import axios from 'axios';
import { FileIcon } from 'react-file-icon';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import HeaderPage from "./HeaderPage";
import ResumeAddUserUnAuth from "./ResumeAddUserUnAuth";

export default function ResumeAddWithoutLogin(props)
{

    const[certificate, setcertificate]=useState([])
    const[skill, setskill]=useState([])
    const[status, setStatus]=useState(false)
    const[user_id_auto, setUser_Id_Auto]=useState('')
    // const[r_email_id, setEmailId]=useState('')
    // const[getUserName, setUserName]=useState('')
    const[resume_title, setResume_Title]=useState('')
    
    const[getimage, setimage]=useState('')
    const[exp_year, setexp_year]=useState('')
    const[exp_month, setexp_month]=useState('')
    const[allResumeData, setAllResumeData]=useState([])
    const [getenable, setdisable]=useState(true)
    const [pageenable, setpageenable]=useState(false)
    
    const [selectedId, setSelectedId] = useState(null);
    const [getresumename,setresumename]=useState('')
    const [showdel, setShowdel] = useState(false);
    const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [show, setShow] = useState(false);
const [getAllJobData, setAllJobData]=useState([])
const [userdetails,setuserdetails]=useState([])
    
const [getCityList,setCityList]= useState([])
    const [getSkillList,setSkillList]= useState([])
    const [getEducationList,setEducationList]= useState([])
    const [getCertificateList,setCertificateList]= useState([])
    const [getCompanyName,setCompanyName]= useState('')
   
    const [getMobNumber,setMobNumber]= useState('')
    const [getResumeDesc,setResumeDesc]= useState('')
    const [getJobUserData,setJobUserData]= useState([])

    

    useEffect(()=>
    {
      if(pageenable)
      {
      const mergedJson = Object.assign({},props.jobdata, userdetails);
      setJobUserData(mergedJson)
      setUser_Id_Auto(userdetails.user_id)
      console.log("jobdata",mergedJson)
      
}

    },[pageenable])
    
    
    const email_idRef=useRef('')
    const resume_titleRef=useRef('')
    const imageref=useRef('')
    const exp_yearRef=useRef('')
    const exp_monthRef=useRef('')
    const resumeDescRef=useRef('')
    const userNameRef=useRef('')
    const mobileNumberRef=useRef('')

    
  
    let imgsize=0;
    try{
    imgsize=getimage.size
  }catch
  {

  }
    useEffect(()=>
      {
        if(imgsize>0 && resume_title.length>0  && allResumeData.length<3)
        {
          setdisable(false)
          
        }else{
          setdisable(true)
        }
      })

      

      useEffect(()=>{
        // if(localStorage.getItem('user-info')){
               
          fetch(`${window.siteUrl}/getlist`,
        {
          headers: {
          authorization:'bearar'+' '+localStorage.getItem('auth')
        }
      }).then((result)=>
       {
        console.log("citylist",result)
         result.json().then((resp)=>{
          setCityList(resp.cityList) 
          setSkillList((resp.skillList))
          setEducationList((resp.educationList))
          setCertificateList((resp.certificateList))
              console.log("citylist123",resp)
              setStatus(false)
         }) 
       }
       )
      // }
      },[])


      
     

      function getReffered()
    {
    const  formData= new FormData();
    formData.append('userData',JSON.stringify(getJobUserData))
    
    formData.append('resume_title',resume_title)
    formData.append('getMobNumber',getMobNumber)
    formData.append('getimage',getimage)
    formData.append('skill',skill)
    formData.append('certificate',certificate)
    formData.append('exp_year',exp_year)
    formData.append('exp_month',exp_month)
    formData.append('getResumeDesc',getResumeDesc)
  
    for (const [key, value] of formData.entries()) {
      console.log("resumedata",`${key}: ${value}`);
    }
    axios.post(window.siteUrl+'/applyjobunauth',formData,

{
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth'),
  foldername: user_id_auto
}
}
)
.then(res=>
  {
    console.log("resume123",res)
    if(res.data.result.affectedRows>=1)
    {
      setStatus(true)
    handleClose()
    }
    
    console.log("error",res.data.result.affectedRows)
    
    // email_idRef.current.value=''
    // resume_titleRef.current.value=''
    // imageref.current.value=''
    // exp_yearRef.current.value=''
    // exp_monthRef.current.value=''
    // resumeDescRef.current.value=''

    // skillRef.current.value=''
    // certificateRef.current.value=''
    
   
  }, 
res1=>console.log("addresume",res1))
.catch(err=>console.log(err))

  setTimeout(() => {
      setStatus(false)
    }, 4000)
  }

    return(
        <>
        <Button variant="outline-primary" onClick={handleShow} className='fs-7 m-1 '>Apply</Button>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='fs-6'> Submit Your  CV Details </Modal.Title>
        </Modal.Header>
        
        
        <Modal.Body>
        <ResumeAddUserUnAuth resumePage={setpageenable} userpagedetails={setuserdetails}disablepage={props.hide} />
        { console.log("pagedisable123",props.hide) }
        {pageenable?
         <div className="card mb-4">
                 <div className="resumeheader">Resume Details</div>
                 <div className="card-body">
                     <form >


                     <div className="row">
   <div className="col-lg-6 mb-2">
   <label class="small mb-1" for="inputResumeTitle"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Resume Title</label>
     <input className="form-control" id="inputresumetitle" ref={resume_titleRef} type="text" placeholder="Enter resume title" onChange={(e)=>setResume_Title(e.target.value)}  />
   </div>
   
   <div className="col-lg-6 mb-2">
   <label class="small mb-1" for="inputmobilenumber"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Mobile</label>
     <input className="form-control" id="inputmobilenumber" ref={mobileNumberRef} type="text" placeholder="Enter Mobile Number" onChange={(e)=>setMobNumber(e.target.value)}  />
   </div>
   <div className="col-lg-6 mb-2 ">
   <label class="small mb-1" for="inputExp">Experience</label>
     <InputGroup>
       <InputGroup.Text>Exp</InputGroup.Text>
       <Form.Select value={exp_year}aria-label="Years" ref={exp_yearRef}onChange={(e)=>setexp_year(e.target.value)}>
         <option>Year</option>
         <option value="0">0</option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         <option value="4">4</option>
         <option value="5">5</option>
         <option value="6">6</option>
         <option value="7">7</option>
         <option value="8">8</option>
         <option value="9">9</option>
         <option value="10">10</option>
         <option value="11">11</option>
         <option value="12">12</option>
         <option value="13">13</option>
         <option value="14">14</option>
         <option value="15">15</option>
         <option value="16">16</option>
         <option value="17">17</option>
         <option value="18">18</option>
         <option value="19">19</option>
         <option value="20">20</option>
         <option value="21">21</option>
         <option value="22">22</option>
         <option value="23">23</option>
         <option value="24">24</option>
         <option value="25">25</option>
         <option value="26">26</option>
         <option value="27">27</option>
         <option value="28">28</option>
         <option value="29">29</option>
         <option value="30">30</option>
         <option value="31">31</option>
       </Form.Select>
       <Form.Select value={exp_month} aria-label="Months" ref={exp_monthRef} onChange={(e)=>setexp_month(e.target.value)}>
         <option>Month</option>
         <option value="0">0</option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         <option value="4">4</option>
         <option value="5">5</option>
         <option value="6">6</option>
         <option value="7">7</option>
         <option value="8">8</option>
         <option value="9">9</option>
         <option value="10">10</option>
         <option value="11">11</option>
         <option value="12">12</option>
       </Form.Select>
     </InputGroup>
   </div>
 {/* </div>
                         <div className="row gx-3 mb-3"> */}
                             
                             <div className="col-md-6 mb-1 ">
                             <label class="small mb-1" for="inputCertificate">Certificate</label>
                                 {/* <label class="small mb-1" for="inputFirstName">First name</label> */}
                                 <CertificateList clearstatus={status} selcertificate={setcertificate} getdefaultcertificate={certificate} getdblist={getCertificateList}/>
                             </div>
                             
                             <div className="col-md-6 mb-2">
                             <label class="small mb-1" for="inputCertificate"><span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Upload Resume</label>
  
   <input ref={imageref}
     className="form-control" 
     id="inputUploadResume" 
     type="file" 
     accept=".pdf,.doc,.docx" 
     onChange={(e)=>setimage(e.target.files[0])}
   />
   {/* <Form.Control type="file"  className='border border-dark' onChange={(e)=>setimage(e.target.files[0])}/> */}
 </div>
  {/* </div>
                         <div className="row gx-3 mb-3"> */}
                             
                             
                             <div className="col-md-6 mb-2">
                                 {/* <label class="small mb-1" for="inputFirstName">First name</label> */}
                                 <label class="small mb-1" for="inputSkill">Skills</label>
                                 <SkillList  clearstatus={status} selskill={setskill} getdefaultskill={skill} getdblist={getSkillList}/>
                                
                             </div>
                          </div>
 
                         <div className="row gx-3 mb-3"> 
                             <div className="col-md-12">
                             <label class="small mb-1" for="inputdescription">Resume Description</label>
                         <FloatingLabel controlId="floatingTextarea2" className="my-2">
           <Form.Control
             id="addressid"
             controlId="addressidc"
             as="textarea"
             className="border border-dark"
             placeholder="Leave a comment here"
             value={getResumeDesc}
             onChange={(e) => setResumeDesc(e.target.value)}
             style={{ height: "150px" }}
             ref={resumeDescRef}
           />
           {getResumeDesc == "" ? (
             <label htmlFor="floatingInput" className="text-danger p-3">
               Enter Resume Details (1000-{getResumeDesc.length} left)
             </label>
           ) : (
             <label htmlFor="floatingInputCustom" className="p-3">
               Enter Resume Details (1000-{getResumeDesc.length} left char)
             </label>
           )}
         </FloatingLabel>
 </div>
 </div>
 {console.log("enable",getenable)}
                        
                         <Button variant="success" disabled={getenable}className="me-3 my-2" onClick={getReffered}>
       Get Reffered
     </Button>
     <Button variant="primary" onClick={()=>setStatus(true)} >Clear</Button>
                         </form>
                         </div>
                         </div>
                         :
                         null
           }
                         </Modal.Body>
        
      </Modal>
                         </>
    )
}