import { useState,useEffect } from "react"


export default function JobAffiliate()
{

    const [getproduct,setproduct]=useState([])
    useEffect(()=>{
        
          fetch(`${window.siteUrl}/getaffiliate`,
       
          ).then((result)=>
       {
         result.json().then((resp)=>{
            setproduct(resp) 
            console.log("affilite",resp)
         }) 
       }
       )
      
      },[])
    
    return(
        
<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' , maxWidth: '100%' }} >
{getproduct &&
      getproduct.length > 0 &&
      getproduct.map((item, i)=>

        <a href={item.product_url} style={{ display: 'inline-block', margin: '9.8px', padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', textAlign: 'center', width: '110px', height: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', overflow: 'hidden' }}
   onMouseEnter={(e) => { e.currentTarget.querySelector('.description').style.top = '0'; }}
   onMouseLeave={(e) => { e.currentTarget.querySelector('.description').style.top = '100%'; }}>
  <img src={item.product_img} alt={item.product_name} style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'contain' }}/>
  <div className="description" style={{ position: 'absolute', top: '100%', left: '0', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', padding: '10px', boxSizing: 'border-box', transition: 'top 0.3s ease' }}>
    {item.product_name}
  </div>
</a>
)}
</div>
    )
}