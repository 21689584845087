import logo from './logo.svg';
import './App.css';
import HeaderPage from './HeaderPage';
import SearchPage from './SearchPage';
import PostYourJob from './PostYourJob';
import PostedJobList from './PostedJobList';
import Profile from './Profile';
import AppliedJob from './AppliedJob';
import ResumeList from './ResumeList';
import SideMenu from './SideMenu';
import DashboardPage from './DashboardPage';
import FollowList from './FollowList';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DashboardContent from './DashboardContent';
import ContactList from './ContactList';
import Login from './LoginAuth/Login';
import SignUp from './LoginAuth/SignUp';
import Resetpassword from './LoginAuth/Reset_password';
import MobileOtp from './LoginAuth/MobileOtp';
import { useEffect, useState } from 'react';
import HeadMenu from './HeadMenu';
import HomePage from './HomePage';
import CandidateList from './CandidateList';
import Protected from './Protected';
import JobDetailsSinglePage from './JobDetailsSinglePage';
import JobChat from './JobChat';
import SelectPlan from './SelectPlan';

function App() {
  const [islogin, setLogin]=useState(false)

  useEffect(()=>{
    if(localStorage.getItem('user-info'))
    {
     
      setLogin(true)
      let userdata = localStorage.getItem('user-info');
      let parseVal = JSON.parse(userdata);
      window.loggedIUser=parseVal.user_id;
    }
  },[])


   //window.siteUrl="http://localhost:4500";
 window.siteUrl="//allstoresprice.com:3443"; 
  return (
    <>
    <div className="App">
      <BrowserRouter>
      <Routes>
    { islogin?
<Route path="/" element={<Protected Cmp={DashboardPage}/>}>
<Route path="dash" element={<DashboardContent/>}/>
 <Route path="posturjob" element={<PostYourJob/>}/> 
  <Route path="postedjoblist" element={<PostedJobList/>}/>
  <Route path="appliedjob" element={<AppliedJob/>}/>
  <Route path="searchjob" element={<SearchPage/>}/>
  <Route path="resume" element={<ResumeList/>}/>
  {/* <Route path="followlist" element={<FollowList/>}/> */}
  <Route path="profile" element={<Profile/>}/>
  <Route path="chat" element={<JobChat/>}/>
  {/* <Route path="contactlist" element={<ContactList/>}/>
  <Route path="candidatelist" element={<CandidateList/>}/> */}
  
</Route>
:
<Route path="/" element={<HomePage/>}></Route>
}
{/* <Route path="/plan" element={<SelectPlan/>}></Route> */}
<Route path="/:name/:jobId" element={<JobDetailsSinglePage />}/>
{/* <Route path="chat" element={<JobChat/>}/> */}
<Route path="/signup"element={<SignUp/>}/>
      <Route path="/login"element={<Login/>}/>
      <Route path="/setpassword"element={<Resetpassword/>}/>
      <Route path="/otp"element={<MobileOtp/>}/> 
      {/* <Route path="/" element={<DashboardPage/>}></Route>  */}
{
// islogin?
// <Route path="/" element={<DashboardPage/>}></Route>
// :<Route path="/" element={<HomePage/>}></Route>

}

      </Routes>
      </BrowserRouter>
      
    </div>
   {/* <HeaderPage/>
    <SearchPage/>
    <PostYourJob/>
    <PostedJobList/>
    <Profile/>
    <AppliedJob/>
    <ResumeList/>
    <SideMenu/>
    <DashboardPage/>
    <FollowList/> */}
    
  </>
  );
}

export default App;
