
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import SearchCityPage from './SearchCityPage';
import Button from 'react-bootstrap/Button';
import { Row,Col, Tabs } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import HeadMenu from './HeadMenu';
import JobList from './JobList';
import Tab from 'react-bootstrap/Tab';
import HeaderPage from './HeaderPage';
import JobListFollow from './JobListFollow';


export default function SearchPage(props)
{
  const [getSearchKey, setSearchKey] = useState([])
  const [getSearchCity, setSearchCity] = useState('')
console.log("getkey",getSearchKey)

const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const name= "Find Your Job"

return(
<>
{/* <HeaderPage headingname={name}/> */}

<h2 className="dashboard-heading">Find Your Job</h2>
{/* <Container fluid='md' className="my-0"> */}
  <Row className="px-1">
    <Col xs={12} sm={8} md={8} >
      <InputGroup className="mb-3">
        <form className="d-flex justify-content-center" style={{ width: "100%" }}>
          <input
            className="form-control"
            type="search"
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder="Find Your Job Here"
            aria-label="Search"
          />
          {/* <Button variant="success" className="ms-2 d-inline-block d-sm-none">
            Search123
          </Button> */}
        </form>
      </InputGroup>
    </Col>
    <Col xs={6} sm={4} md={4} className="d-flex justify-content-end ">
      <SearchCityPage  selcity={setSearchCity}/>
    </Col>
    {/* <Col xs={6} sm={4} md={2} className="d-none d-sm-block">
      <Button variant="success" className="w-100">
        Success
      </Button>
    </Col> */}
  </Row>

<Tabs
      defaultActiveKey="home"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Job Search">
      <JobList jobkeysearch={getSearchKey} citykeysearch={getSearchCity}/>
      </Tab>
      {/* <Tab eventKey="followed" title="Followed Job List">
      <JobListFollow jobkeysearch={getSearchKey} citykeysearch={getSearchCity}/>
      </Tab> */}
      </Tabs>
      {/* </Container> */}
{/* {props.inputKey(getSearchKey)} */}
</>

)

}