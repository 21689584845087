
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import SearchCityPage from './SearchCityPage';
import Button from 'react-bootstrap/Button';
import { Row,Col, Tabs } from 'react-bootstrap';
import HeaderPage from './HeaderPage';
import JobList from './JobList';

export default function JobSearch()
{
    const [getSearchKey, setSearchKey] = useState([])
    const [getSearchCity, setSearchCity] = useState('')
    const name= "Find Your Job"
return(
<>
{/* <HeaderPage headingname={name}/> */}
{/* <Container fluid='md' className="my-2"> */}
  <Row className="px-0 my-2">
    <Col xs={12} sm={8} md={8} >
      <InputGroup className="mb-3" >
        <form className="d-flex justify-content-center" style={{ width: "100%" }}>
          <input
            className="form-control"
            type="search"
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder="Find Your Job Here"
            aria-label="Search"
          />
          {/* <Button variant="success" className="ms-2 d-inline-block d-sm-none">
            Search
          </Button> */}
        </form>
      </InputGroup>
    </Col>
    <Col xs={6} sm={3} md={3} className="d-flex justify-content-end">
    <SearchCityPage  selcity={setSearchCity}/>
    </Col>
    <Col xs={6} sm={2} md={2} className="d-none d-sm-block">
      {/* <Button variant="success" className="w-100">
        Success
      </Button> */}
    </Col>
  </Row>

  
  {/* </Container> */}
  
  <JobList jobkeysearch={getSearchKey} citykeysearch={getSearchCity}/>
</>
)
}