
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { NavLink,useNavigate ,useHistory } from "react-router-dom";

export default function MobileOtp(props)
{ 

  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

const [getOtp,setOtp]=useState() 
const [getOtpstatus,setOtpstatus]=useState() 
const navigate=useNavigate();
const [getuserEmail,setuserEmail]=useState(props.email) 
const [getisloginissue, setisloginissue]=useState('');
//const [getotpresend, seteotpresend]=useState('');
const [geterrortimer,seterrortimer]=useState(false)
const [geterrormsg,seterrormsg]=useState('')



async function verifyOtp()
{
  let otpData= {getuserEmail,getOtp}
  
  // console.log("email",getuserEmail)
  try{
   //let pResult= fetch("//allstoresprice.com:3443/verifypin",
    
    let pResult= fetch(window.siteUrl+"/verifypin",
    
     {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(otpData)
    }).then((pResult) => {
      //console.log("otp presult", pResult)
      pResult.json().then((resp) => {
        console.log("otpreq", resp)
        
        if(resp.auth && resp.fres.length>0)
        {         
        // setlength(resp.result.length) 
       // console.log("userdtaalocal",resp.fres[0])
        localStorage.setItem('user-info',JSON.stringify(resp.fres[0]))
            localStorage.setItem('auth',JSON.stringify(resp.auth))
        
        // localStorage.setItem('shopurl',(resp.result[0].shopName))
          // localStorage.setItem('shop-name',(resp.result[0].shop_name))
        // console.log("length is"+resp.result.length)
        } 
        else
        {
          seterrortimer(true)
          if(resp.incorrectotp)
          {
           setisloginissue(resp.incorrectotp) 
          }else
          {
            setisloginissue('Try after sometime')
          }
        }
        try{
        if( resp.fres.length==1)
        {
    navigate('/profile')
    window.location.reload();
  //   //  shopdetails()
    }
   else{
   // setisloginissue(true)
    } 
  }
  catch(error)
  {

  }
      })
    })
    
  }catch(error)
  {
    console.log("update error",error)
  }
}
useEffect(()=>{
  if (geterrortimer==true)
  {
    setTimeout(() => {

      seterrortimer(false)
    },2000)
  }
},[geterrortimer])
    function cancel()
    {
      navigate('/')
    }


    function resendOtp()
    {
      let otpData= {getuserEmail}
  
  // console.log("email",getuserEmail)
  try{
    //let pResult= fetch("//allstoresprice.com:3443/resendotp",
    
    let pResult= fetch(window.siteUrl+"/resendotp",
    
     {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(otpData)
    }).then((pResult) => {
      //console.log("otp presult", pResult)
      pResult.json().then((resp) => {
       // console.log("resendotp", resp)
        try{
          
        if(resp.result.affectedRows==1)
        {
          setisloginissue(resp.otpresend)
        }else
        {
          setisloginissue("Try after sometime")
        }
        seterrortimer(true)
      }
      catch(error)
      {
        setisloginissue("Try after sometime")
      }
        

    })
  })
}
catch{

}
    }

    return(
        <>
        
        {/* <AddC/> */}
<form className='container formC col-sm-3 col-lg-3 col-md-6 col-10 my-3' style={{ backgroundColor: '#20c997' }}>
<div className="form-group d-flex justify-content-center ">
<div className="col-sm-10 col-lg-10 col-md-10 col-8  align-self-center textformat">

{geterrortimer?
<label for="floatingInput" className='text-danger fw-bold'>{getisloginissue}</label>
:
null
}
 <div>
<label for="floatingInput" className='loginLb'>Enter OTP</label>
</div> 
<p>OTP sent to your Email ID <span className='text-primary'>{getuserEmail}</span></p>
<input maxLength={4} onChange={(e)=>setOtp(e.target.value)}value ={getOtp} type="text" placeholder ="Enter OTP" 
className="form-control m-3 text-center fs-5 pinNumber"id="otp"></input>


<Button onClick={verifyOtp}variant="primary">Verify</Button>{' '} 
<Button onClick={cancel}variant="danger">Cancel</Button>{' '} 
<Button onClick={resendOtp}variant="warning" className='my-1'>Resend OTP</Button>{' '} 

</div>
</div>
{/*  */}
</form>
</>
    )
}