import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState,useEffect } from 'react';
import Card from 'react-bootstrap/Card';

export default function ResumeDetails(props)

{
   const [show, setShow] = useState(false);
   const handleShow = () => setShow(true);
   const handleClose = () => setShow(false);

   

  return (
    <>
   
      <Button variant="outline-primary" onClick={handleShow} className='fs-7 m-1 '>View</Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='fs-6'> Resume Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Card className="text-center">
      <Card.Header>{props.rname}</Card.Header>
      <Card.Body >
        <Card.Title>{props.rtitle}</Card.Title>
        <Card.Text> <b>Company Name -</b>
          {props.ucompany}
        </Card.Text>
        <Card.Text> <b>Total Working exp- </b>
          {props.rexp_year}-{props.r_exp_month} Year
        </Card.Text>
        <Card.Text> <b>Skill -</b>
          {props.rskill}
        </Card.Text>
        <Card.Text> <b>Certificate -</b>
          {props.rcertificate}
        </Card.Text>
        {/* <Card.Text> <b>Certificates -</b>
        {props.jcertificate}
        </Card.Text > */}
        <Card.Text>
  <b>Resume download -</b>
    <a href={props.rattachment} download>
      <i className="bi bi-download" /> 
    </a>
  
</Card.Text>
         <b>Resume Description -</b>
        <Card.Text style={{ height: '200px', overflowY: 'auto' }}>
        {props.rdesc}
        
        </Card.Text>
        
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      <Card.Footer className="text-muted">""</Card.Footer>
    </Card>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}