import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Select from 'react-select';



export default function CityList(props)
{
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [filteredOptions, setFilteredOptions] = useState([]);

  const options=props.getdblist
  useEffect(() => {
    if (props.clearstatus) {
      setSelectedOptions([]);
    }
  }, [props.clearstatus]);
  
console.log("citylist3",props.getdblist)
  
// let value=props.getdefaulteducation.length>1

  
useEffect(() => {
if (props.getdefaultcity !== null && props.getdefaultcity !== undefined) {
  if (typeof props.getdefaultcity === "string") {
    const cityArray = props.getdefaultcity.split(",");
    setSelectedOptions(cityArray);
  } else {
    // Handle non-string cases
  }
} else {
  setSelectedOptions([]); // Reset to empty array
}
}, [props.getdefaultcity]);

useEffect(() => {
  if (props.getdblist) {
    const filteredOptions = props.getdblist.filter(option => !selectedOptions.includes(option.value));
    setFilteredOptions(filteredOptions);
  }
}, [props.getdblist, selectedOptions]);

  // const options = [
  //   { value: 'Pune', label: 'Pune' },
  //   { value: 'Food', label: 'Food' },
  //   { value: 'Stationary', label: 'Stationary' },
  //   { value: 'Profession', label: 'Profession' },
  //   { value: 'HomeMade Product', label: 'HomeMade Product' },
  //   { value: 'Travelling', label: 'Travelling' },
  //   { value: 'Interior Design', label: 'Interior Design' },
  //   { value: 'Other', label: 'Other' },
  // ];
  const handleOnChange = (e) => {
      setSearchTerm(e.target.value);
    };
  
    const handleBlur = () => {
      if (searchTerm && !selectedOptions.includes(searchTerm)) {
        setSelectedOptions([...selectedOptions, searchTerm]);
        setSearchTerm('');
      }
    };
  
    const handleRemoveOption = (index) => {
      const updatedOptions = selectedOptions.filter((option, i) => i !== index);
      setSelectedOptions(updatedOptions);
    };


    
  return(
<>
      <Form.Group>
    <input
      className="form-control mb-2 w-100"
      type="text"
      list="city"
      value={searchTerm}
      onChange={handleOnChange}
      onBlur={handleBlur}
      placeholder="Enter Job Location"
      autoComplete="off"
      onFocus={(e) => e.target.select()}
    />
     {/* <Select
    isMulti
    name="colors"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
  /> */}
 { console.log("citylist4",options)}
    <datalist id="city">
      {filteredOptions.map((option) => (
        <option value={option.value} key={option.value}>
         {option.label}
        </option>
      ))}
    </datalist>
    <div className="selected-options" style={{ display: 'flex', flexWrap: 'wrap' }}>
           
      {selectedOptions.map((option, index) => (
        <span key={index} style={{ fontSize: '14px',margin:'2px' }}>
        {option || selectedOptions}
        <button type="button" onClick={() => handleRemoveOption(index)} className="remove-option" style={{ fontSize: '9px', marginLeft: '1px' }} >
          &#x2716;
        </button>
      </span>
      
      ))}
      
    </div>
  </Form.Group>
  {props.selcity(selectedOptions)}
  </>
  )
}