
import { Row, Col, Card, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import ListGroup from 'react-bootstrap/ListGroup';
import JobApplication from './JobApplication';
import Accordion from 'react-bootstrap/Accordion';
import { FcViewDetails } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import { useEffect,useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import JobDetails from './JobDetails';
import { NavLink,useNavigate,useParams ,Link} from "react-router-dom";

export default function AppliedJob()
{
  const [allJobData,setAllJobData]=useState([])
  const [getDraftJobCount,setDraftJobCount]=useState('')
  const [showdel, setShowdel] = useState(false);
  const handleClose = () => setShowdel(false);
const handleShow = () => setShowdel(true);

const [getjobname,setjobname]=useState('')
const[status, setStatus]=useState(false)
const [searchKey,setSearchKey]=useState(10)


  useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id
      let data={userIdAuto,searchKey}
      const datas=JSON.stringify(data)
     
      fetch(`${window.siteUrl}/appliedjobresult/${datas}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
     result.json().then((resp)=>{
      setAllJobData(resp) 
         console.log("appliedjob",resp)
          // const draftCount = resp.filter((job) => job.j_status === "draft").length;
          // setDraftJobCount(draftCount)
     }) 
   }
   )
  }
  },[status,searchKey])



  function deleteapplication(applicationid)
{

  let data = applicationid
fetch(`${window.siteUrl}/deleteapplication/${data}`,

{
method:'DELETE',

  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}

}).then((result)=>
 {
//   setapistatus(result.status)
setStatus(true)
       
  
result.json().then((resp)=>
{
  
 console.log("deleteapplication",resp)
 handleClose()
 setTimeout(() => {
  
  setStatus(false)
 },4000)
 })
 })
}

let [getvalue,setvalue]=useState()
function setdeldata(id)
{
  console.log("delete")
handleShow ()
  setvalue(id)
 
}

    return(
<>
        {/* <Container fluid='md' className='my-0'  > */}
{/* <h2 className="headingbackground p-3 my-2" style={{ borderRadius: '4px' }}>Applied Job</h2> */}

<h2 className="dashboard-heading">Your Applied Job</h2>
  {/* <div className="my-1">
          <Card style={{ width: '100%' }} className='my-1'>
  <Card.Body>
    <Row>
      <Col xs={3}sm={2}>
      <figure>
    <Card.Img variant="top" src="holder.js/10px30?text=Image cap" />
    <figcaption className="text-center">image</figcaption>
  </figure>
      </Col>
      <Col xs={9}sm={3}  className="text-start">
      <Card.Text className="fw-bold companyname  mb-1">Company Name</Card.Text>
      <Card.Text className='postedondate text-muted '><strong className='postedontext'>Posted on -</strong>25/02/2025</Card.Text>
        </Col>
        <Col xs={12}sm={6}  className="text-start requirement ">
        <Card.Text className="text-muted mb-1">Job Title dfads dfa Pune, Mumbai, Noida </Card.Text>
       
        <Card.Text className="mb-0"> Year of Exp Required Skill dfadsf adfa dfa dsfadsfadfad fg dfadsf  </Card.Text>
        </Col>
        <Col xs={6} sm={1} className="d-flex flex-sm-column align-items-end " >
              
             
              <FcViewDetails className='fs-3'/>
              <AiOutlineDelete className='fs-3 text-danger'/>
                </Col>
             
    </Row>
    
  </Card.Body>
</Card>

</div> */}

{
            status?
            <span className="text-danger fw-bold">
Record Deleted successfully
            </span>:
            null
          }
{allJobData.length > 0?
<form className="d-flex justify-content-start my-2 ml-auto" style={{ width: "50%" }}>
  <select 
    className="form-control" 
    value={searchKey} 
    onChange={(e) => {
      const value = e.target.value;
      setSearchKey(value === 'all' ? value : parseInt(value));
    }}
  >
    <option value="10">Last 10 Records</option>
    <option value="20">Last 20 Records</option>
    <option value="30">Last 30 Records</option>
    <option value="all">All Records</option>
  </select>
</form>
:
<span>No Job Applied</span>
}
        
{allJobData &&
      allJobData.length > 0 &&
      allJobData.map((item, i)=>
      item.j_status=="posted"?
          <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex flex-column flex-lg-row">
        <div class="col-sm-2">
          {/* avatar-lg img-thumbnail rounded-circle */}
                                          <div class="candidate-list-images">
                                              <img src={item.j_userimage?item.j_userimage:"https://bootdey.com/img/Content/avatar/avatar3.png" }alt="" class="profile-photo-jd" />
                                          </div>
                                          <span class="text-wrap">{item.j_username}</span> 
                                          
                                      </div>
          <div class="row flex-fill">
            <div class="col-sm-5">
            
              <h5 class="h5">{item.job_title}</h5>
             
              <ul class="list-inline mb-0 text-muted">
                                                  <li class="list-inline-item"><i class="bi bi-building"></i>{item.j_company} {item.j_exp_from_year}-{item.j_exp_to_year} year</li>
                                                  {/* <li class="list-inline-item"> 5-7 year</li> */}
                                              </ul>
              <span class="badge bg-secondary text-wrap text-break"> <i class="bi bi-geo-alt"></i>{item.j_city} </span> <span class="badge bg-success">{item.j_salary_from}k - {item.j_salary_to}k</span> 
              {/* <span class="">25-10-2025</span> */}
            
            </div>
            <div class="col-sm-4 py-2 ">
            <span class="badge bg-secondary text-wrap text-break ">{item.j_skill} </span>
            <span class="badge bg-secondary text-wrap text-break">{item.j_certificate}</span>

            </div>
            <div class="col-sm-3 text-lg-end p-1">
            <JobDetails jresponsibility ={item.j_responsibility} jqualification={item.j_qualification} jskill={item.j_skill} jtitle={item.job_title} jusername={item.user_name} jcity={item.j_city} jcompanyname={item.u_company_name} jfromexp={item.j_exp_from_year} jtoexp={item.j_exp_to_year} jdesc={item.j_desc} jdate={item.j_curr_date} jcertificate={item.j_certificate} jeducation={item.j_education} jsalaryfrom={item.j_salary_from} jsalaryto={item.j_salary_to} jstatus={item.j_status} jida={item.job_id_a}jidm={item.job_id_m}/>
              <Button variant="outline-danger" className="m-1" onClick={()=>setdeldata(item.applied_id,setjobname(item.job_title))}>Delete</Button>
            
            </div>
            <div className="text-end"style={{ fontSize: "10px" }}>{item.applied_date.split('T')[0].split('-').reverse().join('/')}</div>
          </div>
        </div>
      </div>
      
    </div>
    :null
    )}


        {/* </Container> */}
       <Modal show={showdel} onHide={handleClose}>
       <Modal.Header closeButton>
         <Modal.Title>Delete Application</Modal.Title>
       </Modal.Header>
       <Modal.Body>Do you want to delete this  <b>{getjobname}</b> job?
           
       </Modal.Body>
       <Modal.Footer>
        <Button variant="primary" onClick={()=>deleteapplication(getvalue)}>
           Delete
         </Button> 
         <Button variant="secondary" onClick={handleClose}>
           No
         </Button>
       </Modal.Footer>
     </Modal> 
     </>
    )
}