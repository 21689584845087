import { Link } from "react-router-dom";
import JobListSameUser from "./JobListSameUser";
import { useEffect, useState } from "react";

export default function JobSameUser(props)
{
  const [userjobdata,setuserjobdata]=useState([])
  const [username,setusername]=useState('')
  const [companyname,setcompanyname]=useState('')
  const [countpostedjob,setcountpostedjob]=useState('')
  const [userimage,setuserimage]=useState('')
  useEffect(()=>
  {
   
    setuserjobdata(props.userjoblist)
    setusername(props.userjoblist[0].user_name)
    setcompanyname(props.userjoblist[0].u_company_name)
    setcountpostedjob(props.userjoblist.length)
    
  },[props])
    return(
<>

        <div className="col-md-12 col-lg-12 d-flex align-items-center my-1">
       <div className="card " style={{ width: "100%", maxWidth: "100%", padding: 0, margin: 0 }}>
          <div className="row my-2 no-gutters">
            <div className="col-3 ">
              <div className="d-flex align-items-center justify-content-center  ">
                <div className="linear-gradient d-flex align-items-center justify-content-center rounded-circle" style={{ width: "60px", height: "60px" }}>
                  <div className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden" style={{ width: "60px", height: "60px" }}>
                    <img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" className="profile-photo-fwl " style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9 ">
              <div className="text-start">
                <span className="fw-semibold mb-0" style={{ fontSize: "14px" }}><i style={{ fontSize: "12px" }}>posted by</i>{username} </span>
                <div style={{ fontSize: "14px" }}><i style={{ fontSize: "12px" }}>Working at -</i> {companyname}</div>
              </div>
            </div>
            <div className="col-12 ">

            <div className="d-flex align-items-center justify-content-around " style={{ fontSize: "13px" }}>
    <div className="text-center  ">
  <i className="fa fa-file m-1 mb-2 d-block"></i>
  <small>
  <span className="mb-0 fw-semibold m-1 lh-1 ">{countpostedjob}</span>
  <span className="mb-0">Job Posted</span>
  </small>
</div>
      <div className="text-center ">
        <i className="fa fa-user  m-1 mb-2 d-block"></i>
        <small>
        <span className="mb-0 fw-semibold m-1 lh-1">300</span>
        <span className="mb-0 ">Followers</span>
        </small>
      </div>
    </div>
            </div>
          </div>
   
    </div>
    
    </div>
    <JobListSameUser samejoblist={userjobdata}/>
    </>
    )
}