import { useState } from "react"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import Container from 'react-bootstrap/Container';

export default function PromotedJobDisplay()
{
  return null;
const [jobdata,setjobdata]=useState([])
const [getStatus,setStatus]=useState(false)



useEffect(()=>{
    
      fetch(`${window.siteUrl}/promotedjobdata`,
    //fetch(`//allstoresprice.com:3443/getuserdetails/${usernumber}`,
    {
      headers: {
    //   authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
    console.log("user details1",result)
     result.json().then((resp)=>{
       console.log("jobdetails",resp)
      //  setcount(getplist.length) 
          //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
          setjobdata(resp)
          
          
     }) 
   }
   )
  
  },[]) 
  
    return(
      <Container fluid='md' className='my-2'>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"/>

  
    {/* <h3 style={{ textAlign: "center", marginBottom: "20px", color: "#343a40" }}>🔥 Hot Jobs Alert! 🔥</h3> */}
    <div className="row my-2 no-gutters flex-row" style={{ overflow: 'hidden' }}>
  {jobdata && jobdata.length > 0 && jobdata.slice(0, 4).map((item, i) => (
    <div className="col-md-3 col-sm-12 p-0 " key={i}>
      <div className="text-center p-3" style={{ border: "1px solid #dee2e6", borderRadius: "6px", height: "100%" }}>
            <Link to={`/${item.user_id}/${item.job_id_a}`} target="_blank" state={{ itemData: item }} style={{ textDecoration: "none" }}>
              <h6 className="fw-bold mb-2" style={{ color: "#007bff" }}>{item.job_title}</h6>
            </Link>
            <div style={{ fontSize: "14px", color: "#6c757d" }}>
              <i style={{ fontSize: "12px" }}>at</i> {item.u_company_name}
              <span className="badge bg-light text-dark me-1">Exp: {item.j_exp_from_year}-{item.j_exp_to_year} yrs</span>
            </div>
            
            <div className="mt-1">
              {item.j_skill.split(',').map((skill, index) => (
  <span key={index} className={`badge bg-secondary text-wrap text-break d-inline-block m-1`}>
    {skill.trim()}
  </span>
))}
              
            </div>
            <div className="mt-1">
  <i className="bi bi-geo-alt-fill me-1" style={{ fontSize: "10px" }}></i>
  {item.j_city.split(',').map((city, index) => (
    <span key={index} className="badge bg-success text-wrap text-break d-inline-block me-1" style={{ marginRight: '2px' }}>
      {city.trim()}
    </span>
  ))}
</div>
            {/* <div className="mt-3">
              <Link to={`/${item.user_id}/${item.job_id_a}`} target="_blank" state={{ itemData: item }} className="btn btn-sm btn-outline-primary">
                View Details
              </Link>
            </div> */}
          </div>
        </div>
      ))}
    </div>
   
  

     </Container>
    )
    
}