import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function SkillList(props)
{
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    
    const options=props.getdblist
    useEffect(() => {
      if (props.clearstatus) {
        setSelectedOptions([]);
      }
    }, [props.clearstatus]);

    useEffect(() => {
      if (Array.isArray(props.getdefaultskill)) {
        setSelectedOptions(props.getdefaultskill);
      } else {
        console.error('getDefaultEducation is not an array');
      }
    }, []);

    // const options = [
    //   { value: 'sql', label: 'sql' },
    //   { value: 'java', label: 'java' },
    //   { value: 'python', label: 'python' },
    //   { value: 'selenium', label: 'selenium' },
    //   { value: 'mySQL', label: 'mySQL' },
    //   { value: 'html', label: 'html' },
    //   { value: 'CSS', label: 'CSS' },
    //   { value: 'Other', label: 'Other' },
    // ];
    console.log("skill",options)
    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const handleBlur = () => {
        if (searchTerm && !selectedOptions.includes(searchTerm)) {
          setSelectedOptions([...selectedOptions, searchTerm]);
          setSearchTerm('');
        }
      };
    
      const handleRemoveOption = (index) => {
        const updatedOptions = selectedOptions.filter((option, i) => i !== index);
        setSelectedOptions(updatedOptions);
      };

      useEffect(() => {
        if (props.getdblist) {
          const filteredOptions = props.getdblist.filter(option => !selectedOptions.includes(option.value));
          setFilteredOptions(filteredOptions);
        }
      }, [props.getdblist, selectedOptions]);

    return(
        <>
        
        <Form.Group>
      <input
        className="form-control mb-2 w-100"
        type="text"
        list="skill"
        value={searchTerm}
        onChange={handleOnChange}
        onBlur={handleBlur}
        placeholder="skill (optional)"
        autoComplete="off"
        onFocus={(e) => e.target.select()}
      />
      <datalist id="skill">
        {filteredOptions.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </datalist>
      <div className="selected-options">
        {selectedOptions.map((option, index) => (
          <span key={index}>
            {option}
            <button
              type="button"
              onClick={() => handleRemoveOption(index)}
              className="remove-option"
            >
              &#x2716;
            </button>
          </span>
        ))}
      </div>
    </Form.Group>
    {props.selskill(selectedOptions)}
    </>
    )
}