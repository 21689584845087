import { useState,useEffect } from "react";
import FollowerList from "./FollowerList";
import FollowedList from "./FollowedList";
export default function FollowList(props)
{
  return null;
 
  const [activeTab, setActiveTab] = useState('people');
  const [allpeoples,setallpeoples]=useState([])
  const [profileImage, setProfileImage]=useState('')
  const [profileCompany, setProfileCompany]=useState('')
  const [profileName, setProfileName]=useState('')
  const [jobCount, setjobCount]=useState('')
  const [isFollowing, setIsFollowing] = useState('')
  const [status,setStatus]= useState('')
  const [userID,setUserID]= useState('')
  const [followList,setFollowList]= useState([])
  const [followerCount,setFollowerCount]= useState('')
  const [followingCount,setFollowingCount]= useState('')
  const [searchKey,setSearchKey]=useState('')
  

  let userData
useEffect(()=>
{
  if(localStorage.getItem('user-info'))
	{
	  userData=localStorage.getItem('user-info')
		const details= JSON.parse(userData);
    setProfileImage(details.u_image) 
    setProfileCompany(details.u_company_name)
    setProfileName(details.u_name)
    setUserID(details.user_id)

}
},)

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setStatus(true)
    console.log("tab",tab)
  }

  useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let userIdAuto= parseVal.user_id
      
let data={userIdAuto,searchKey}
let datas=JSON.stringify(data)

    fetch(`${window.siteUrl}/getpeoples/${datas}`,
  {
    headers: {
    authorization:'bearar'+' '+localStorage.getItem('auth')
  }
}).then((result)=>
 {
  console.log("result78",result)
   result.json().then((resp)=>{
    setallpeoples(resp.result) 
    setjobCount(resp.result1[0].count)
    setFollowingCount(resp.result2[0].following_count)
  setFollowerCount(resp.result2[0].follower_count)

        console.log("peopledata",resp) 
        setStatus(false)
   }) 
 }
 )
}
},[status,searchKey])

function followEntry(followerData)
{
  {
    const data={userID,followerData}
    console.log("follow123api",data)
try{
  let pResult= fetch(window.siteUrl+"/followerlist",
  {
   method: 'POST',
   headers: {
     'Accept': 'application/json',
     'Content-Type': 'application/json',
     'authorization': 'bearar'+' '+localStorage.getItem('auth')
   },
   body: JSON.stringify(data)
 }).then((pResult) => {
   //console.log("user list", pResult)
   pResult.json().then((resp) => {
     console.log("insertfollow", resp)  
     if(resp.result.affectedRows>=1)
     {
       setStatus(true)
     }
}
   )
 }
 )
}catch
{

}
  }
  
}


function deletefollowentry(followid)
{

  let data = followid
fetch(`${window.siteUrl}/deletefollowentry/${data}`,

{
method:'DELETE',
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}
}).then((result)=>
 {
//   setapistatus(result.status)
setStatus(true)
result.json().then((resp)=>
{
 console.log("deleteapplication",resp)
 })
 })
}

// useEffect(() => {
//   console.log("follow123", followerData);
// }, [followerData]);

const handleFollow = (followerData, isFollowing,followId) => {
  if (isFollowing) {
    deletefollowentry(followId)
    console.log("deleteApi",followId)

  } else {
    console.log("EntryApi",followerData)
    followEntry(followerData);
  }
};



    return(

<>
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>

<div class="container">
  <div class="card overflow-hidden">
    <div class="card-body p-0">
      {/* <img src="https://www.bootdey.com/image/1352x300/FF00FF/000000" alt="" class="img-fluid"/> */}
      <div class="row align-items-center">
        <div class="col-lg-4 order-lg-1 order-2">
          <div class="d-flex align-items-center justify-content-around" style={{ fontSize: '13px'}}>
            <div class="text-center">
              <i class="fa fa-file fs-6 d-block mb-2"></i>
              <h4 class="mb-0 fw-semibold lh-1">{jobCount}</h4>
              <p class="mb-0 ">Job Posted</p>
            </div>
            <div class="text-center">
              <i class="fa fa-user fs-6 d-block mb-2"></i>
              <h4 class="mb-0 fw-semibold lh-1">{followerCount}</h4>
              <p class="mb-0 ">Followers</p>
            </div>
            <div class="text-center">
              <i class="fa fa-check fs-6 d-block mb-2"></i>
              <h4 class="mb-0 fw-semibold lh-1">{followingCount}</h4>
              <p class="mb-0 ">Following</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-n3 order-lg-2 order-1">
          <div class="mt-n3">
            <div class="d-flex align-items-center justify-content-center mb-1">
              <div class="linear-gradient d-flex align-items-center justify-content-center rounded-circle" style={{width: "100px",height: "100px"}} >
                <div class="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden" style={{width: "100px",height: "100px"}} >
                  {/* <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" class="w-100 h-100"/> */}
                  <img src={profileImage?profileImage:"https://bootdey.com/img/Content/avatar/avatar1.png"} alt="user" className="profile-photo-fw"/>
                </div>
              </div>
            </div>
            <div class="text-center">
              <h5 class="fs-5 mb-0 fw-semibold">{profileName}</h5>
              <p class="mb-0 fs-4">{profileCompany}</p>
            </div>
          </div>
        </div>
       
      </div>

<div class="tab-content" id="pills-tabContent">
  
  <div>
  <ul className="nav nav-pills user-profile-tab justify-content-end mt-2 bg-light-info rounded-2" id="pills-tab" role="tablist">
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link ${activeTab === 'people' ? 'active' : ''} position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-4 py-6`}
        id="pills-people-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-people"
        type="button"
        role="tab"
        aria-controls="pills-people"
        aria-selected={activeTab === 'people'}
        onClick={() => handleTabClick('people')}
      >
        <i className="fa fa-user me-2 fs-6"></i>
        <span className="d-none d-md-block">People</span>
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link ${activeTab === 'followers' ? 'active' : ''} position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-4 py-6`}
        id="pills-followers-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-followers"
        type="button"
        role="tab"
        aria-controls="pills-followers"
        aria-selected={activeTab === 'followers'}
        onClick={() => handleTabClick('followers')}
      >
        <i className="fa fa-heart me-2 fs-6"></i>
        <span className="d-none d-md-block">Followers</span>
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link ${activeTab === 'following' ? 'active' : ''} position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-4 py-6`}
        id="pills-following-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-following"
        type="button"
        role="tab"
        aria-controls="pills-following"
        aria-selected={activeTab === 'following'}
        onClick={() => handleTabClick('following')}
      >
        <i className="fa fa-users me-2 fs-6"></i>
        <span className="d-none d-md-block">Following</span>
      </button>
    </li>
  </ul>


  <form className="d-flex justify-content-start my-2 ml-auto" style={{ width: "50%" }}>
          <input
            className="form-control"
            type="search"
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder="Search People/company/skill"
            aria-label="Search"
          />
        </form>

  
  <div className="tab-content" id="pills-tabContent">
    {activeTab === 'people' && (
      <div className="tab-pane fade show active" id="pills-people" role="tabpanel" aria-labelledby="pills-people-tab">
      
      <div className="row m-1">
        
      {allpeoples &&
      allpeoples.length > 0 &&
      allpeoples.map((item, i)=>
      userID!=item.user_id?
      <>
      <div className="col-md-8 col-lg-3 my-1 px-1 py-0 " style={{ height: "320px" }}>
  <div className="card" 
  style={{
    height: "320px",
    borderRadius: "15px",
    margin: "0 auto"
  }}>
    <div className="my-2">
    <div className="d-block">
  {/* <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" className="rounded-circle mx-auto d-block mb-1" width="80" height="80" /> */}
  <div class="d-flex align-items-center justify-content-center mb-1">
  <div class="linear-gradient d-flex align-items-center justify-content-center rounded-circle" style={{width: "102px",height: "102px"}} >
    <div class="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden" style={{width: "100px",height: "100px"}} >
  <img src={item.u_image?item.u_image:"https://bootdey.com/img/Content/avatar/avatar3.png" }alt="" class="profile-photo-fwl" />
  </div>
  </div>
  </div>
  <div className="text-center " style={{height:"90px"}}>
    <h6 className="fw-semibold mb-0">{item.u_name}</h6>
    <span className="fs-6"><i className="ti ti-map-pin text-dark fs-6 me-1"></i>{item.u_company_name}</span>
  </div>
</div>
    </div>
    <div className="d-flex align-items-center justify-content-around mb-2 p-1">
    <div className="text-center fs-6 ">
  <i className="fa fa-file m-1 mb-2 d-block"></i>
  <small>
  <span className="mb-0 fw-semibold m-1 lh-1 ">{item.posted_jobs_count}</span>
  <span className="mb-0">Job Posted</span>
  </small>
</div>
      <div className="text-center ">
        <i className="fa fa-user  m-1 mb-2 d-block"></i>
        <small>
        <span className="mb-0 fw-semibold m-1 lh-1">{item.follower_count}</span>
        <span className="mb-0 ">Followers</span>
        </small>
      </div>
    </div>
    <button 
  style={{
    borderRadius: "5px", 
    margin: "0 auto", 
    backgroundColor: item.followed ? '#4CAF50' : '', 
    color: item.followed ? 'white' : ''
  }} 
  onClick={()=>handleFollow({followedName:item.u_name,followedCompany:item.u_company_name,followedID:item.user_id,followedEmailId:item.u_email_id}, item.followed,item.followId)}>
  {item.followed? 'Following' : 'Follow'}
</button>
  </div>
</div>
</>
:null
)}
</div>
      </div>
 
 
 )}
    {activeTab === 'followers' && (
      <div className="tab-pane fade show active" id="pills-followers" role="tabpanel" aria-labelledby="pills-followers-tab">
      


      <FollowedList getSearchKey={searchKey}/>

       
      </div>
    )}
    {activeTab === 'following' && (
      <div className="tab-pane fade show active" id="pills-following" role="tabpanel" aria-labelledby="pills-following-tab">
       
       <FollowerList getSearchKey={searchKey} />
      </div>
    )}
  </div>
</div>


        </div>
       
        
      
      </div>
    </div>

  </div>

  
</>

    )
}