import { useState,useEffect } from "react";
import { Form } from 'react-bootstrap';
export default function PlanStatusDashboard()
{
  return null;
    const [getPlanStatus, setPlanStatus] = useState('');
    const [getPlanName, setPlanName] = useState('');
    const [getPlanStartDate, setPlanStartDate] = useState('');
    const [getPlanExpDate, setPlanExpDate] = useState('');
    const [getPlanDays, setPlanDays] = useState('');
    const [getPlanPrice, setPlanPrice] = useState('');
    const [getshowdetails, setshowdetails] = useState();
    const [getPlanEnableStatus, setPlanEnableStatus] = useState('');
    
const [getStatus,setStatus]=useState('')

    // useEffect(()=>{
        
    //   if(localStorage.getItem('user-info')){
    //     let userdata=localStorage.getItem('user-info')
    //     let parseVal=JSON.parse(userdata)
    //     setPlanStatus(parseVal.isplanactive)
    //     setPlanName(parseVal.planselected)
    //     setPlanStartDate(parseVal.planstartdate)
    //     setPlanExpDate(parseVal.planexpdate)
        
    //   }
    // })

    
    

useEffect(()=>{
  if(localStorage.getItem('user-info')){
    let userdata=localStorage.getItem('user-info')
    let parseVal=JSON.parse(userdata)
    let getid=parseVal.user_id
    const data={getid,getshowdetails}
    console.log("data",data)
try{
  let pResult= fetch(window.siteUrl+"/updateplanenablestatus",
  {
   method: 'POST',
   headers: {
     'Accept': 'application/json',
     'Content-Type': 'application/json',
     'authorization': 'bearar'+' '+localStorage.getItem('auth')
   },
   body: JSON.stringify(data)
 }).then((pResult) => {
  //  console.log("enable plan", pResult)
   pResult.json().then((resp) => {
      try{
        console.log("enable plan", resp) 
        setshowdetails(resp.result[0].plan_enable)
        setPlanStatus(resp.result[0].isplanactive)
        setPlanName(resp.result[0].planselected)
        setPlanStartDate(resp.result[0].planstartdate)
        setPlanExpDate(resp.result[0].planexpdate)
     if(resp.result1.affectedRows>=1)
     {
      
       setStatus(true)
        
     }
     setTimeout(() => {
      setStatus(false)
    }, 4000)
  }
  catch{

  }
}
   )
 }
 )
}catch
{

}
  }
},[getshowdetails])
    return(
       
        <>
          <div className="card-header my-2 d-flex justify-content-between align-items-center">
            <div>Current Active Plan</div>
            <Form>
              <Form.Check
                type="switch"
                id="plan-status-switch"
                label="Enable/Disable" // Combined label and switch
                checked={getshowdetails}
                 onChange={(e) => setshowdetails(e.target.checked)}
              />
            </Form>
          </div>
        
          {getPlanStatus === 'yes' ? (
            <div style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '10px',
              alignItems: 'center'
            }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Plan Name:</span>
                <div>{getPlanName}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Plan Status:</span>
                <div>{getPlanStatus?"Active":null}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Plan Start Date:</span>
                <div>
                  {getPlanStartDate ? (
                    new Date(getPlanStartDate).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                  ) : "-"}
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Plan End Date:</span>
                <div>
                  {getPlanExpDate ? (
                    new Date(getPlanExpDate).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                  ) : "-"}
                </div>
              </div>
            </div>
          ) : null}
        </>

    )
}