import Container from 'react-bootstrap/Container';
import { useState,useEffect } from 'react';
import JobDetails from './JobDetails';
import Button from 'react-bootstrap/Button';
import SelectResumeApply from './SelectResumeApply';
import ToolTip from './ToolTip';
import { NavLink,useNavigate,useParams ,Link} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';


export default function JobList(props)
{

  const [allJobData,setAllJobData]=useState([])
  const [getDraftJobCount,setDraftJobCount]=useState('')
  const [getLoginUser_Id_a,setLoginUser_Id_a]=useState('')
  const [getLoginUser_Id_m,setLoginUser_Id_m]=useState('')
  const [getLoginImage,setLoginImage]=useState('')
   const [data, setData] = useState([]);
   let [pageCount,getpageCount]=useState('');
    const [perPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
  const [getJobUser_Id_a,setJobUser_Id_a]=useState('')
  const [getJobUser_Id_m,setJobUser_Id_m]=useState('')
  const jobserarchkey=props.jobkeysearch
  const cityserarchkey=props.citykeysearch
  


  useEffect(()=>{
    let userIdAuto=null
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      userIdAuto= parseVal.user_id
    }
const data={jobserarchkey,userIdAuto,cityserarchkey}
const datas=JSON.stringify(data)
      console.log("not working",datas)
      fetch(`${window.siteUrl}/getalljobdata/${datas}`,
    {
      headers: {
      // authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }).then((result)=>
   {
    console.log("city",result)
     result.json().then((resp)=>{
      setAllJobData(resp) 
      const data = resp.slice(0,10);
 getpageCount(Math.ceil(resp.length / perPage));
 setData(data);
          console.log("joballdata123",resp)
          const draftCount = resp.filter((job) => job.j_status === "posted").length;
          setDraftJobCount(draftCount)
     }) 
   }
   )
  
  },[jobserarchkey,cityserarchkey])

   const handlePageClick = ({ selected }) => {
    const pagesVisited = selected * perPage;
    const lastSetData = pagesVisited + perPage;
    setData(allJobData.slice(pagesVisited, lastSetData));
    window.scrollTo(0, 0);
    setCurrentPage(selected);
   }
  
    return(
        <>
        

        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"/>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" />
    
        Total: {getDraftJobCount}
         {/* <Container fluid='md' className='my-3 '  > */}
        <ReactPaginate 
  previousLabel={ 
    <span> 
      <FaChevronLeft /> 
      <span style={{ fontSize: '1.2em', marginRight: '5px' }}> Prev </span> 
    </span> 
  } 
  nextLabel={ 
    <span> 
      <span style={{ fontSize: '1.2em', marginLeft: '5px' }}> Next </span> 
      <FaChevronRight /> 
    </span> 
  } 
  breakLabel={'...'} 
  breakClassName={'break-me'} 
  pageCount={pageCount} 
  onPageChange={handlePageClick} 
  containerClassName={'pagination'} 
  subContainerClassName={'pages pagination'} 
  pageLinkClassName={'page-link'} 
  activeClassName={'active-page'} 
  renderOnZeroPageCount={null} 
/>

         {data &&
      data.length > 0 &&
      data.map((item, i)=>
      item.j_status=="posted"?
          <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex flex-column flex-lg-row">
        <div class="col-sm-2">
          {/* avatar-lg img-thumbnail rounded-circle */}
                                          <div class="candidate-list-images">
                                              <a href="#"><img src={item.user_image?item.user_image:"https://bootdey.com/img/Content/avatar/avatar3.png" }alt="" class="profile-photo-jd" /></a>
                                          </div>
                                          <span class="text-wrap">{item.user_name} </span> 
                                      </div>
          <div class="row flex-fill">
            <div class="col-sm-5">
              <h5 class="h5">{item.job_title}</h5>
              <ul class="list-inline mb-0 text-muted">
                                                  <li class="list-inline-item"><i class="bi bi-building"></i>{item.u_company_name} {item.j_exp_from_year}-{item.j_exp_to_year} year</li>
                                                  {/* <li class="list-inline-item"> 5-7 year</li> */}
                                              </ul>
            <div>
              <i class="bi bi-geo-alt" style={{ fontSize: "12px"}}></i>
                  {item.j_city.split(',').map((city, index) => (
              <span class="badge bg-secondary text-wrap"style={{ marginRight: '1px' }}> 
              {city.trim()}
              </span> 
              ))}
              </div>
              <span class="badge bg-success">{item.j_salary_from}k - {item.j_salary_to}k</span> 
              {/* <span class="">25-10-2025</span> */}
            </div>
            <div class="col-sm-4 py-2 ">

           
            <ToolTip item={"Skill"}>
            
  <div>
    {item.j_skill ? (
      <div>
      {item.j_skill.split(',').map((skill, index) => (
      <span key={index} className="badge bg-secondary text-wrap text-break  mb-1"style={{ marginRight: '1px' }}>
      {skill.trim()}
      </span>
      ))}
</div>
    ) : null}
    
  </div>
</ToolTip>
            
<ToolTip item={"Certificate"}>
  <div>
            {item.j_certificate ? 
            <div>
      {item.j_certificate.split(',').map((certificate, index) => (
      <span key={index} className="badge bg-success text-wrap text-break  mb-1" style={{ marginRight: '1px' }}>
      {certificate.trim()}
      </span>
      ))} 
      </div>
      
      : null}
            </div>   
            </ToolTip>
            </div>
            <div class="col-sm-3 text-lg-end p-1">
              {/* <a href="#" class="btn btn-primary stretched-link m-1">View</a> */}
              {/* <JobDetails jskill={item.j_skill} jtitle={item.job_title} jusername={item.user_name} jcity={item.j_city} jcompanyname={item.u_company_name} jfromexp={item.j_exp_from_year} jtoexp={item.j_exp_to_year} jdesc={item.j_desc} jdate={item.j_curr_date} jcertificate={item.j_certificate} jeducation={item.j_education} jsalaryfrom={item.j_salary_from} jsalaryto={item.j_salary_to} jstatus={item.j_status} jida={item.job_id_a}jidm={item.job_id_m}/> */}
              <Link to ={`/${item.user_id}/${item.job_id_a}`} target="_blank" 
          state={{ itemData: item }}>
            <Button variant="outline-primary" className='fs-7 m-1 '>View</Button>
          </Link>
              <SelectResumeApply jresponsibility ={item.j_responsibility} jqualification={item.j_qualification}jskill={item.j_skill} jtitle={item.job_title} jusername={item.user_name} juserida={item.user_id} juseridm={item.user_id_self} juserimageprofile={item.user_image} jcity={item.j_city} jcompanyname={item.u_company_name} jfromexp={item.j_exp_from_year} jtoexp={item.j_exp_to_year} jdesc={item.j_desc} jdate={item.j_curr_date} jcertificate={item.j_certificate} jeducation={item.j_education}jsalaryfrom={item.j_salary_from} jsalaryto={item.j_salary_to} jstatus={item.j_status} jida={item.job_id_a}jidm={item.job_id_m}/>
              {/* <Button class="btn btn-success stretched-link" >Apply</Button> */}
            </div>
            <div className="text-end"style={{ fontSize: "10px" }}>{item.j_curr_date.split('T')[0].split('-').reverse().join('/')}</div>
          </div>
        </div>
      </div>
      
    </div>
    :null
    )}
    {/* </Container> */}
    
        </>
    )
}